<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { route, useShared, useSession, $post } from '@plenny/visitor';

  const emit = defineEmits([
    'saved',
  ]);

  const shared = useShared();
  const session = useSession();
  const create = ref(false);

  const options = computed(() => {
    return Object.entries(shared.value.wishlists).map(([id, name]) => ({
      label: name as string,
      value: id as string,
    }));
  });

  function onSubmit(data: { wishlist: string }) {
    return $post(route('api.v1.web.cart.wishlist.store', { wishlist: data.wishlist })).then(() => {
      emit('saved');
    });
  }
</script>
<template>
  <VisitorForm :submit="onSubmit" v-slot="{ data, submitting }" class="wishlist-cart-picker">
    <section class="wishlist-cart-picker__wrapper">
      <div v-if="session.is_authenticated">
        <SfFormSelect :label="$t('Wybierz listę')" name="wishlist" required :options="options" />
      </div>
      <div v-else class="wishlist-cart-picker__authentication">
        <p>{{ $t('Schowek jest dostępny tylko dla zalogowanych użytkowników.') }}</p>
      </div>
    </section>
    <footer class="wishlist-cart-picker__footer">
      <template v-if="session.is_authenticated">
        <SfButton type="submit" primary :disabled="!data.wishlist" :loading="submitting">
          {{ $t('Zapisz') }}
        </SfButton>
        <SfButton @click.prevent="create = true">
          {{ $t('Nowa lista') }}
        </SfButton>
      </template>
      <template v-else>
        <SfButton :href="route('web.account.session.create')" primary>
          {{ $t('Zaloguj się') }}
        </SfButton>
      </template>
    </footer>
  </VisitorForm>
  <WishlistEditModal v-model:open="create" />
</template>
<style lang="scss">
  .wishlist-cart-picker {
    &__wrapper {
      margin: 1rem 0;
    }

    &__authentication {
      color: var(--sf-status-color-danger);
      align-items: center;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  //@media(max-width: 768px) {
  //  .wishlist-product-picker {
  //    flex-direction: column;
  //    flex-wrap: nowrap;
  //    justify-content: flex-start;
  //    flex-grow: 1;
  //  }
  //}
</style>
