<script lang="ts" setup>
  import { $get, $redirect, route } from '@plenny/visitor';
  import { onBeforeUnmount, onMounted } from 'vue';
  import { CartLayout } from '$components';

  defineOptions({
    layout: CartLayout,
    inheritAttrs: false,
  });

  const props = defineProps({
    order: Object,
    payment: Object,
  });

  let interval;

  function refreshStatus() {
    $get(route('api.v1.web.order.payment.show', { order: props.order, payment: props.payment })).then((res) => {
      if (res.redirect) {
        clearInterval(interval);
        $redirect(res.redirect);
      }
    });
  }

  onMounted(() => {
    interval = setInterval(refreshStatus, 2000);
  });

  onBeforeUnmount(() => {
    clearInterval(interval);
  });
</script>
<template>
  <div class="order">
    <div class="order-processing">
      <SfTile class="order-processing__confirmation">
        <div class="order-processing__icon-wrapper">
          <SfSpinner />
        </div>
        <main class="order-processing__content">
          <header class="order-processing__heading-wrapper">
            <h1 class="order-processing__heading">{{ $t('Twoja płatność jest w trakcie przetwarzania...') }}</h1>
            <p class="order-processing__request"> {{ $t('Prosimy nie zamykać okna przeglądarki.') }}</p>
          </header>
          <section class="order-processing__order-section">
            <p class="order-processing__order">{{ $t('Numer twojego zamówienia') + ':' }}<strong>{{ order.id }}</strong></p>
          </section>
        </main>
      </SfTile>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-processing-icon-color: var(--sf-status-color-warning);
    --sf-order-processing-icon-background: var(--sf-status-background-warning);
  }

  .order-processing {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    text-align: center;

    &__confirmation {
      position: relative;
      width: 100%;
      max-width: 700px;
      margin-top: 3rem;
    }

    &__icon-wrapper {
      --sf-spinner-color: var(--sf-order-processing-icon-color);
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
      height: 100px;
      width: 100px;
      font-size: 3rem;
      border-radius: 50%;
      background-color: var(--sf-order-processing-icon-background);
    }

    &__content,
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__content {
      gap: 1.5rem;
    }

    &__header {
      gap: 1rem;
    }

    &__heading {
      margin: 3rem 0 2rem;
    }

    &__order {
      font-size: 1.25rem;
    }

    &__request {
      font-size: 1.25rem;
      font-weight: var(--font-weight-semi);
      padding: 2rem;
    }

    &__security {
      font-size: 0.75rem;
    }
  }

  @include media.query(lg) {
    .order-processing {
      margin: 0;
      padding: 1rem;

      &__icon-wrapper {
        width: 70px;
        height: 70px;

        svg {
          font-size: 2rem;
        }
      }

      &__confirmation {
        border-radius: var(--sf-tile-border-radius, 0);
        box-shadow: var(--sf-tile-interactive-box-shadow);
        padding: 1rem 0.5rem;
      }

      &__header {
        margin-top: 1rem;
      }

      &__heading {
        font-size: 1.5rem;
        margin: 1rem 0;
      }
    }

  }
</style>
