<script setup lang="ts">
  import { useSession, useVisitorForm, $get, route } from '@plenny/visitor';
  import { ref, onMounted } from 'vue';

  const loading = ref(true);
  const addresses = ref([]);
  const address = ref();

  const session = useSession();
  const open = ref(false);

  const { set } = useVisitorForm();

  function onSelected(address: any) {
    set('shipping_company', address.company);
    set('shipping_name', address.name);
    set('shipping_street', address.street);
    set('shipping_building_nr', address.building_nr);
    set('shipping_apartment_nr', address.apartment_nr);
    set('shipping_first_line', address.first_line);
    set('shipping_second_line', address.second_line);
    set('shipping_third_line', address.third_line);
    set('shipping_postcode', address.postcode);
    set('shipping_city', address.city);
    set('shipping_email', address.email);
    set('shipping_phone', address.phone);

    open.value = false;
  }

  onMounted(() => {
    $get(route('api.v1.web.cart.address.shipping.show')).then((res) => {
      addresses.value = res;
      loading.value = false;

      address.value = addresses.value.find((address: any) => {
        return address.default === true;
      });

      if (address.value) {
        onSelected(address.value);
      }
    });
  });
</script>
<template>
  <div class="shipping-address">
    <div class="shipping-address__section-data">
      <div v-if="session.is_authenticated" class="shipping-address__content">
        <div v-if="addresses.length" class="shipping-address__saved-addresses">
          <SfButton primary type="button" @click="open = true">
            <template #before>
              <SfIconBook />
            </template>
            <template #default>
              {{ $t('Wypełnij z zapisanych adresów') }}
            </template>
          </SfButton>
          <SfModal v-model:open="open">
            <SfModalHeader v-model:open="open">
              {{ $t('Zapisane Adresy') }}
            </SfModalHeader>
            <ShippingCourierPicker
              :addresses="addresses"
              :loading="loading"
              @selected="onSelected"
            />
          </SfModal>
        </div>
      </div>
      <div class="shipping-address__address-form">
        <SfAddressForm
          prefix="shipping"
          external-country="country_code"
          named contactable
          name-required
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .shipping-address {
    max-width: 672px;

    &__section-data {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
</style>
