import { ref, onMounted, onBeforeUnmount } from 'vue';

type Callback = (args: { down: boolean, up: boolean, scrolled: boolean }) => void;
type Options = { threshold?: number, callback?: Callback };

export function useScrollDetector(options?: Options) {
  const isScrollingDown = ref(false);
  const isScrollingUp = ref(false);
  const isScrolled = ref(false);

  let heightPrevious: number;
  let scrollPrevious: number;

  function onScroll() {
    let dH = heightPrevious - document.body.scrollHeight;
    let dS = document.body.scrollTop - scrollPrevious;
    let delta = dH + dS;

    heightPrevious = document.body.scrollHeight;
    scrollPrevious = document.body.scrollTop;

    if (delta === 0 || dS === 0 || Math.abs(delta) < (options?.threshold || 0)) {
      return;
    }

    isScrollingDown.value = delta > 0;
    isScrollingUp.value = delta < 0;
    isScrolled.value = document.body.scrollTop > 0;

    if (options && options.callback) {
      options.callback({
        down: isScrollingDown.value,
        up: isScrollingUp.value,
        scrolled: isScrolled.value,
      });
    }
  }

  onMounted(() => {
    heightPrevious = document.body.scrollHeight;
    scrollPrevious = document.body.scrollTop;

    document.body.addEventListener('scroll', onScroll, { passive: true });
  });

  onBeforeUnmount(() => {
    document.body.removeEventListener('scroll', onScroll);
  });

  return { isScrollingDown, isScrollingUp, isScrolled };
}
