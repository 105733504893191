<script setup lang="ts">
  import { $delete, route } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  defineProps({
    isCartEmpty: { type: Boolean, required: true },
  });

  function actionEmpty() {
    if (confirm(trans('Czy na pewno chcesz usunąć wszystkie produkty z koszyka?'))) {
      $delete(route('web.cart.destroy'));
    }
  }
</script>
<template>
  <SfButton :disabled="isCartEmpty" transparent small danger @click="actionEmpty">
    <template #before>
      <SfIconClose />
    </template>
    <template #default>
      {{ $t('Wyczyść koszyk') }}
    </template>
  </SfButton>
</template>
