<script setup lang="ts">
  import { computed } from 'vue';
  import { useVisitorForm } from '@plenny/visitor';
  import { OrderFormShippingDataCollect, OrderFormShippingDataFurgonetka, OrderFormShippingDataEmpty } from '$components';

  type OrderShippingDataForm = {
    shipping_option_driver: string;
    shipping_option_id: number;
  }

  const { data } = useVisitorForm<OrderShippingDataForm>();

  const driver = computed(() => {
    switch (data.value.shipping_option_driver) {
      case 'collect':
        return OrderFormShippingDataCollect;
      case 'furgonetka':
        return OrderFormShippingDataFurgonetka;
      default:
        return OrderFormShippingDataEmpty;
    }
  });
</script>
<template>
  <div class="shipping-data-form">
    <div class="shipping-data-form__form">
      <component :is="driver" />
    </div>
  </div>
</template>
