<script setup lang="ts">
  import { useVisitorForm } from '@plenny/visitor';

  type OrderBilling = {
    billing_method_option_id: number | null;
    billing_method_option_name: string | null;
  }

  const { data } = useVisitorForm<OrderBilling>();
</script>
<template>
  <div class="billing-option-summary" v-if="data.billing_method_option_id">
    <SfIconWallet class="billing-option-summary__icon" />
    <div class="billing-option-summary__content">
      <span class="billing-option-summary__property">{{ $t('Sposób płatności') + ':' }}</span>
      <span class="billing-option-summary__value">{{ data.billing_method_option_name }}</span>
    </div>
  </div>
</template>
<style lang="scss">
  .billing-option-summary {
    display: flex;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem;

    &__icon {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__value {
      font-weight: var(--font-weight-semi);
    }
  }
</style>
