<script lang="ts" setup>
  import { ref } from 'vue';

  defineProps({
    isCartEmpty: { type: Boolean, required: true },
  });

  const open = ref(false);
</script>
<template>
  <div class="wishlist-cart-button" @click.prevent.stop="open = true">
    <slot>
      <SfButton :disabled="isCartEmpty" class="wishlist-cart-button__favorite" small transparent>
        <template #before>
          <SfIconHeart />
        </template>
        <template #default>
          {{ $t('Dodaj koszyk do listy') }}
        </template>
      </SfButton>
    </slot>
    <SfModal v-model:open="open">
      <SfModalHeader v-model:open="open">
        {{ $t('Dodaj do ulubionych') }}
      </SfModalHeader>
      <CartWishlistPickerModal @saved="open = false" />
    </SfModal>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .wishlist-cart-button {
    display: contents;

    @include media.query(lg) {
      font-size: 1.125rem;
    }

    &__favorite {
      &.saved, &:hover {
        color: var(--primary-color);
      }
    }
  }
</style>
