import { ref, onMounted } from 'vue';

export function useScrollWidth() {
  const width = ref(0);

  onMounted(() => {
    width.value = document.body.offsetWidth - document.body.clientWidth;
  });

  return width;
}
