<script setup>
  import { computed } from 'vue';
  import { route } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    parent: { type: Object, required: false },
    category: { type: Object, required: false },
    categories: Array,
  });

  const title = computed(() => {
    if (props.parent) {
      return props.parent.title;
    } else if (props.category) {
      if (props.category._rgt === (props.category._lft + 1)) {
        return trans('Wszystkie');
      } else {
        return props.category.title;
      }
    } else {
      return trans('Wszystkie');
    }
  });

  const back = computed(() => {
    if (props.parent) {
      return route('web.blog.category.show', { category: props.parent });
    } else if (props.category) {
      return route('web.blog.post.index');
    } else {
      return null;
    }
  });

</script>
<template>
  <section class="category-blog">
    <SfHeading type="h2" class="category-blog__title">
      {{ $t('Kategorie') }}
    </SfHeading>
    <article class="category-blog__content">
      <VisitorLink v-if="back" :href="back">
        {{ $t('Wróć do: :title', { title }) }}
      </VisitorLink>
      <ul class="category-blog__list">
        <li v-for="category in categories" class="category-blog__item">
          <VisitorLink :href="route('web.blog.category.show', { category })">
            {{ category.title }}
          </VisitorLink>
        </li>
      </ul>
    </article>
  </section>
</template>
<style lang="scss" scoped>
  .category-blog {
    display: flex;
    flex-direction: column;
    gap: 2.375rem;

    @media (max-width: 992px) {
      background: var(--primary-color);
      color: var(--color-white);
      padding: 1.5rem;
      gap: 1rem;
    }

    @media (max-width: 576px) {
      padding: 1rem;
    }

    &__title {
      font-size: 1.125rem;
      text-transform: uppercase;
    }

    &__list {
      list-style: none;
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.325rem;

      @media(max-width: 992px) {
        display: grid;
        gap: 0.5rem;
        padding: 0;
        grid-template-columns: repeat(4, 1fr);
      }

      @media(max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media(max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__item {
      @media(max-width: 992px) {
        padding: 0.2rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      a {
        @media (max-width: 992px) {
          color: var(--color-white);
        }
      }
    }
  }
</style>
