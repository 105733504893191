<script lang="ts" setup>
  import type { PropType } from 'vue';
  import { useBreakpoints } from '$storefront';
  import type { Statuses, Pagination } from '$types';

  type Order = {
    currency_code: string;
    currency_decimals: number;
    id: number;
    items_count: number;
    ordered_at: string;
    status: Statuses;
    total_gross: string;
  }

  type AccountOrders = Pagination & {
    data: Order[];
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    orders: { type: Object as PropType<AccountOrders>, required: true },
  });

  const breakpoints = useBreakpoints();
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Moje zamówienia') }}
  </AccountMobileNavigation>
  <SfTile large v-if="orders.data.length > 0" class="account-orders">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">{{ $t('Moje zamówienia') }}</h1>
    </template>

    <template #default>
      <div class="account-orders__content">
        <SfListCard v-for="order in orders.data" :key="order.id" class="account-orders">
          <template #icons>
            <SfOrderStatus :status="order.status" />
          </template>
          <template #default>
            <div class="account-orders__header">
              <h2 class="account-orders__heading">{{ $t('Nr') }} {{ order.id }}</h2>
              <p class="account-orders__date">
                <SfDateTime :value="order.ordered_at" />
              </p>
            </div>
            <div class="account-orders__content">
              <span class="account-orders__count">
                <template v-if="order.items_count > 1">
                  {{ order.items_count }} {{ $t('produkty') }}
                </template>
                <template v-else>
                  {{ order.items_count }} {{ $t('produkt') }}
                </template>
              </span>
              <span class="account-orders__price">
                <SfMoney :code="order.currency_code" :decimals="order.currency_decimals" :value="order.total_gross" />
              </span>
            </div>
          </template>

          <template #actions>
            <SfButton :href="route('web.account.order.show', { order })" class="account-orders__details">
              {{ $t('Zobacz szczegóły') }}
            </SfButton>
          </template>
        </SfListCard>
      </div>
    </template>
  </SfTile>

  <SfTile v-else>
    <SfDataPlaceholder>
      {{ $t('Obecnie, nie masz zapisanych żadnych zamówień.') }}
    </SfDataPlaceholder>
  </SfTile>
  <SfPagination :lastPage="orders.last_page" :links="orders.links" :currentPage="orders.current_page" />
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .account-orders {

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__heading {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__date,
    &__count {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }

    &__price {
      font-weight: var(--font-weight-bold);
      margin-top: 0.125rem;
    }

    &__details {
      margin-top: 1rem;
    }
  }

  @include media.query(lg) {
    .account-orders {
      row-gap: 1rem;

      &__content .list-card__actions {
        gap: 0.75rem;
      }
    }
  }
</style>
