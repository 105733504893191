<script setup lang="ts">
  import { useBreakpoints } from '$storefront';
  import { $post, route } from '@plenny/visitor';

  const breakpoints = useBreakpoints();

  const props = defineProps({
    offer: { type: Object, required: true },
    quotation: { type: Object, required: true },
  });

  function accept() {
    $post(route('web.account.offers.quotations.accept', {
      offer: props.quotation.sale_offer_id,
      quotation: props.quotation.id,
    }));
  }

  function reject() {
    $post(route('web.account.offers.quotations.reject', {
      offer: props.quotation.sale_offer_id,
      quotation: props.quotation.id,
    }));
  }
</script>
<template>
  <div class="order-completion">
    <SfTile large>
      <template #title>
        <div class="order-completion__header">
          <h1 class="account__heading" v-if="breakpoints.lg">
            {{ $t('Oferta Nr') }} {{ quotation.id }}
          </h1>
          <span class="order-completion__date">
            {{ $t('Data ostatniej aktualizacji:') }}
            <SfDateTime :value="quotation.updated_at" />
          </span>
        </div>
      </template>
      <template #headerAction>
        <div class="order-completion__status">
          <SfOrderStatus :status="quotation.status" />
        </div>
      </template>
      <template #default>
        <div class="order-completion__products">
          <OrderDetailsLine v-for="item in quotation.items" :key="item.id" :item="item" />
        </div>
        <OrderDetailsSummary :order="quotation" />
      </template>
    </SfTile>
    <div v-if="offer.status === 'NEW' || offer.status === 'PROCESSING'" class="order-completion__button-wrapper">
      <SfButton primary @click="accept">
        {{ $t('Zaakceptuj') }}
      </SfButton>
      <SfButton danger @click="reject">
        {{ $t('Odrzuć') }}
      </SfButton>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-details-old-price: var(--sf-product-price-catalog-color, rgb(200, 0, 0));
  }

  .order-completion {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    &__products,
    &__product-content,
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__date {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
      font-weight: var(--font-weight-semi);
    }

    &__status {
      font-weight: var(--font-weight-semi);
      color: var(--sf-status-color-success);
      margin-left: 0.375rem;
    }

    &__data {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-start;
      gap: 2rem;

      .placeholder {
        --sf-data-placeholder-padding: 0.375rem
      }
    }

    &__data-info {
      font-size: 0.875rem;
    }

    &__button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 0.75rem;
    }
  }

  @include media.query(xxl) {
    .order-completion__data {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }

  @include media.query(xl) {
    .order-completion__data {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media.query(lg) {
    .order-completion {
      row-gap: 1rem;

      &__heading {
        font-size: 1.375rem;
      }

      &__status {
        margin-top: 0.375rem;
      }

      &__product {
        padding-bottom: 1rem;
      }

      &__data-heading {
        font-size: 1rem;
      }
    }
  }

  @include media.query(sm) {
    .order-completion {
      &__data {
        grid-template-columns: 1fr;
      }

      &__product-heading {
        font-size: 1rem;
      }

      &__product-price {
        font-size: 0.875rem;
      }

      &__wrapper {
        gap: 0.625rem;
        padding: 0.625rem 0.125rem;
      }

      &__content {
        gap: 0.625rem;
      }

      &__button-wrapper .btn {
        flex-grow: 1;
      }
    }
  }
</style>
