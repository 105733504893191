<script setup lang="ts">
  import { ref, onMounted, type PropType, type Ref, onBeforeUnmount, onUpdated } from 'vue';
  import type { SwiperOptions } from 'swiper/types';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';

  type Brand = {
    id: number;
    name: string;
    slug: string;
    photo: string | null;
  }

  const props = defineProps({
    brands: { type: Array as PropType<Brand[]>, require: true },
    parameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      ...props.parameters,
      modules: [Navigation],
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel brands-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container brands-carousel__container">
      <div class="swiper-wrapper">
        <div class="swiper-slide items-carousel__slide brands-carousel__article" v-for="brand in brands">
          <MediaPicture :photo="brand.photo" type="logo" sizes="430x380 2x, 215x160" loading="lazy" />
          <div class="swiper-lazy-preloader" />
        </div>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
