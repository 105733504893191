<script setup lang="ts">
  import { $post, route } from '@plenny/visitor';

  function onSubmit(data: { email: string }) {
    return $post(route('web.account.password.forgotten.store'), data);
  }
</script>
<template>
  <div class="password">
    <SfTile large>
      <template #title>
        <h1 class="account-heading">{{ $t('Zresetuj hasło') }}</h1>
      </template>
      <template #default>
        <VisitorForm @submit="onSubmit" v-slot="{ submitting }">
          <div class="password__inputs">
            <SfFormInput :label="$t('Adres e-mail')" name="email" required />
            <div class="password__buttons-wrapper">
              <SfButton :href="route('web.account.session.create')">
                {{ $t('Anuluj') }}
              </SfButton>
              <SfButton type="submit" :loading="submitting" primary>{{ $t('Wyślij link') }}</SfButton>
            </div>
          </div>
        </VisitorForm>
      </template>
    </SfTile>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .password {
    width: 98%;
    max-width: 600px;
    margin: 100px auto;

    &__inputs {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
    }

    &__buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  @include media.query(lg) {
    .password {
      align-items: stretch;

      &_header {
        justify-content: center;
      }

      &__buttons-wrapper {
        flex-direction: column;
      }
    }
  }
</style>
