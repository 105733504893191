<script lang="ts" setup>
  import { $get, route } from '@plenny/visitor';
  import { type PropType } from 'vue';

  type AttributeOption = {
    label: string,
    value: number,
  };

  type Attribute = {
    label: string,
    options: AttributeOption[],
    value: number,
  };

  const variant = defineModel('variant', { type: Object, required: false });
  const attributes = defineModel('attributes', { type: Array as PropType<Attribute[]>, required: true });
  const selected = defineModel('selected', { type: Object, required: true });
  const variants = defineModel('variants', { type: Object, required: true });
  const series = defineModel('series', { type: Object, required: true });

  const props = defineProps({
    product: { type: Object, required: true },
  });

  function actionSearch() {
    $get(route('api.v1.web.product.variant.index', { product: props.product.id, selected: selected.value })).then((response) => {
      variant.value = response.variant;
      attributes.value = response.attributes;
      variants.value = response.variants;
      series.value = response.series;
    });
  }
</script>
<template>
  <div class="variant-attributes">
    <h2 class="variant-attributes__header" v-if="$slots.title">
      <slot name="title" />
    </h2>
    <SfFormSelect v-for="attribute in attributes"
      :id="`variation-${attribute.value}`"
      :key="attribute.value"
      :label="attribute.label"
      :options="attribute.options"
      v-model="selected[attribute.value]"
      @change="actionSearch"
      required
    />
  </div>
</template>
<style scoped lang="scss">
  .variant-attributes {
    display: flex;
    flex-direction: column;
    gap: var(--gap-card);

    &__header {
      font-size: 1rem;
      font-weight: var(--font-weight-bold);
    }
  }
</style>
