<script setup lang="ts">
  import { ref } from 'vue';
  import { useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { route } from '@plenny/visitor';

  const variant = defineModel('variant', { type: Object, required: true });

  defineProps({
    product: { type: Object, required: true },
  });

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const successModalVisible = ref(false);

  const showSuccessModal = () => {
    successModalVisible.value = true;
  };
</script>
<template>
  <div class="picker">
    <div class="picker__options">
      <SfVariantPicker series :product="product" v-model:variant="variant" />
    </div>
    <template v-if="canCustomerOrder">
      <div v-if="canCustomerOrder" class="picker__price">
        <SfProductPrice :price="variant" presentation />
        <div class="picker__price__annotations">
          <SfProductStock :variant="variant" />
          <SfProductPoints :variant="variant" />
        </div>
      </div>
      <div v-if="canCustomerOrder" class="picker__regulations">
        <SfProductOmnibus :price="variant" />
        <SfProductMultipackUnitPrice :price="variant" />
        <SfProductUnitPrice :price="variant" />
      </div>
      <SfCartStoreButton :variant="variant" @added="showSuccessModal" />
      <CartButtonSuccessModal :open="successModalVisible" :product="product" :variant="variant" @update:open="successModalVisible = $event" />
    </template>
    <template v-else>
      <div class="picker__guest">
        <VisitorLink v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" rel="nofollow">
          {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
        </VisitorLink>
        <span v-else>
          {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
        </span>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
  .picker {
    display: flex;
    flex-direction: column;
    gap: var(--gap-card);

    &__price {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: var(--gap-card);

      &__annotations {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &__regulations {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__guest {
      color: var(--secondary-color);
      font-weight: var(--font-weight-bold);
      font-size: 0.875rem;
    }
  }
</style>
