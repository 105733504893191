<script lang="ts" setup>
  import { ref } from 'vue';

  // On mobile row should behave like a collapse
  const open = ref(false);
</script>
<template>
  <div class="filters-row">
    <div class="filters-row__header-container" @click="open = !open">
      <div class="filters-row__header">
        <slot name="header" />
      </div>
      <SfIconChevronDown :class="{'filters-row__icon--open': open}" class="filters-row__icon" />
    </div>
    <div :class="{'filters-row__content--open': open}" class="filters-row__content">
      <slot name="content" />
    </div>
  </div>
</template>
<style lang="scss">
  @use '$assets/mixins/media';

  :root {
    --sf-filters-row-gap-lg: 1rem;
    --sf-filters-row-gap: 0;
    --sf-filters-row-border: none;
    --sf-filters-row-border-radius: 5px;
    --sf-filters-row-padding: 0.75rem;
    --sf-filters-row-padding-lg: 0.25rem 0;

    --sf-filters-row-header-background: rgb(255, 255, 255);
    --sf-filters-row-heading-background: var(--sf-filters-row-header-background);
    --sf-filters-row-heading-background-lg: var(--sf-filters-row-heading-background);
    --sf-filters-row-header-separator-color-lg: var(--color-secondary);
    --sf-filters-row-header-padding: 0;
    --sf-filters-row-header-padding-lg: 0 0.5rem 0 0;
    --sf-filters-row-header-position: static;
    --sf-filters-row-header-position-top: 0;
    --sf-filters-row-header-background-lg: rgb(255, 255, 255);

    --sf-filters-row-icon-border-radius-lg: 50%;
    --sf-filters-row-icon-padding-lg: 0.4rem;
    --sf-filters-row-icon-height-lg: 30px;
    --sf-filters-row-icon-width-lg: 30px;
    --sf-filters-row-icon-btn-background-lg: var(--sf-filters-row-header-separator-color-lg);

    --sf-filters-row-content-padding: 0.25rem 0.1rem;
    --sf-filters-row-content-padding-lg: 0;

    --sf-filters-row-heading-color: var(--color-default);
    --sf-filters-row-input-background: var(--sf-input-background);
  }

  .filters-row {
    display: flex;
    flex-direction: column;
    gap: var(--sf-filters-row-gap);
    border: var(--sf-filters-row-border);
    border-radius: var(--sf-filters-row-border-radius);
    padding: var(--sf-filters-row-padding);
    width: 100%;
    position: relative;

    @include media.query(lg) {
      border: none;
      border-radius: 0;
      padding: var(--sf-filters-row-padding-lg);
      gap: var(--sf-filters-row-gap-lg);
    }

    &__header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      background: var(--sf-filters-row-header-background);

      @include media.query(lg) {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: var(--sf-filters-row-header-separator-color-lg);
        }
      }
    }

    &__header {
      position: var(--sf-filters-row-header-position);
      top: var(--sf-filters-row-header-position-top);
      padding: var(--sf-filters-row-header-padding);
      background: var(--sf-filters-row-heading-background);
      color: var(--sf-filters-row-heading-color);
      z-index: 2;

      @include media.query(lg) {
        position: unset;
        top: 0;
        padding: var(--sf-filters-row-header-padding-lg);
        background: var(--sf-filters-row-heading-background-lg);
      }
    }

    &__icon {
      display: none;

      @include media.query(lg) {
        display: block;
        z-index: 2;
        background: var(--sf-filters-row-header-background-lg);
        border: 1px solid var(--sf-filters-row-icon-btn-background-lg);
        width: var(--sf-filters-row-icon-width-lg);
        height: var(--sf-filters-row-icon-height-lg);
        padding: var(--sf-filters-row-icon-padding-lg);
        border-radius: var(--sf-filters-row-icon-border-radius-lg);
        transition: transform 0.3s ease;

        &--open {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      --sf-input-background: var(--sf-filters-row-input-background);

      padding: var(--sf-filters-row-content-padding);
      transition: max-height 0.6s ease;

      @include media.query(lg) {
        max-height: 0;
        padding: var(--sf-filters-row-content-padding-lg);
        overflow: hidden;

        &--open {
          max-height: 750px;
          overflow-y: auto;
        }
      }
    }
  }
</style>
