<script setup>
  import { ref } from 'vue';
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  const visible = ref(false);
</script>

<template>
  <section class="frame">
    <header class="frame__title" @click="visible = !visible">
      <slot name="header" />
      <IconArrow v-if="!breakpoints.lg" :class="{'frame__icon--visible': visible}" class="frame__icon" />
    </header>
    <transition name="fade">
      <div v-if="breakpoints.lg || visible" class="frame__content">
        <slot />
      </div>
    </transition>
  </section>
</template>
<style lang="scss" scoped>
  .frame {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    row-gap: 1rem;
    height: fit-content;
    margin-bottom: 1.5rem;

    &__title {
      color: var(--primary-color);

      @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-light-background);
      }

      :deep(h1), :deep(h2), :deep(h3), :deep(h4), :deep(h5), :deep(h6) {
        font-size: 1.125rem;
        text-transform: uppercase;
      }
    }

    &__icon {
      color: var(--color-primary-black);
      transition: transform 0.4s;

      &--visible {
        transform: rotate(180deg);
      }
    }

    &__content {
      margin-left: 0.5rem;
      @media (max-width: 992px) {
        overflow-y: auto;
        max-height: 250px;
      }
    }
  }
</style>