<template>
  <svg fill="currentColor" height="1em" viewBox="0 0 64 64" width="1em" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m57.29 4.71h-12.5a2 2 0 0 0 0 4h10.5v10.5a2 2 0 0 0 4 0v-12.5a2 2 0 0 0 -2-2z" />
      <path d="m19.21 4.71h-12.5a2 2 0 0 0 -2 2v12.5a2 2 0 0 0 4 0v-10.5h10.5a2 2 0 0 0 0-4z" />
      <path d="m19.21 55.29h-10.5v-10.5a2 2 0 0 0 -4 0v12.5a2 2 0 0 0 2 2h12.5a2 2 0 0 0 0-4z" />
      <path d="m57.29 42.79a2 2 0 0 0 -2 2v10.5h-10.5a2 2 0 0 0 0 4h12.5a2 2 0 0 0 2-2v-12.5a2 2 0 0 0 -2-2z" />
    </g>
  </svg>
</template>
