<script lang="ts" setup>
  import { ref } from 'vue';
  import { useScrollDetector, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';

  const variant = defineModel('variant', { type: Object, required: false });
  const related = defineModel('related', { type: Array, required: false, default: [] });
  const success = defineModel('success', { type: Boolean, required: false, default: false });

  defineProps({
    product: { type: Object, required: true },
  });

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const isScrolled = ref(false);

  useScrollDetector({
    threshold: 8,
    callback({ scrolled }) {
      isScrolled.value = scrolled;
    },
  });

  function onAdded() {
    success.value = true;
  }
</script>
<template>
  <article class="product-side">
    <template v-if="canCustomerOrder">
      <div class="product-side__metadata">
        <Availability :variant="variant" amount />
        <SfProductPrice :price="variant || product" presentation />
      </div>
      <div class="product-side__store">
        <SfCartStoreButton :variant="variant" @added="onAdded" class="product-side__store-button" />
      </div>
    </template>
    <template v-else>
      <div class="product-side__guest">
        <VisitorLink v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" rel="nofollow">
          {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
        </VisitorLink>
        <span v-else>
          {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
        </span>
      </div>
    </template>
    <ProductMeta />
  </article>
</template>

<style lang="scss" scoped>
  .product-side {
    position: sticky;
    top: 210px;
    background: var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-primary);
    padding: 1.5rem;
    max-height: 425px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: flex-end;
    text-align: right;

    &__title {
      font-size: 0.875rem;
      text-align: left;
      color: var(--color-gray);
    }

    &__metadata {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;

      .price {
        align-items: flex-end;
        font-size: 2rem;
      }
    }

    &__store {
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      width: 100%;

      @media (max-width: 1200px) {
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;

        .control__wrapper {
          max-width: 125px;
        }
      }
    }

    &__store-button {
      :deep(.cart-store-button__button) {
        flex-wrap: wrap;

        .btn {
          flex: 1 1 auto;
        }

        .control__wrapper {
          @media (max-width: 1200px) {
            flex-grow: 1;
            width: 100%;
          }
        }
      }
    }

    &__guest {
      color: var(--secondary-color);
      font-weight: var(--font-weight-bold);
      font-size: 0.875rem;
    }
  }
</style>
