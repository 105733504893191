<script lang="ts" setup>
  import SpecialProduct from '../../../Components/products/specialProduct/SpecialProduct.vue';
  import Section from '../../../Components/products/Section.vue';
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    product: Object,
    photos: Array,
  });

</script>
<template>
  <div class="wrapper special-wrapper">
    <Section :title="header" class="sale-container">
      <SpecialProduct :product="product" />
    </Section>
    <div v-if="breakpoints.lg" class="baner-container">
      <MediaPicture :photo="photos" type="banner" />
    </div>
  </div>
</template>
<style scoped>
  .special-wrapper {
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 30px;


    .section-wrapper {
      margin: 0;
    }

    .baner-container {
      width: calc(60% - 15px);

      & :deep(img) {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .sale-container {
      width: calc(40% - 15px);
      min-width: 600px;
    }

    :deep(.section-content) {
      flex-grow: 1
    }
  }

  @media (max-width: 992px) {
    .special-wrapper {
      height: auto;

      .sale-container.section-wrapper {
        width: 100%;
        min-width: unset;
      }
    }
  }
</style>
