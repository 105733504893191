<script setup>
  import { computed, ref } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { transChoice } from '@plenny/translator';
  import { $post, route } from '@plenny/visitor';

  const props = defineProps({
    product: Object,
    sections: Array,
    brand: Object,
    categories: Array,
    page: Object,
  });

  const breakpoints = useBreakpoints();

  const variant = defineModel('variant', { type: Object, required: false });
  const related = defineModel('related', { type: Array, required: false, default: [] });
  const success = defineModel('success', { type: Boolean, required: false, default: false });

  const loading = ref(false);
  const amount = ref(1);
  const error = ref(null);

  function actionAddToCart() {
    if (variant.value) {
      loading.value = true;

      $post(route('api.v1.web.cart.item.store'), {
        variant: variant.value.id,
        amount: amount.value,
      }).then((res) => {
        related.value = res.related;
        success.value = true;
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        loading.value = false;
      });
    }
  }

  const photosParameters = ref({
    slidesPerView: 1,
    navigation: true,
    pagination: false,
    loop: false,
    watchSlidesProgress: true,
  });

  const thumbsParameters = ref({
    slidesPerView: 'auto',
    navigation: true,
    pagination: false,
    loop: false,
    centeredSlidesBounds: true,
    spaceBetween: 10,
  });

  const photos = computed(() => {
    if (variant.value && variant.value.photos) {
      return [...variant.value.photos, ...props.product.photos];
    } else {
      return props.product.photos;
    }
  });

  const title = computed(() => {
    if (variant.value?.title) {
      return variant.value.title;
    } else {
      return props.product.title;
    }
  });

  const description = computed(() => {
    if (variant.value?.description_short) {
      return variant.value.description_short;
    } else {
      return props.product.description_short;
    }
  });

  const sku = computed(() => {
    if (variant.value?.sku) {
      return variant.value.sku;
    } else {
      return props.product.sku;
    }
  });

  const stock = computed(() => {
    if (variant.value?.stock_available) {
      return variant.value.stock_available;
    } else {
      return props.product.stock_available;
    }
  });

  const realization = computed(() => {
    const day = props.product.realization_from || props.product.realization_to;

    if (day) {
      return transChoice('Wysyłamy w :day dzień roboczy|Wysyłamy w :day dni robocze|Wysyłamy w :day dni roboczych', day, { day });
    }
  });
</script>
<template>
  <div class="product-detail">
    <div class="product-detail__main">
      <div class="product-detail__left">
        <ProductLabels :product="product" />
        <SfProductGallery v-bind="{ thumbsParameters, photosParameters, photos: [variant?.photo, ...product.photos]}" />
      </div>
      <div class="product-detail__right">
        <article class="product-detail__description">
          <SfHeading type="h1" class="product-detail__name" v-html="variant?.title || product?.title" />
          <p class="product-detail__code">
            {{ $t('Kod:') }}
            <span>{{ variant?.sku || product?.sku }}</span>
          </p>
          <SfStars :count="product.reviews_count" :rating="product.reviews_average" />
          <div class="product-detail__short-description" v-html="product.description_short" />
          <ProductAttributes :product="product" horizontal />
          <div class="product-detail__store">
            <div v-if="!breakpoints.lg" class="product-detail__price">
              <Availability :variant="variant" amount/>
              <SfProductPrice :price="variant || product" />
            </div>
            <SfVariantPicker v-model:variant="variant" :product="product" class="product-detail__variants" />
            <div v-if="!breakpoints.lg" class="product-detail__actions">
              <SfFormNumber v-model="amount" min="1" required step="1" />
              <SfButton primary @click="actionAddToCart">
                <template #before>
                  <IconCart />
                </template>
                {{ $t('Dodaj do koszyka') }}
              </SfButton>
            </div>
          </div>
          <ProductMeta v-if="!breakpoints.lg" />
        </article>
        <div v-if="realization && variant" class="product-detail__info">
          <div v-if="realization" class="product-detail__order-time">{{ realization }}</div>
          <Availability v-if="stock" :variant />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .product-detail {
    &__main {
      background: var(--color-white);
      border-radius: 20px;
      box-shadow: var(--box-shadow-primary);
      padding: 1.5rem 1rem 1rem;

      @media (max-width: 768px) {
        padding: 0.25rem;
      }

      @media (max-width: 992px) {
        border-radius: 0;
      }
    }

    &__metadata {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;

      .price {
        align-items: flex-end;
        font-size: 2rem;
      }
    }

    &__store {
      display: grid;
      grid-template-rows: 1fr 1fr;
      margin-top: 1rem;
    }

    &__price {
      --sf-product-price-current-font-size: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__shipment-info {
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__order-info {
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.375rem;

      img {
        width: 100%;
        margin-top: 0.2rem;
      }
    }

    &__product-request {
      border-bottom: 1px solid var(--color-light-background);
      padding: 0.2rem 0;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;

      span {
        color: var(--primary-color);
        font-size: 0.875rem;
      }

      a {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    &__auth {
      padding: 0.2rem 0;
      font-size: 0.75rem;

      a {
        color: var(--primary-color);
      }
    }

    &__number {
      width: 60px;
    }

    &__left {
      overflow: hidden;
      position: relative;
      padding: 0 1rem;
    }

    &__wishlist {
      :deep(.wishlist-button__favorite) {
        position: absolute;
        z-index: 99;
        right: 10px;
        background: var(--color-white);
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__main {
      display: grid;
      grid-template-columns: 55% 45%;

      @media (max-width: 1200px) {
        grid-template-columns: 60% 40%;
      }

      @media (max-width: 992px) {
        grid-template-columns: 1fr;
      }
    }

    &__description {
      border-left: 1px solid var(--color-light-background);
      padding: 1rem;
      height: 80%;

      @media (max-width: 992px) {
        border: none;
      }

      @media (max-width: 576px) {
        padding: 1rem 0.5rem;
      }
    }

    &__name {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
    }

    &__code {
      font-size: 0.75rem;
      margin-bottom: 0.875rem;

      span {
        font-weight: bold;
      }
    }

    &__short-description {
      font-size: 0.875rem;
      word-break: break-word;
    }

    &__variants {
      width: 40%;

      @media (max-width: 1400px) {
        width: 75%;
      }

      @media (max-width: 992px) {
        margin: 0 0 0.5rem 0;
      }

      @media (max-width: 992px) {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        :deep(.variant-attributes) {
          width: 100%;
          gap: 0.5rem;
        }
      }
    }

    &__info {
      font-size: 0.75rem;
      gap: 0.75rem;
      display: flex;
      padding: 1rem;
    }

    &__order-time {
      background: var(--color-light-background);
      padding: 0.5rem 0.75rem;
      border-radius: 6px;
    }

    &__availability {
      padding: 0.5rem 0.75rem;

      span {
        color: var(--primary-color);
      }
    }
  }
</style>
