<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    primary: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
  });

  const spinnerClasses = computed(() => {
    return {
      'spinner--primary': props.primary,
      'spinner--small': props.small,
      'spinner--large': props.large,
    };
  });
</script>
<template>
  <span :class="spinnerClasses" class="spinner">
    <span class="spinner-icon" />
  </span>
</template>
<style lang="scss">
  .spinner {
    --sf-spinner-color: var(--secondary-color);
    --sf-spinner-primary-color: var(--primary-color);
    --sf-spinner-size: 1.25rem;
    --sf-spinner-size-sm: 0.875rem;
    --sf-spinner-size-lg: 1.5rem;
  }

  .spinner {
    display: inline-flex;
    width: var(--sf-spinner-size);
    height: var(--sf-spinner-size);

    .spinner-icon {
      display: inline-flex;
      width: var(--sf-spinner-size);
      height: var(--sf-spinner-size);
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: var(--sf-spinner-color);
      border-left-color: var(--sf-spinner-color);
      border-radius: 50%;
      animation: spin 400ms linear infinite;
    }

    &--primary {
      --sf-spinner-color: var(--sf-spinner-primary-color);
    }

    &--square {
      --sf-spinner-size: var(--sf-spinner-square-size);
    }

    &--small {
      --sf-spinner-size: var(--sf-spinner-size-sm);
    }

    &--large {
      --sf-spinner-size: var(--sf-spinner-size-lg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
