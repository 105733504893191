<script setup lang="ts">
  import { onMounted, ref, onBeforeUnmount, computed } from 'vue';
  import { Features } from '$features';

  declare global {
    interface WindowEventMap {
      beforeinstallprompt: BeforeInstallPromptEvent;
    }
  }

  type PromptResult = {
    outcome: 'accepted' | 'dismissed',
  }

  type BeforeInstallPromptEvent = Event & {
    prompt: () => Promise<PromptResult>,
  }

  const installation = ref<BeforeInstallPromptEvent>();
  const visible = ref(false);
  const outcome = ref();

  const show = computed(() => {
    return Features.pwa.isEnabled() && visible.value && outcome.value === undefined;
  });

  function onBeforeInstallPrompt(event: BeforeInstallPromptEvent) {
    event.preventDefault();

    installation.value = event;
    visible.value = true;
  }

  function onInstall() {
    if (installation.value) {
      installation.value.prompt().then((result) => {
        outcome.value = result.outcome;
        visible.value = false;
      });
    }
  }

  function onCancel() {
    visible.value = false;
  }

  onMounted(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
  });
</script>
<template>
  <transition name="pwa-install-prompt-slide-in" :duration="300">
    <div v-if="show" class="pwa-install-prompt">
      <div class="pwa-install-prompt__backdrop" />
      <div class="pwa-install-prompt__wrapper" role="dialog">
        <div class="pwa-install-prompt__container">
          <div class="pwa-install-prompt__icon">
            <SfIconBoxArrowDown />
          </div>
          <div class="pwa-install-prompt__content">
            <h2 v-html="$t('Zainstaluj aplikację')" />
            <p v-html="$t('Możesz skorzystać z naszej aplikacji, aby szybciej i łatwiej korzystać z naszego sklepu, oraz otrzymywać powiadomienia bezpośrednio na swoim urządzeniu.')" />
          </div>
          <div class="pwa-install-prompt__actions">
            <SfButton primary @click="onInstall">
              {{ $t('Zainstaluj aplikację') }}
            </SfButton>
            <SfButton @click="onCancel">
              {{ $t('Kontynuuj w przeglądarce') }}
            </SfButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-pwa-install-prompt-background: var(--sf-modal-background, #fff);
    --sf-pwa-install-prompt-backdrop: var(--backdrop-default, rgba(0, 0, 0, 0.25));
    --sf-pwa-install-prompt-foreground: var(--secondary-color);
    --sf-pwa-install-prompt-icon-foreground: var(--primary-color);
    --sf-pwa-install-prompt-padding-x: 1rem;
    --sf-pwa-install-prompt-padding-y: 2rem;
    --sf-pwa-install-prompt-gap: 2rem;
    --sf-pwa-install-prompt-box-shadow: var(--secondary-box-shadow-200);
  }

  .pwa-install-prompt {
    display: contents;

    &__wrapper {
      position: fixed;
      background: var(--sf-pwa-install-prompt-background);
      box-shadow: var(--sf-pwa-install-prompt-box-shadow);
      color: var(--sf-pwa-install-prompt-foreground);
      padding: var(--sf-pwa-install-prompt-padding-y) var(--sf-pwa-install-prompt-padding-x);
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10000;
    }

    &__backdrop {
      background: var(--sf-pwa-install-prompt-backdrop);
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 1;
      z-index: 10000;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1600px;
      margin: 0 auto;
      gap: var(--sf-pwa-install-prompt-gap);

      @include media.query(md) {
        flex-direction: column;
      }
    }

    &__icon {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      color: var(--sf-pwa-install-prompt-icon-foreground);
    }

    &__content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: calc(var(--sf-pwa-install-prompt-gap) / 2);

      h2 {
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
      }

      p {
        font-size: 0.875rem;
      }
    }

    &__actions {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: calc(var(--sf-pwa-install-prompt-gap) / 2);

      @include media.query(md) {
        flex-direction: column;
        align-items: stretch;
      }
    }

    &-slide-in-enter-active,
    &-slide-in-leave-active {
      .pwa-install-prompt__backdrop {
        transition-property: opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 300ms;
      }

      .pwa-install-prompt__wrapper {
        transition-property: bottom;
        transition-timing-function: ease-in-out;
        transition-duration: 300ms;
      }
    }

    &-slide-in-enter-from,
    &-slide-in-leave-to {
      .pwa-install-prompt__backdrop {
        opacity: 0;
      }

      .pwa-install-prompt__wrapper {
        bottom: -100%;
      }
    }
  }
</style>
