<script setup lang="ts">
  import { type PropType, computed } from 'vue';

  type TagType = 'novelty' | 'discount';

  const props = defineProps({
    visible: { type: Boolean, required: false, default: false },
    tagType: { type: String as PropType<TagType>, required: false },
    icon: { type: Boolean, required: false, default: false },
  });

  const classes = computed(() => {
    return {
      'tag--novelty': props.tagType === 'novelty',
      'tag--discount': props.tagType === 'discount',
    };
  });
</script>
<template>
  <div v-if="visible" class="tag" :class="classes">
    <span v-if="icon" class="tag__icon">
      <slot name="icon">
        <SfIconTags />
      </slot>
    </span>
    <span class="tag__value">
      <slot />
    </span>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-product-tag-radius: var(--border-radius);
    --sf-product-tag-background: var(--primary-background);
    --sf-product-tag-background-novelty: var(--primary-background);
    --sf-product-tag-background-discount: var(--primary-background);
    --sf-product-tag-color: var(--primary-foreground);
    --sf-product-tag-color-novelty: var(--primary-foreground);
    --sf-product-tag-color-discount: var(--primary-foreground);
    --sf-product-tag-font-size: 0.75rem;
    --sf-product-tag-line-height: 1rem;
    --sf-product-tag-icon-size: 1rem;
    --sf-product-tag-gap: 0.5rem;
    --sf-product-tag-padding: 0.25rem 0.5rem;
  }
</style>
<style scoped lang="scss">
  .tag {
    display: inline-flex;
    align-items: center;
    padding: var(--sf-product-tag-padding);
    border-radius: var(--sf-product-tag-radius);
    background-color: var(--sf-product-tag-background);
    color: var(--sf-product-tag-color);
    font-weight: var(--font-weight-bold);
    font-size: var(--sf-product-tag-font-size);
    line-height: var(--sf-product-tag-line-height);
    gap: var(--sf-product-tag-gap);

    &--novelty {
      --sf-product-tag-color: var(--sf-product-tag-color-novelty);
      --sf-product-tag-background: var(--sf-product-tag-background-novelty);
    }

    &--discount {
      --sf-product-tag-color: var(--sf-product-tag-color-discount);
      --sf-product-tag-background: var(--sf-product-tag-background-discount);
    }

    &__icon {
      display: flex;
      font-size: var(--sf-product-tag-icon-size);
    }
  }
</style>
