<script setup lang="ts">
  import { computed, type PropType, type Ref } from 'vue';
  import { useShared } from '@plenny/visitor';
  import { UnitFormatter } from '$support';
  import { Unit } from '$types';

  interface Price {
    stock_unit: Unit;
    stock_unit_base: string; // DECIMAL(8,4)
    multipack_unit_price_net?: string; // DECIMAL(19,4)
    multipack_unit_price_gross?: string; // DECIMAL(19,4)
  }

  interface Shared {
    include_vat: boolean;
  }

  const props = defineProps({
    price: { type: Object as PropType<Price>, required: true },
  });

  const shared = useShared() as Ref<Shared>;

  const price = computed(() => {
    if (shared.value.include_vat) {
      return props.price.multipack_unit_price_gross;
    } else {
      return props.price.multipack_unit_price_net;
    }
  });

  const unit = computed(() => {
    let stockUnit = props.price.stock_unit;
    let stockBase = props.price.stock_unit_base;

    if (stockUnit && stockBase) {
      return UnitFormatter.accusative(stockUnit, stockBase);
    }

    return undefined;
  });

  const visible = computed(() => {
    return price.value && unit.value;
  });
</script>
<template>
  <div v-if="visible && unit && price" class="unit-price">
    <span v-html="$t('Cena za :unit w zestawie: ', { unit })" />
    <SfMoney :value="price" />
  </div>
</template>
<style lang="scss">
  :root {
    --multipack-unit-price-color-text: var(--color-default);
    --multipack-unit-price-font-family: var(--font-family-sans);
    --multipack-unit-price-font-size: var(--font-size-sm);
    --multipack-unit-price-font-weight: var(--font-weight-regular);
    --multipack-unit-price-padding-y: 0;
    --multipack-unit-price-padding-x: 0;
    --multipack-unit-price-margin-y: 0;
    --multipack-unit-price-margin-x: 0;
  }
</style>
<style scoped lang="scss">
  .unit-price {
    font-size: var(--multipack-unit-price-font-size);
    font-family: var(--multipack-unit-price-font-family);
    color: var(--multipack-unit-price-color-text);
    font-weight: var(--multipack-unit-price-font-weight);
    padding: var(--multipack-unit-price-padding-y) var(--multipack-unit-price-padding-x);
    margin: var(--multipack-unit-price-margin-y) var(--multipack-unit-price-margin-x);
  }
</style>
