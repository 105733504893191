<script setup lang="ts">
  import { useQuery } from '@plenny/visitor';
  import { computed } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    module: { type: Object, required: true },
    page: { type: Object, required: true },
    category: { type: Object, required: false },
    categories: { type: Object, required: true },
  });

  const query = useQuery();

  const nested = computed(() => {
    return !!props.categories.parent;
  });

  const filtered = computed(() => {
    return query.value && Object.values(query.value).length > 0;
  });

  const classes = computed(() => {
    return {
      'shop-categories--children': nested.value,
    };
  });
</script>
<template>
  <div :class="classes" class="shop-categories">
    <div v-if="nested || filtered" class="shop-categories__actions">
      <SfButton v-if="nested" :href="url(categories.parent.url, query)" small transparent>
        <template #before>
          <SfIconChevronLeft class="shop-categories__icon-back" />
        </template>
        <template #default>
          {{ categories.parent.name }}
        </template>
      </SfButton>
      <SfButton v-if="filtered" :href="page.meta_url" small transparent>
        <template #before>
          <SfIconClose class="shop-categories__icon-close" />
        </template>
        <template #default>
          {{ $t('Wyczyść filtry') }}
        </template>
      </SfButton>
    </div>
    <div class="shop-categories__content">
      <CategoryCard v-for="category in categories.children" :category="category" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .shop-categories {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    margin: 1rem auto;

    &__actions {
      display: flex;
      flex-direction: row;
      gap: 0.875rem;
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 2rem;
      width: 100%;
    }

    &__icon-back, &__icon-close {
      font-size: 0.75rem;
    }
  }

  @media(max-width: 1400px) {
    .shop-categories__content {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media(max-width: 1200px) {
    .shop-categories__content {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media(max-width: 992px) {
    .shop-categories {
      margin: 0.5rem auto 1rem;
      width: 95%;

      &__content {
        gap: 0.75rem;
        grid-template-columns: repeat(2, 1fr);
      }

      &--children .shop-categories__content {
        margin-top: 1rem;
      }
    }
  }

  @media(max-width: 576px) {
    .shop-categories__content {
      grid-template-columns: 1fr;
    }
  }
</style>
