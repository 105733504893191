<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.238 32.238" height="1em" width="1em" >
    <path stroke="none"
      d="M32.565,24.5a1.877,1.877,0,0,1,.256-.945,2.609,2.609,0,0,1,.163-.24,12.557,12.557,0,0,0,2.139-7c.023-7.145-6.006-12.941-13.461-12.941a13.368,13.368,0,0,0-13.2,10.3,12.453,12.453,0,0,0-.287,2.65,13.145,13.145,0,0,0,13.251,13.1,15.959,15.959,0,0,0,3.658-.6c.876-.24,1.744-.558,1.968-.643a2.049,2.049,0,0,1,.721-.132,2.015,2.015,0,0,1,.783.155l4.394,1.558a1.048,1.048,0,0,0,.3.077.617.617,0,0,0,.62-.62,1,1,0,0,0-.039-.209Z"
      transform="translate(-2.885 -3.375)" fill="currentColor" />
    <path stroke="none"
      d="M24.337,29.1c-.279.077-.635.163-1.023.248a14.3,14.3,0,0,1-2.635.349,13.145,13.145,0,0,1-13.251-13.1,14.651,14.651,0,0,1,.116-1.658c.046-.333.1-.666.178-.992.077-.349.17-.7.271-1.038l-.62.55a11.533,11.533,0,0,0-4,8.672A11.4,11.4,0,0,0,5.3,28.479c.178.271.279.48.248.62s-.922,4.8-.922,4.8a.622.622,0,0,0,.209.6.631.631,0,0,0,.4.139.556.556,0,0,0,.225-.046L9.8,32.88a1.211,1.211,0,0,1,.93.015,13.042,13.042,0,0,0,4.7.93,12.171,12.171,0,0,0,9.307-4.27s.248-.341.535-.744C24.988,28.913,24.663,29.013,24.337,29.1Z"
      transform="translate(-3.375 -2.402)" fill="currentColor" />
  </svg>
</template>
