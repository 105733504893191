<script setup lang="ts">
  import { ref, watch } from 'vue';

  const props = defineProps({
    product: { type: Object, required: true },
    variant: { type: Object, required: false },
  });

  const picker = ref(false);
  const success = ref(false);
  const variant = ref(props.variant);
  const related = ref([]);

  function onClose() {
    if (!success.value) {
      variant.value = props.variant;
      related.value = [];
    }
  }

  function onReset() {
    variant.value = props.variant;
    related.value = [];
  }

  watch(() => props.variant, (value) => {
    variant.value = value;
  });
</script>
<template>
  <div class="cart-button" @click.prevent.stop="picker = true">
    <slot />
  </div>
  <CartButtonPickerModal :product="product" v-model:open="picker" v-model:success="success" v-model:variant="variant" v-model:related="related" @close="onClose" />
  <CartButtonSuccessModal :product="product" v-model:open="success" v-model:variant="variant" v-model:related="related" @close="onReset" />
</template>

<style lang="scss">
  .cart-button {
    display: contents;
  }
</style>
