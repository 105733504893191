<script setup lang="ts">
  import { ref, computed, onMounted, type PropType, onBeforeUpdate, onBeforeUnmount, onUpdated } from 'vue';
  import type { Photo } from '@plenny/picture';
  import type { SwiperOptions } from 'swiper/types';
  import Swiper from 'swiper';
  import { Navigation, Thumbs } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/thumbs';

  const props = defineProps({
    photos: { type: Array as PropType<Photo[]>, required: true },
    thumbsParameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
    photosParameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
  });

  const htmlPhotosElement = ref<HTMLElement>();
  const htmlThumbnailsElement = ref<HTMLElement>();

  const photosNav = ref();
  const thumbsNav = ref();
  const lightbox = ref();

  const swiperPhotos = ref<Swiper>();
  const swiperThumbs = ref<Swiper>();

  const photos = computed(() => {
    return props.photos.filter((photo) => !!photo);
  });

  function initializeSwipers() {
    if (htmlThumbnailsElement.value) {
      swiperThumbs.value = new Swiper(htmlThumbnailsElement.value, {
        ...props.thumbsParameters,
        modules: [Navigation],
        slidesPerView: 'auto',
        freeMode: false,
        pagination: false,
        loop: false,
        spaceBetween: 10,
        watchSlidesProgress: true,
        navigation: {
          nextEl: thumbsNav.value.next.htmlElement,
          prevEl: thumbsNav.value.prev.htmlElement,
        },
      });
    } else {
      swiperThumbs.value?.destroy();
      swiperThumbs.value = undefined;
    }

    swiperPhotos.value = new Swiper(htmlPhotosElement.value!, {
      ...props.photosParameters,
      modules: [Navigation, Thumbs],
      slidesPerView: 1,
      freeMode: false,
      pagination: false,
      loop: false,
      navigation: {
        nextEl: photosNav.value.next.htmlElement,
        prevEl: photosNav.value.prev.htmlElement,
      },
      thumbs: {
        swiper: swiperThumbs.value,
      },
    });
  }

  function destroySwipers() {
    swiperPhotos.value?.destroy();
    swiperThumbs.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="product-gallery">
    <div class="items-carousel product-gallery__images">
      <div ref="htmlPhotosElement" class="swiper product-gallery__image" :class="{ 'product-gallery__image--zoomable': photos.length > 0 }">
        <template v-if="photos.length > 0">
          <div class="swiper-wrapper">
            <div v-for="(photo, index) in photos" class="swiper-slide">
              <MediaPicture :photo="photo" sizes="1600x1200 2x, 800x600" @click="lightbox.open(index)" />
              <div class="swiper-lazy-preloader"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <MediaPicture sizes="1600x1200 2x, 800x600" />
            </div>
          </div>
        </template>
      </div>
      <SfCarouselButtons ref="photosNav" />
    </div>
    <div v-if="photos.length > 0" class="items-carousel product-gallery__thumbs-wrapper">
      <div ref="htmlThumbnailsElement" class="swiper product-gallery__thumbs">
        <div class="swiper-wrapper">
          <div v-for="photo in photos" class="swiper-slide">
            <MediaPicture :photo="photo" sizes="150x150 2x, 75x75" />
            <div class="swiper-lazy-preloader" />
          </div>
        </div>
      </div>
      <SfCarouselButtons ref="thumbsNav" />
    </div>
    <SfLightbox ref="lightbox" :photos="photos" />
  </div>
</template>
<style lang="scss">
  .product-gallery {
    --sf-product-gallery-gap: 1rem;
    --sf-product-gallery-thumbs-height: 75px;
    --sf-product-gallery-thumbs-width: 75px;

    --sf-product-gallery-thumbs-border-radius: 0.25rem;
    --sf-product-gallery-thumbs-border: rgb(200, 200, 200);
    --sf-product-gallery-thumbs-border-style: solid;
    --sf-product-gallery-thumbs-border-width: 1px;

    --sf-product-gallery-thumbs-active-border: rgb(0, 0, 0);
    --sf-product-gallery-thumbs-active-border-style: solid;
    --sf-product-gallery-thumbs-active-border-width: 1px;
    --sf-product-gallery-thumbs-padding-x: 4px;
    --sf-product-gallery-thumbs-padding-y: 4px;
    --sf-carousel-button-space: 5px;

    --swiper-preloader-color: var(--sf-spinner-color);

    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__wrapper {
      position: relative;
    }

    &__image--zoomable {
      cursor: zoom-in;
    }

    &__thumbs {
      height: var(--sf-product-gallery-thumbs-height);
      position: relative;

      .swiper-slide {
        cursor: pointer;
        width: var(--sf-product-gallery-thumbs-width);
        border-color: var(--sf-product-gallery-thumbs-border);
        border-width: var(--sf-product-gallery-thumbs-border-width);
        border-style: var(--sf-product-gallery-thumbs-border-style);
        border-radius: var(--sf-product-gallery-thumbs-border-radius);
        padding: var(--sf-product-gallery-thumbs-padding-x) var(--sf-product-gallery-thumbs-padding-y);

        picture {
          display: flex;
          max-width: var(--sf-product-gallery-thumbs-width);
          aspect-ratio: 1 / 1;

          img {
            display: flex;
            max-width: var(--sf-product-gallery-thumbs-width);
            aspect-ratio: 1 / 1;
            border-radius: var(--sf-product-gallery-thumbs-border-radius);
          }
        }
      }

      .swiper-slide-thumb-active {
        border-color: var(--sf-product-gallery-thumbs-active-border);
        border-width: var(--sf-product-gallery-thumbs-active-border-width);
        border-style: var(--sf-product-gallery-thumbs-active-border-style);
      }
    }
  }
</style>
