import { createVisitor } from '@plenny/visitor';
import { register } from 'swiper/element/bundle';
import { createStorefront, createViewResolver } from './app';

register();

// noinspection JSIgnoredPromiseFromCall
createVisitor({
  resolve: createViewResolver,
  setup: (options) => {
    createStorefront(options).mount('#app');
  },
});
