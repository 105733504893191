<script lang="ts" setup>
  import { route } from '@plenny/visitor';
  import { computed } from 'vue';
  import { useBreakpoints, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';

  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: 'h2',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  });

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const breakpoints = useBreakpoints();

  const target = computed(() => {
    return route('web.product.show', { slug: props.product.slug });
  });

  const productClasses = computed(() => {
    return {
      'product--horizontal': props.horizontal,
    };
  });
</script>
<template>
  <article :class=productClasses class="product" @click="$redirect(target)">
    <div class="product__img">
      <MediaPicture :photo="product.photo" sizes="490x368 2x, 245x184" />
    </div>
    <section class="product__content">
      <article class="product__top">
        <header>
          <component :is="header" class="product__title">
            <VisitorLink :href="target">
              {{ product.title }}
            </VisitorLink>
          </component>
        </header>
        <ProductAttributes :horizontal="horizontal" :product="product" />
      </article>
      <section class="product__actions">
        <article class="product__icons">
          <slot name="icons">
            <SfWishlistButton :variant="product.variant_id" />
          </slot>
        </article>
        <article v-if="canCustomerOrder" class="product__price">
          <SfProductPrice :price="product" />
          <SfProductOmnibus :price="product" />
        </article>
        <article v-if="canCustomerOrder" class="product__bottom">
          <Availability v-if="horizontal || breakpoints.md" :variant="product" />
          <SfCartButton :product="product">
            <SfButton :square="!breakpoints.md" primary small>
              <template #before>
                <IconCart class="product__icon" />
              </template>
              <template v-if="breakpoints.md" #default>
                {{ $t('DODAJ') }}
              </template>
            </SfButton>
          </SfCartButton>
        </article>
        <article v-else class="product__bottom">
          <span v-if="isCustomerAccepted === undefined" class="product__bottom__guest" @click.stop="void 0">
            <VisitorLink :href="route('web.account.session.create')" rel="nofollow">
              {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
            </VisitorLink>
          </span>
          <span v-else class="product__bottom__guest">
            {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
          </span>
        </article>
      </section>
    </section>
    <ProductLabels :product="product" />
  </article>
</template>
<style lang="scss" scoped>
  .product {
    display: flex;
    position: relative;
    border-radius: 10px;
    transition: var(--transition-primary);
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    min-height: 250px;
    min-width: 215px;

    > .product__img {
      width: 100%;
    }

    &--horizontal
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
    }

    &__title {
      a {
        font-size: 0.875rem;
        width: 100%;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 0.3rem;

        @media (max-width: 768px) {
          font-size: 0.875rem;
          margin-bottom: 0.2rem;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      @media (max-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
        margin-top: 0.675rem;

        :deep(.omnibus) {
          text-align: left;
        }
      }
    }

    &__icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0.2rem;
      right: 0.5rem;
      transition: var(--transition-primary);
      opacity: 1;
      background: var(--color-white);
      z-index: 99;
      border-radius: 50%;
    }

    &__price {
      display: flex;
      flex-direction: column;
      align-items: center;

      :deep(.price) {
        width: 100%;
        align-items: center;
        text-align: center;

        @media (max-width: 768px) {
          align-items: flex-start;
          text-align: left;
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      &__guest {
        color: var(--secondary-color);
        font-size: 0.625rem;
      }
    }

    &:hover {
      box-shadow: var(--box-shadow-primary);
    }

    &__icon {
      font-size: 1rem;
    }

    &--horizontal {
      width: 100%;
      max-width: none;
      min-height: 150px;
      padding-top: 0.5rem;
      flex-direction: row;
      box-shadow: var(--box-shadow-primary);

      @media (max-width: 768px) {
        flex-direction: column;
      }

      > .product__img {
        width: 195px
      }

      .product__content {
        flex-direction: row;
        text-align: left;
        gap: 1.25rem;
      }

      .product__title {
        a {
          text-align: left;
        }
      }

      .product__price {
        :deep(.price) {
          align-items: flex-end;
          text-align: right;
        }

        :deep(.omnibus) {
          text-align: right;
        }
      }

      .product__actions {
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        margin-right: 1.125rem;

        @media (max-width: 768px) {
          flex-direction: column;
          margin-top: 0.675rem;
        }
      }

      .product__icons {
        top: 5px;
      }

      .product__bottom {
        gap: 1.25rem;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-end;
          gap: 1rem;
          font-size: 0.75rem;
        }
      }

      .product__availability {
        @media (max-width: 768px) {
          font-size: 0.75rem;

          &::before {
            width: 5px;
            height: 5px;
          }
        }
      }
    }
  }
</style>
