<script setup>
  import { ref } from 'vue';
  import { useBreakpoints } from '$storefront';

  defineProps({
    breadcrumbs: { type: Array, required: false, default: [] },
    sections: Array,
    recommended: Array,
    post: Object,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  const breakpoints = useBreakpoints();

</script>
<template>
  <main class="container">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
    <SectionRenderer :sections="sections" placement="before" />
    <section class="post">
      <div class="post__left">
        <PopularPosts v-if="recommended.length && breakpoints.lg" :posts="recommended" />
        <Newsletter />
      </div>
      <div class="post__right">
        <SfHeading type="h1" class="post__title" v-html="post.title" />
        <div v-if="post.photos.filter(p => p.type === 'banner').length > 0" class="post__banner">
          <MediaPicture :photo="post.photos" sizes="2200x880 2x, 1100x440" type="banner" />
        </div>
        <div class="post__content">
          <SectionRenderer :sections="sections" />
        </div>
        <SfButton :href="route('web.blog.index')" class="post__redirect">{{ $t('Wszystkie wpisy') }}</SfButton>
      </div>
    </section>
    <SectionRenderer :sections="sections" placement="after" />

    <div v-if="recommended.length" class="post__carousel">
      <SfHeading type="h2" class="post__carousel-title">{{ $t('Podobne wpisy') }}</SfHeading>
      <SfArticlesCarousel :parameters :posts="recommended" />
    </div>

  </main>
</template>
<style lang="scss" scoped>
  .post {
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 2.5rem;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &__left, &__right {
      background: var(--color-white);
      border-radius: 20px;
      padding: 2.5rem;

      @media (max-width: 992px) {
        border-radius: 0;
        padding: 1rem 0.625rem 1.5rem 0.625rem;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 3.5rem;
      align-self: start;
      @media (max-width: 992px) {
        order: 2;
      }
    }

    &__title {
      font-size: 1.25rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    &__banner {
      aspect-ratio: 5/2;
      margin-bottom: 2rem;

      :deep(picture) {
        display: block;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          aspect-ratio: 5/2;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }

    &__content {
      padding-bottom: 2rem;

      :deep(.section) {
        padding: 0;

        .text__header {
          margin-bottom: 1rem;
        }

        .text__content {
          width: 100%;
          text-align: left;
        }
      }
    }

    &__redirect {
      text-transform: uppercase;
      font-weight: 600;
    }

    &__carousel {
      margin-top: 3rem;
      background: var(--color-white);
      border-radius: 20px;
      padding: 2rem;

      @media (max-width: 992px) {
        margin-top: 2rem;
      }
    }

    &__carousel-title {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      color: var(--primary-color);
      text-transform: uppercase;
    }
  }
</style>
