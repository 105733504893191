<script lang="ts" setup>
  import { useVisitorForm } from '@plenny/visitor';
  import { computed } from 'vue';
  import { useRealizationTime } from '$storefront';

  type OrderRealization = {
    realization_from: number | null;
    realization_to: number | null;
  }

  const { data } = useVisitorForm<OrderRealization>();
  const realization = computed(() => useRealizationTime(data.value));
</script>
<template>
  <div v-if="realization" class="realization-option-summary">
    <SfIconClock class="realization-option-summary__icon" />
    <div class="realization-option-summary__content">
      <span class="realization-option-summary__property">{{ $t('Szacowany termin realizacji') + ':' }}</span>
      <span class="realization-option-summary__value">{{ realization }}</span>
    </div>
  </div>
</template>
<style lang="scss">
  .realization-option-summary {
    display: flex;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem;

    &__icon {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__value {
      font-weight: var(--font-weight-semi);
    }
  }
</style>
