<script setup lang="ts">
  import { computed, type PropType } from 'vue';

  interface Product {
    is_new: boolean,
  }

  interface Variant {
    price_gross: string,
    regular_gross: string,
  }

  const props = defineProps({
    product: { type: Object as PropType<Product>, required: true },
    variant: { type: Object as PropType<Variant>, required: true },
    icon: { type: Boolean, required: false, default: false },
  });

  const isNew = computed(() => {
    return props.product.is_new;
  });

  const isPromo = computed(() => {
    return props.variant.price_gross !== props.variant.regular_gross;
  });
</script>
<template>
  <div v-if="isNew || isPromo" class="product-tags-wrapper">
    <SfProductTag :visible="isNew" tag-type="novelty" :icon>
      {{ $t('Nowość') }}
    </SfProductTag>
    <SfProductTag :visible="isPromo" tag-type="discount" :icon>
      {{ $t('Promocja') }}
    </SfProductTag>
  </div>
</template>
<style scoped lang="scss">
  .product-tags-wrapper {
    display: flex;
    gap: 0.25rem;
  }
</style>
