<script setup lang="ts">
  import { ref } from 'vue';
  import { $get, $patch, route, useVisitorForm } from '@plenny/visitor';

  type OrderShippingForm = {
    currency_code: string;
    shipping_option_id: number;
  }

  type ShippingOption = {
    id: number;
    name: string | null;
    description: string | null;
    cost: number;
    shippingTime: string | null;
  }

  const { data } = useVisitorForm<OrderShippingForm>();

  const loading = ref();
  const options = ref([] as ShippingOption[]);

  function onChoose(option: number) {
    $patch(route('api.v1.web.cart.shipping.update'), { option }).then((res) => {
      data.value = { ...data.value, ...res };
    });
  }

  $get(route('api.v1.web.cart.shipping.show')).then((res) => {
    options.value = res;
    loading.value = false;
  });
</script>
<template>
  <SfDataLoader :loading="loading">
    <div class="shipping-method-form">
      <SfListCard
        v-for="option in options" :key="option.id"
        class="shipping-method-form__option"
        role="button"
        tabindex="0"
        interactive :selected="data.shipping_option_id === option.id"
        @click="onChoose(option.id)"
        @keydown.space="onChoose(option.id)"
      >
        <template #image>
          <SfIconBox class="shipping-method-form__icon" />
        </template>
        <template #default>
          <header class="shipping-method-form__header">
            <h3 class="shipping-method-form__heading">{{ option.name }}</h3>
            <p class="shipping-method-form__description">{{ option.description }}</p>
          </header>
        </template>
        <template #actions>
          <div class="shipping-method-form__details">
            <div class="shipping-method-form__price">
              <SfMoney :value="option.cost" :code="data.currency_code" class="shipping-method-form__money" />
            </div>
            <div class="shipping-method-form__time">
              {{ option.shippingTime }}
            </div>
          </div>
        </template>
      </SfListCard>
    </div>
  </SfDataLoader>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .shipping-method-form {
    display: flex;
    flex-direction: column;
    gap: var(--form-gap-y) var(--form-gap-x);

    &__option {
      cursor: pointer;
    }

    &__icon {
      font-size: 2rem;
    }

    &__heading {
      font-size: 1.25rem;
    }

    &__description {
      font-size: 0.875rem;
      color: var(--sf-list-card-color-alt);
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__price {
      font-weight: var(--font-weight-semi);
      font-size: 1.25rem;
    }

    &__time {
      font-size: 0.875rem;
      color: var(--sf-list-card-color-alt);
    }
  }

  @include media.query(lg) {
    .shipping-method-form {
      &__price, &__heading {
        font-size: 1rem;
      }

      &__description {
        font-size: 0.875rem;
      }

      &__icon {
        font-size: 1.5rem;
      }
    }
  }
</style>
