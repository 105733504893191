<script setup lang="ts">
  import { useVisitorControl } from '@plenny/visitor';
  import type { PropType } from 'vue';

  type Option = {
    label: string,
    value: any,
    available?: number,
  }

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: Array, required: false },
    modelValue: { type: Array, required: false },
    defaultValue: { type: Array, required: false, default: [] },
    options: { type: Array as PropType<Option[]>, required: false, default: [] },
  });

  const { model, error } = useVisitorControl(props, emit);
</script>

<template>
  <SfControlWrapper v-bind="{ id, name, required, error }">
    <label class="checkbox__container " v-for="option in options" :key="option.value">
      <input type="checkbox" class="checkbox__input" v-bind="{ name, disabled: (option.available != null && option.available <= 0), ...$attrs }" :value="option.value" v-model="model" />
      <span class="checkmark" />
      <span v-if="option.label" class="checkbox__label">
        <span>{{ option.label }}</span>
        <small class="label__available" v-if="option.available != null">{{ option.available }}</small>
      </span>
    </label>
  </SfControlWrapper>
</template>

<style lang="scss">
  .checkbox__container {
    padding-left: calc(var(--sf-checkbox-line-height) * 1.5);
    margin: calc(var(--sf-checkbox-line-height) / 2) 0;

    .checkmark {
      height: var(--sf-checkbox-line-height);
      width: var(--sf-checkbox-line-height);
      border: 1px solid var(--sf-checkbox-checkmark-border-color);
      border-radius: var(--sf-checkbox-checkmark-border-radius);

      &:after {
        height: calc(var(--sf-checkbox-line-height) - 4px);
        width: calc(var(--sf-checkbox-line-height) - 4px);
        background-color: var(--sf-checkbox-checkmark-background-color);
      }
    }

    .checkbox__label {
      display: flex;
      font-size: var(--sf-checkbox-label-font-size);
      line-height: var(--sf-checkbox-line-height);
      justify-content: space-between;
      align-items: center;
      text-transform: var(--sf-checkbox-label-text-transform);
      color: var(--sf-checkbox-label-color);
      gap: .5rem;

      .label__available {
        color: var(--sf-checkbox-label-available-color);
        font-size: var(--sf-checkbox-label-available-font-size);
      }
    }
  }
</style>
