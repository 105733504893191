<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    larger: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'div', validator: (v: string) => ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(v) },
  });

  const classes = computed(() => {
    return {
      'heading--large': props.large,
      'heading--larger': props.larger,
    };
  });
</script>
<template>
  <component :is="props.type" :class="classes" class="heading">
    <slot />
  </component>
</template>
<style lang="scss">
  :root {
    --sf-heading-font-size: 1.125rem;
    --sf-heading-line-height: 2rem;
    --sf-heading-font-weight: 700;
    --sf-heading-color: var(--color-default);

    --sf-heading-font-size-large: 1.75rem;
    --sf-heading-line-height-large: 2.5rem;

    --sf-heading-font-size-larger: 2rem;
    --sf-heading-line-height-larger: 3rem;
  }

  .heading {
    display: block;
    position: relative;
    font-weight: var(--sf-heading-font-weight);
    font-size: var(--sf-heading-font-size);
    line-height: var(--sf-heading-line-height);
    color: var(--sf-heading-color);

    &--large {
      font-size: var(--sf-heading-font-size-large);
      line-height: var(--sf-heading-line-height-large);
    }

    &--larger {
      font-size: var(--sf-heading-font-size-larger);
      line-height: var(--sf-heading-line-height-larger);
    }
  }
</style>
