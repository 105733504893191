<script setup>
  import { watch, computed, ref } from 'vue';
  import { useBreakpoints, useFixedActionsSpacer, useScrollWidth, useScrollDetector } from '$storefront';
  import { useShared } from '@plenny/visitor';

  const { spacer, offset } = useFixedActionsSpacer();
  const scroll = useScrollWidth();
  const breakpoints = useBreakpoints();
  const shared = useShared();

  const isScrollingDown = ref(true);
  const isScrolled = ref(false);
  const scrollWidth = computed(() => (scroll.value + 20) + 'px');

  const backToTop = () => {
    document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  function actionChat() {
    window.Tawk_API?.toggle();
  }

  useScrollDetector({
    threshold: 2,
    callback({ down, scrolled }) {
      isScrollingDown.value = down;
      isScrolled.value = scrolled;
    },
  });

  watch(isScrollingDown, (value) => {
    if (value) {
      spacer.value -= 58;
    } else {
      spacer.value += 58;
    }
  });
</script>
<template>
  <div :class="{ 'is-scrolling-down': isScrollingDown }" class="fixed-actions-wrapper">
    <SfButton class="chat-wrapper" rounded square transparent @click="actionChat">
      <IconChat />
    </SfButton>

    <SfButton v-if="isScrolled" class="back-to-top-wrapper" rounded square transparent @click="backToTop">
      <IconChevronUp />
    </SfButton>
  </div>
  <div v-if="!breakpoints.lg" :class="{ 'is-scrolling-down': isScrollingDown }" class="bottom-nav-wrapper">
    <BottomNavigation />
  </div>
</template>
<style lang="scss" scoped>
  .fixed-actions-wrapper {
    --scrollOffset: v-bind(scrollWidth);
    --bottomOffset: v-bind(offset);

    position: fixed;
    right: var(--scrollOffset);
    bottom: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 99;
  }

  .chat-wrapper,
  .back-to-top-wrapper,
  .comparator {
    --space: v-bind(scrollWidth);
    display: flex;
    position: sticky;
    right: var(--space);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--color-light-background);
    height: 50px;
    width: 50px;
    --sf-btn-font-size: 1.5rem;
    box-shadow: var(--box-shadow-fixed-actions);

    &:hover {
      box-shadow: var(--box-shadow-fixed-actions-hover);
    }
  }

  .comparator .comparator-link {
    position: relative;
    height: 100%;
    width: 100%;

    .items-count {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.25rem;
      width: 1.25rem;
      color: var(--color-primary-black);
      font-weight: 500;
      font-size: 0.875rem;
      z-index: 1;
      border-radius: 0.625rem;
      transform: translate(-20%, -20%);
      background-color: var(--primary-color);
    }
  }

  .chat-wrapper {
    transition: var(--transition-primary);
  }

  .bottom-nav-wrapper {
    width: 100%;
    position: fixed;
    z-index: 9998;
    bottom: 0;
    left: 0;
    height: 58px;
    transform: translateY(0);
    transition: transform 0.3s;
  }

  .bottom-nav-wrapper.is-scrolling-down {
    transform: translateY(58px);
  }

  @media (max-width: 992px) {
    .fixed-actions-wrapper {
      transform: translateY(calc(-1 * var(--bottomOffset)));
      transition: transform 0.3s;
    }
  }
</style>
