<script setup lang="ts">
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';
  import { route, useVisitorForm } from '@plenny/visitor';

  type OrderConditionsForm = {
    billing_method_driver: 'PayU' | 'Przelewy24' | 'BankTransfer' | 'CashOnDelivery' | 'FreePayment';
  }

  const { data } = useVisitorForm<OrderConditionsForm>();

  const termsAndConditions = computed(() => {
    return trans('Akceptuję <a href=":url" class="conditions-form__item-link" target="_blank">regulamin</a> sklepu.', { url: route('web.terms_and_condition.index') });
  });

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" class="conditions-form__item-link" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });

  const payuTerms = computed(() => {
    return trans('Akceptuję <a href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_en.pdf" class="conditions-form__item-link" target="_blank">regulamin płatności</a> PayU.');
  });

  const przelewy24Terms = computed(() => {
    return trans('Oświadczam, że zapoznałem się z <a href="https://www.przelewy24.pl/regulamin" class="conditions-form__item-link" target="_blank">regulaminem</a> i <a href="https://www.przelewy24.pl/obowiazek-informacyjny-platnik" class="conditions-form__item-link" target="_blank">obowiązkiem informacyjnym</a> serwisu Przelewy24.');
  });

  const ceneoTerms = computed(() => {
    return trans('Wyrażam zgodę na przekazanie moich danych osobowych do Ceneo sp. z o.o. w ramach programu <a href="https://shops.ceneo.pl/documents/Regulamin%20Programu%20Zaufane%20Opinie.pdf" class="conditions-form__item-link" target="_blank">&quot;Zaufane Opinie&quot;</a>.');
  });

</script>
<template>
  <div class="conditions-form">
    <div class="conditions-form__item">
      <SfFormSwitch name="acceptance_terms" required>
        <span v-html="termsAndConditions" />
      </SfFormSwitch>
    </div>
    <div class="conditions-form__item">
      <SfFormSwitch name="acceptance_rodo" required>
        <span v-html="privacyPolicy" />
      </SfFormSwitch>
    </div>
    <div v-if="data.billing_method_driver === 'PayU'" class="conditions-form__item">
      <SfFormSwitch name="acceptance_payu" required>
        <span v-html="payuTerms" />
      </SfFormSwitch>
    </div>
    <div v-if="data.billing_method_driver === 'Przelewy24'" class="conditions-form__item">
      <SfFormSwitch name="acceptance_przelewy24" required>
        <span v-html="przelewy24Terms" />
      </SfFormSwitch>
    </div>
<!--    <div class="conditions-form__item">-->
<!--      <SfFormSwitch name="acceptance_ceneo">-->
<!--        <span v-html="ceneoTerms" />-->
<!--      </SfFormSwitch>-->
<!--    </div>-->
    <div class="conditions-form__item">
      <SfFormSwitch name="acceptance_marketing">
        {{ $t('Wyrażam zgodę na przetwarzanie moich danych osobowych, podanych w niniejszym formularzu, w celu przesyłania newslettera na podany przeze mnie adres poczty elektronicznej.') }}
      </SfFormSwitch>
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-sign-up-form-conditions-link-color-hover: var(--primary-color-hover);
  }

  .conditions-form {
    display: flex;
    flex-direction: column;
    gap: var(--form-gap-y) var(--form-gap-x);

    &__item-link {
      text-decoration: underline;

      &:hover {
        color: var(--sf-sign-up-form-conditions-link-color-hover);
      }
    }
  }
</style>
