<script setup lang="ts">
  import { useVisitorForm } from '@plenny/visitor';

  type OrderNoteForm = {
    has_customer_note: boolean;
  }

  const { data } = useVisitorForm<OrderNoteForm>();
</script>
<template>
  <div class="customer-note-form">
    <div class="shipping-data-wrapper__switch-note">
      <SfFormSwitch name="has_customer_note">
        {{ $t('Chcę dodać dodatkowe informacje do zamówienia') }}
      </SfFormSwitch>
    </div>
    <div v-if="data.has_customer_note" class="shipping-data-wrapper__textarea-note">
      <SfFormTextarea :label="$t('Dodatkowe informacje')" name="customer_note" />
    </div>
  </div>
</template>
<style lang="scss">
  .customer-note-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 672px;
  }
</style>
