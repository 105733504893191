<script setup lang="ts">
  import { ref, type PropType, nextTick } from 'vue';
  import { route, $refresh } from '@plenny/visitor';
  import { useBreakpoints } from '$storefront';
  import type { Pagination } from '$types';

  interface Product {
    id: number,
    variant_id: number,
    photo: string | null,
    title: string,
    sku: string | null,
  }

  type AccountReview = Pagination & {
    data: Product[];
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    available: { type: Object as PropType<AccountReview>, required: true },
  });

  const breakpoints = useBreakpoints();

  const product = ref<Product>();
  const open = ref(false);

  function sendReview(item: Product) {
    product.value = item;
    nextTick(() => open.value = true);
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Wystaw opinię') }}
  </AccountMobileNavigation>
  <SfTile large class="account-review">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">
        {{ $t('Wystaw opinię') }}
      </h1>
    </template>
    <template #default>
      <div v-if="available.data.length > 0" class="account-review__products">
        <SfListCard v-for="item in available.data">
          <template #image>
            <MediaPicture :photo="item.photo" sizes="140x140 2x, 70x70" class="account-review__product-image" />
          </template>
          <template #default>
            <div class="account-review__product-content">
              <h2 class="account-review__product-title">{{ item.title }}</h2>
              <p class="account-review__product-sku">SKU: {{ item.sku }}</p>
            </div>
          </template>
          <template #actions>
            <SfButton @click="sendReview(item)">
              {{ $t('Wystaw opinię') }}
            </SfButton>
          </template>
        </SfListCard>
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Obecnie, brak produktów dla których możesz wystawić opinię.') }}
      </SfDataPlaceholder>
    </template>
  </SfTile>
  <SfPagination :lastPage="available.last_page" :links="available.links" :currentPage="available.current_page" />
  <ReviewFormModal v-if="product" v-model:open="open" :product="product" @reviewed="$refresh()" />
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-review {
    &__products,
    &__product-content,
    &__form-product,
    &__form-content {
      display: flex;
      flex-direction: column;
    }

    &__products {
      row-gap: 1rem;

      .list-card__image {
        flex: 0 0 80px;
      }
    }

    &__product-content,
    &__form-product {
      gap: 0.5rem;
    }

    &__product-title {
      font-size: 1.125rem;
    }

    &__product-sku {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }

    &__form-main {
      flex-grow: 1;
    }

    &__form-content {
      row-gap: 2rem;

      .list-card__image {
        flex: 0 0 80px;
      }
    }

    &__product-title--form {
      font-size: 1.125rem;
    }

    &__product-sku {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }
  }


  @include media.query(lg) {
    .account-review {
      &__products .card {
        padding-bottom: 1rem;
      }

      &__content {
        flex-direction: column;
        gap: 0.75rem;
      }

      &__form .card {
        border: none;
      }

      &__product-title {
        font-size: 1rem;
      }
    }
  }
</style>
