<template>
  <div class="sign-up-info-advantages">
    <span class="sign-up-info-advantages__title">{{ $t('Zyskasz') + ':' }}</span>
    <div class="sign-up-info-advantages__info">
      <SfIconDocument />
      <span class="sign-up-info-advantages__text">{{ $t('Pełna historia wszystkich zamówień') }}</span>
    </div>
    <div class="sign-up-info-advantages__info">
      <SfIconClock />
      <span class="sign-up-info-advantages__text">{{ $t('Szybki proces zamawiania') }}</span>
    </div>
    <div class="sign-up-info-advantages__info">
      <SfIconTruck />
      <span class="sign-up-info-advantages__text">{{ $t('Wygodne śledzenie statusu zamówień') }}</span>
    </div>
  </div>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sign-up-info-advantages-color: rgb(100, 100, 100);
  }

  .sign-up-info-advantages {
    color: var(--sign-up-info-advantages-color);
    font-size: 0.875rem;

    &__title {
      font-weight: var(--font-weight-semi);
    }

    &__info {
      display: flex;
      align-items: center;
      column-gap: 0.75rem;
      margin-top: 0.75rem;

      svg {
        font-size: 1.25rem;
      }
    }
  }

  @include media.query(lg) {
    .sign-up-info-advantages {
      padding: 0 0.5rem;
    }
  }
</style>
