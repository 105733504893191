<script setup lang="ts">
  import { $post, route } from '@plenny/visitor';
  import { useCart } from '$storefront';

  const emit = defineEmits({
    toggle: (section: string) => void 0,
  });

  defineProps({
    open: { type: Boolean, required: false, default: false },
  });

  const { cart } = useCart();

  function onSubmit(data: string | null) {
    return $post(route('api.v1.web.cart.coupon.store'), data);
  }

  function activate() {
    emit('toggle', 'discount');
  }
</script>
<template>
  <article class="order-discount-code">
    <template v-if="cart">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit" class="order-discount-code__form">
        <div class="order-discount-code__header" role="button" tabindex="0" aria-controls="order-discount-code__apply-discount" :aria-pressed="open" @click="activate" @keydown.space="activate">
          <h3 class="order-discount-code__heading">{{ $t('Masz kod promocyjny?') }}</h3>
          <SfIconPlus :class="{ 'order-discount-code__icon--open': open }" aria-hidden="true" class="order-discount-code__icon" />
        </div>
        <section id="order-discount-code__apply-discount" class="order-discount-code__content" :class="{ 'order-discount-code__content--open': open }" :inert="!open">
          <div class="order-discount-code__content-outer">
            <div class="order-discount-code__content-inner">
              <SfFormInput :label="$t('Kod')" name="code" type="text" class="order-discount-code__input" />
              <SfButton :loading="submitting" primary type="submit" class="order-discount-code__button-add">
                {{ $t('Dodaj') }}
              </SfButton>
            </div>
          </div>
        </section>
      </VisitorForm>
    </template>
    <template v-if="cart && cart.coupons.length > 0">
      <CartCoupon v-for="coupon in cart.coupons" :coupon="coupon" />
    </template>
  </article>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-discount-transition-timing-func: ease-in-out;
    --sf-order-discount-transition-duration: 200ms;
  }

  .order-discount-code {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      cursor: pointer;
    }

    &__heading {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: var(--font-weight-regular);
    }

    &__icon {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      font-size: 1.25rem;
      line-height: 1;
      transition: transform;
      transition-duration: var(--sf-order-discount-transition-duration);
      transition-timing-function: var(--sf-order-discount-transition-timing-func);

      &--open {
        transform: rotate(45deg);
      }
    }

    &__content {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows;
      transition-duration: var(--sf-order-discount-transition-duration);
      transition-timing-function: var(--sf-order-discount-transition-timing-func);

      &--open {
        grid-template-rows: 1fr;
      }
    }

    &__content-outer {
      overflow: hidden;
    }

    &__content-inner {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem 0.375rem;

      .control__error {
        padding: 0.25rem 0;
      }
    }
  }

  @include media.query(lg) {
    .order-discount-code {
      margin-top: 0.625rem;

      &__content {
        margin-top: 0.375rem;
      }
    }
  }
</style>
