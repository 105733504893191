<script lang="ts" setup>
  import { onMounted, ref } from 'vue';

  defineProps({
    perView: { type: Number, required: true, default: 8 },
    categories: Array,
  });

  const swiperElement = ref();
  const navButtons = ref();

  onMounted(() => {
    Object.assign(swiperElement.value, {
      breakpoints: {
        1: {
          slidesPerView: 1.5,
        },
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
        1400: {
          slidesPerView: 6,
        },
      },
      navigation: {
        nextEl: navButtons.value.next.htmlElement,
        prevEl: navButtons.value.prev.htmlElement,
      },
    });
    swiperElement.value.initialize();
  });
</script>

<template>
  <div class="items-carousel categories-carousel categories-carousel--frame">
    <swiper-container ref="swiperElement" class="items-carousel__container categories-carousel__container" init="false">
      <swiper-slide v-for="category in categories" class="items-carousel__slide categories-carousel__article">
        <VisitorLink :href="route('web.product.category', { category })" class="categories-carousel__link">
          <MediaPicture :photo="category.photo" sizes="400x300 2x, 200x150" />
          <span>
            {{ category.title }}
          </span>
        </VisitorLink>
      </swiper-slide>
    </swiper-container>
    <SfCarouselButtons ref="navButtons" />
  </div>
</template>

<style lang="scss" scoped>

  .categories-carousel {
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      box-shadow: var(--box-shadow-primary);
      border-radius: 5px;
      padding: 1.5rem 1rem;
      flex-grow: 1;
      text-align: center;
      line-height: 1.5;

      :deep(img) {
        height: 120px;
      }

      span {
        transition: var(--transition-primary);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        word-break: break-word;
      }

      &:hover span {
        color: var(--primary-color);
      }
    }
  }

  @media(max-width: 768px) {
    .categories-carousel__link {
      padding: 1rem 0.5rem;

      :deep(img) {
        height: 100px;
      }
    }
  }
</style>

