<script setup lang="ts">
  import type { PropType } from 'vue';
  import { trans } from '@plenny/translator';
  import type { Country } from '$types';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  defineProps({
    countries: { type: Array as PropType<Country[]>, required: true },
  });

  const labels = {
    type: trans('Kupujący'),
    email: trans('E-mail na który wyślemy fakturę'),
    phone: trans('Telefon w sprawie faktur'),
  };
</script>
<template>
  <VisitorForm v-slot="{ data, submitting }" class="billing-address-form">
    <header>
      <slot name="header">
        <h2 v-if="data.id" class="billing-address-form__heading">
          {{ $t('Edytuj dane do faktury') }}
        </h2>
        <h2 v-else class="billing-address-form__heading">
          {{ $t('Dodaj dane do faktury') }}
        </h2>
      </slot>
    </header>
    <SfAddressForm billable typed named contactable :contact-required="false" :labels="labels" :countries="countries" />
    <SfFormSwitch :label="$t('Ustaw jako domyślny')" name="default" />
    <SfModalFooter>
      <SfButton @click="open = false">
        {{ $t('Anuluj') }}
      </SfButton>
      <SfButton type="submit" primary :loading="submitting">
        {{ $t('Zapisz') }}
      </SfButton>
    </SfModalFooter>
  </VisitorForm>
</template>
