<script setup lang="ts">
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    model: { type: Object, required: true },
    prefix: { type: String, required: false },
  });

  function field(model: Object, prefix: string | undefined, name: string) {
    if (prefix) {
      return model[prefix + '_' + name];
    } else {
      return model[name];
    }
  }

  const company = computed(() => {
    return field(props.model, props.prefix, 'company');
  });

  const name = computed(() => {
    const firstname = field(props.model, props.prefix, 'firstname');
    const lastname = field(props.model, props.prefix, 'lastname');

    return [firstname, lastname].join(' ').trim();
  });

  const first = computed(() => {
    return field(props.model, props.prefix, 'first_line');
  });

  const second = computed(() => {
    return field(props.model, props.prefix, 'second_line');
  });

  const third = computed(() => {
    return field(props.model, props.prefix, 'third_line');
  });

  const billing = computed(() => {
    const nip = field(props.model, props.prefix, 'vat_id');

    if (nip) {
      return trans('NIP: :nip', { nip });
    }

    return null;
  });

  const email = computed(() => {
    return field(props.model, props.prefix, 'email');
  });

  const phone = computed(() => {
    return field(props.model, props.prefix, 'phone');
  });

  const lines = computed(() => {
    const elements: string[] = [];

    if (company.value) elements.push(company.value);
    if (name.value) elements.push(name.value);
    if (first.value) elements.push(first.value);
    if (second.value) elements.push(second.value);
    if (third.value) elements.push(third.value);
    if (billing.value) elements.push(billing.value);
    if (email.value) elements.push(email.value);
    if (phone.value) elements.push(phone.value);

    return elements.join('<br/>');
  });
</script>
<template>
  <address v-if="lines" v-html="lines" />
  <SfDataPlaceholder v-else />
</template>
