<script setup lang="ts">
  import { useVisitorControl } from '@plenny/visitor';
  import type { PropType } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: [String, Number, Boolean], required: false },
    modelValue: { type: [String, Number, Boolean], required: false },
    defaultValue: { type: [String, Number, Boolean], required: false, default: null },
    options: { type: Array as PropType<{ value: any, label: string }[]>, required: true },
  });

  const { model, error } = useVisitorControl(props, emit);
</script>

<template>
  <SfControlWrapper class="radio__wrapper" v-bind="{ id, name, label, required, error }">
    <label class="radio__container" v-for="option in options">
      <input type="radio" class="radio__input" v-bind="{ name, ...$attrs }" :value="option.value" v-model="model" />
      <span class="checkmark" />
      <span class="radio__label" v-html="option.label" />
    </label>
  </SfControlWrapper>
</template>

<style lang="scss">
  .radio__wrapper {

    .control__label {
      position: static;
      padding: 0 0 0.25rem 0;
    }

    .radio__container {
      padding-left: calc(var(--sf-radio-line-height) * 1.5);
      line-height: var(--sf-radio-line-height);
      margin: calc(var(--sf-radio-line-height) / 2) 0;


      .checkmark {
        height: var(--sf-radio-line-height);
        width: var(--sf-radio-line-height);
        border-radius: 50%;
        border: 1px solid var(--sf-radio-checkmark-border-color);

        &:after {
          height: calc(var(--sf-radio-line-height) - 6px);
          width: calc(var(--sf-radio-line-height) - 6px);
          border-radius: 50%;
          background-color: var(--sf-radio-checkmark-background-color);
        }
      }

      .radio__label {
        font-size: var(--sf-radio-label-font-size);
        text-transform: var(--sf-radio-label-text-transform);
      }
    }
  }
</style>
