<script setup lang="ts">
  import { trans } from '@plenny/translator';
  import { $delete, route } from '@plenny/visitor';
  import type { PropType } from 'vue';

  type Coupon = {
    id: number;
    name: string;
    code: string;
    type: 'percentage' | 'fixed';
    value: string;
    free_shipping: boolean;
  }

  defineProps({
    coupon: { type: Object as PropType<Coupon>, required: true },
  });

  function onDestroy(id: number) {
    if (confirm(trans('Czy na pewno chcesz usunąć ten kupon ze swojego koszyka?'))) {
      $delete(route('api.v1.web.cart.coupon.destroy', { 'coupon': id }));
    }
  }
</script>
<template>
  <article class="order-coupon">
    <header class="order-coupon__header">
      <div class="order-coupon__data">
        <h3 class="order-coupon__heading">{{ $t('Zastosowany kupon') + ':' }}</h3>
        <p class="order-coupon__name">{{ coupon.name }}</p>
      </div>
      <div class="order-coupon__data">
        <h3 class="order-coupon__heading">{{ $t('Kod kuponu') + ':' }}</h3>
        <p class="order-coupon__code">{{ coupon.code }}</p>
      </div>
    </header>
    <section class="order-coupon__wrapper">
      <article class="order-coupon__content">
        <SfDiscount v-if="Number(coupon.value)" type="FIXED" :value="coupon.value" class="order-coupon__discount" />
        <span v-if="coupon.free_shipping" class="order-coupon__free-shipping">{{ $t('Darmowa dostawa') }}</span>
      </article>
      <SfButton small transparent danger @click="onDestroy(coupon.id)" class="order-coupon__delete">
        <template #before>
          <SfIconTrash role="button" />
        </template>
        <template #default>
          {{ $t('Usuń kupon') }}
        </template>
      </SfButton>
    </section>
  </article>
</template>
<style lang="scss">
  :root {
    --sf-order-coupon-border-radius: 0;
    --sf-order-coupon-border-width: 1px;
    --sf-order-coupon-border-style: solid;
    --sf-order-coupon-border-color: var(--secondary-color);
    --sf-order-coupon-shipping-color: var(--secondary-color);
  }

  .order-coupon {
    font-size: 0.875rem;
    margin-top: 1rem;
    border-radius: var(--sf-order-coupon-border-radius);
    border-color: var(--sf-order-coupon-border-color);
    border-style: var(--sf-order-coupon-border-style);
    border-width: var(--sf-order-coupon-border-width);
    padding: 0.75rem;

    &__header {
      font-weight: var(--font-weight-semi);
      margin-bottom: 0.5rem;
    }

    &__data {
      margin-bottom: 0.25rem;
    }

    &__heading {
      font-size: 0.875rem;
      font-weight: var(--font-weight-regular);
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.1rem;
      margin-top: 0.8rem;
    }

    &__free-shipping {
      font-size: 0.75rem;
      margin-top: 0.25rem;
      color: var(--secondary-color);
    }
  }
</style>
