<template>
  <div class="placeholder">
    <slot>{{ $t('Brak danych do wyświetlenia.')}}</slot>
  </div>
</template>

<style lang="scss">
  :root{
    --sf-data-placeholder-color: rgb(80, 80, 80);
    --sf-data-placeholder-font-size: 1rem;
    --sf-data-placeholder-font-weight: var(--font-weight-regular);

    --sf-data-placeholder-padding: 1.5rem;
    --sf-data-placeholder-margin: 0;

    --sf-data-placeholder-border-color: rgb(222, 222, 222);
    --sf-data-placeholder-border-width: 0;
    --sf-data-placeholder-border-style: solid;
    --sf-data-placeholder-border-radius: 0;
  }

  .placeholder {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-size: var(--sf-data-placeholder-font-size);
    color: var(--sf-data-placeholder-color);
    font-weight: var(--sf-data-placeholder-font-weight);
    padding: var(--sf-data-placeholder-padding);
    margin: var(--sf-data-placeholder-margin);
    border-color: var(--sf-data-placeholder-border-color);
    border-width: var(--sf-data-placeholder-border-width);
    border-style: var(--sf-data-placeholder-border-style);
    border-radius: var(--sf-data-placeholder-border-radius);
  }
</style>
