<script setup lang="ts">
  import { type PropType, computed } from 'vue';
  import { Unit } from '$types';
  import { UnitFormatter } from '$support';

  type Format = 'short' | 'long' | 'genitive' | 'accusative';

  const props = defineProps({
    unit: { type: String as PropType<Unit>, required: true },
    quantity: { type: [Number, String], required: true },
    format: { type: String as PropType<Format>, required: false, default: 'short' },
  });

  const formatted = computed(() => {
    let unit = props.unit;
    let quantity = props.quantity;

    if (unit && quantity) {
      switch (props.format) {
        case 'short':
          return UnitFormatter.short(unit, quantity);
        case 'long':
          return UnitFormatter.long(unit, quantity);
        case 'genitive':
          return UnitFormatter.genitive(unit, quantity);
        case 'accusative':
          return UnitFormatter.accusative(unit, quantity);
      }
    }

    return undefined;
  });
</script>
<template>
  <span class="unit" v-html="formatted" />
</template>
