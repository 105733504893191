<script lang="ts" setup>
  import { computed } from 'vue';
  import { trans, transChoice } from '@plenny/translator';
  import { SfIconStar, SfIconStarHalf, SfIconStarEmpty } from '$components';

  const props = defineProps({
    rating: {
      type: [Number, String],
      required: false,
      default: 0,
      validator: (value: number | string) => {
        let normalized = Number(value);

        if (!isNaN(normalized) && normalized >= 0 && normalized <= 5) {
          return true;
        }

        throw new Error(`${value} is not valid SfStars range. Range should be between 0 and 5.`);
      },
    },
    count: {
      type: Number,
      required: false,
    },
  });

  const label = computed(() => {
    const count = props.count;
    const rating = Number(props.rating);

    const stars = transChoice(':rating gwiazdkę|:rating gwiazdki|:rating gwiazdek', rating, { rating });

    if (!count) {
      if (rating > 0) {
        return trans('Ocena na :stars.', { stars });
      } else {
        return trans('Ten produkt nie został jeszcze oceniony.');
      }
    }

    return transChoice(':count osoba oceniła ten produkt na :stars.|:count osoby oceniły ten produkt na :stars.|:count osób oceniło ten produkt na :stars.', count, {
      count,
      stars,
    });
  });

  const stars = computed(() => {
    return Array.from(Array(5).keys()).map((rate) => {
      if (!props.rating) {
        return { component: SfIconStarEmpty, class: [] };
      }

      const rating = Number(props.rating);

      if (isNaN(rating)) {
        return { component: SfIconStarEmpty, class: [] };
      }

      if (rating >= rate + 1) {
        return { component: SfIconStar, class: ['stars__star--active'] };
      }

      if (rating > rate && rating < rate + 1) {
        return { component: SfIconStarHalf, class: ['stars__star--active'] };
      }

      return { component: SfIconStarEmpty, class: [] };
    });
  });
</script>
<template>
  <article :aria-label="label" class="stars">
    <component :is="star.component" v-for="star in stars" :class="star.class" aria-hidden="true" class="stars__star" />
    <span v-if="count != undefined" aria-hidden="true" class="stars__count">
      ({{ count }})
    </span>
  </article>
</template>
<style lang="scss">
  @use "$assets/mixins/media";


  :root {
    --sf-stars-color-text: var(--color-default);
    --sf-stars-color-star: var(--secondary-background);
    --sf-stars-color-star-filled: var(--primary-background);
    --sf-stars-star-size: var(--font-size);
    --sf-stars-font-family: var(--font-family-sans);
    --sf-stars-font-size: var(--font-size);
    --sf-stars-font-weight: var(--font-weight-regular);
    --sf-stars-padding-y: 0.2rem;
    --sf-stars-padding-x: 0.2rem;
    --sf-stars-margin-y: 0.2rem;
    --sf-stars-margin-x: 0.2rem;
    --sf-stars-gap: 0.2rem;
    --sf-stars-count-space: 0.2rem;

    @include media.query(md) {
      --sf-stars-star-size: 12px;
    }
  }

  .stars {
    display: flex;
    align-items: center;
    padding: var(--sf-stars-padding-y) var(--sf-stars-padding-x);
    margin: var(--sf-stars-margin-y) var(--sf-stars-margin-x);
    gap: var(--sf-stars-gap);

    &__count {
      display: flex;
      align-items: center;
      color: var(--sf-stars-color-text);
      font-family: var(--sf-stars-font-family);
      font-weight: var(--sf-stars-font-weight);
      font-size: var(--sf-stars-font-size);
      margin-left: var(--sf-stars-count-space);
    }

    &__star {
      color: var(--sf-stars-color-star);
      font-size: var(--sf-stars-star-size);

      &--active {
        color: var(--sf-stars-color-star-filled);
      }
    }
  }
</style>
