<script setup lang="ts">
  import { route, $post, $delete } from '@plenny/visitor';
  import { ref } from 'vue';

  const loading = ref(false);
  const resent = ref(false);

  function actionResend() {
    loading.value = true;

    $post(route('web.account.verify.resend')).then(() => {
      resent.value = true;
      loading.value = false;
    });
  }

  function actionLogout() {
    $delete(route('web.account.session.destroy'));
  }
</script>
<template>
  <div class="verify">
    <SfTile large>
      <template #title>
        <h1 class="account-heading verify__heading">{{ $t('Potwierdź swój adres e-mail') }}</h1>
      </template>
      <template #default>
        <div class="verify__message">
          <p class="verify__text">{{ $t(`Na twój adres e-mail wysłaliśmy link aktywujący. Wejdź na swoją pocztę i kliknij link aktywujący aby potwierdzić swój adres e-mail.`) }}</p>
          <p class="verify__text">{{ $t('Wiadomość e-mail nie dotarła lub link wygasł?') }}</p>
        </div>
        <div class="verify__resend">
          <SfButton @click="actionLogout">
            <template #before>
              <SfIconPower />
            </template>
            <template #default>
              {{ $t('Wyloguj się') }}
            </template>
          </SfButton>
          <SfButton @click="actionResend" :disabled="resent" primary :loading="loading">
            {{ resent ? $t('Wysłaliśmy nowy link aktywacyjny') : $t('Wyślij link ponownie') }}
          </SfButton>
        </div>
      </template>
    </SfTile>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .verify {
    width: 98%;
    max-width: 1200px;
    margin: 100px auto;

    &__message {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      line-height: 1.5;
    }

    &__resend {
      display: flex;
      gap: 2rem;
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }

  @include media.query(lg) {
    .verify {
      margin: 50px auto;
      text-align: center;

      .tile__content {
        padding: 1.5rem 0.625rem;

        .tile__header {
          justify-content: center;
        }
      }

      &__header {
        margin-bottom: 0.625rem;
      }


      &__heading {
        font-size: 1.375rem;
        text-align: center;
      }

      &__resend {
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
        margin-top: 1rem;
      }
    }
  }
</style>
