<script setup>
  import { useBreakpoints } from '$storefront';
  import { computed } from 'vue';

  const breakpoints = useBreakpoints();

  const props = defineProps({
    title: { type: String, required: false },
    link: { type: String, required: false },
    transparent: { type: Boolean, default: false },
    square: { type: Boolean, default: false },
  });

  const sectionClasses = computed(() => {
    return {
      'section--transparent': props.transparent,
      'section--square': props.square,
    };
  });

</script>
<template>
  <section :class="sectionClasses" class="section">
    <header v-if="title" class="section__header">
      <SfHeading type="h2" class="section__title">{{ title }}</SfHeading>
      <SfButton v-if="link" :href="link" :small="!breakpoints.md" transparent>
        <template #default>
          {{ $t('Zobacz więcej') }}
        </template>
        <template #after>
          <IconArrow class="section__arrow" />
        </template>
      </SfButton>
    </header>
    <div class="section__content">
      <slot />
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .section {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 2rem;
    width: 100%;
    border-radius: 20px;

    @media (max-width: 992px) {
      border-radius: 0;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0.75rem;
      margin-bottom: 1rem;
      width: 100%;

      .section__title {
        font-size: 1.125rem;
        text-transform: uppercase;
        color: var(--primary-color);
        font-weight: bold;
      }

      @media (max-width: 992px) {
        padding-left: 0;
      }
      
    }

    &__arrow {
      transform: rotate(-90deg);
    }

    &--transparent {
      background: transparent;
    }

    &--square {
      border-radius: 0;
    }

    @media (max-width: 768px) {
      padding: 1.25rem;
    }
  }
</style>
