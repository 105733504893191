import { onMounted, computed, ref, onBeforeUnmount } from 'vue';

export function useBreakpoints() {
  const width = ref(typeof window !== 'undefined' ? window.innerWidth : 1920);

  const breakpoints = computed(() => {
    return {
      xsm: width.value < 576,
      sm: width.value > 576,
      md: width.value > 768,
      lg: width.value > 992,
      xl: width.value > 1200,
      xxl: width.value > 1400,
    };
  });

  let resizeObserver: ResizeObserver;

  function onResize(entries: ResizeObserverEntry[]) {
    for (const entry of entries) {
      if (entry.contentRect) {
        width.value = entry.contentRect.width;
      }
    }
  }

  onMounted(() => {
    // Initial value
    width.value = window.innerWidth;

    resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(document.body);
  });

  onBeforeUnmount(() => {
    if (resizeObserver) resizeObserver.disconnect();
  });

  return breakpoints;
}
