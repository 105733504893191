<script setup>
  import { ref, computed } from 'vue';
  import { useCart } from '$storefront';

  const show = ref(false);
  const { cart } = useCart();

  const total = computed(() => cart.value?.total_gross || 0);
  const currency = computed(() => cart.value?.currency_code);
  const decimals = computed(() => cart.value?.currency_decimals);
</script>
<template>
  <div class="action-item" tabindex="0" @click="show = true" @keydown.enter="show = true">
    <SfMoney :code="currency" :decimals="decimals" :value="total" class="cart-value" />
    <IconCart class="icon" />
  </div>
  <SfDrawerCart v-model:open="show" />
</template>
<style lang="scss" scoped>
  .action-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: 10px;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    transition: var(--transition-primary);
    border: 1px solid var(--color-primary-black);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: bold;

    &:hover {
      color: var(--primary-color);
    }

    .icon {
      color: var(--primary-color);
      font-size: 1.25rem;
    }
  }

  @media(max-width: 992px) {
    .action-item {
      padding: 0.25rem 0.5rem;
      height: 35px;

      .cart-value {
        font-size: 0.875rem;
      }
    }
  }

  @media(max-width: 768px) {
    .action-item {
      .cart-value {
        font-size: 0.875rem;
      }

      .icon {
        font-size: 1rem;
      }
    }
  }

  @media(max-width: 576px) {
    .action-item {
      gap: 6px;
      height: 30px;
    }
  }
</style>
