<script setup lang="ts">
  import Section from '../../../Components/products/Section.vue';
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    products: Array,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  });
</script>

<template>
  <Section :title="header" :link="route('web.product.index')">
    <SfProductsCarousel :products="products" :parameters />
  </Section>
</template>


<style scoped lang="scss">
  @media(max-width: 576px) {
    .section-wrapper {
      padding-right: 0;
    }
  }
</style>
