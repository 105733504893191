<script setup lang="ts">
  import Section from '../../../Components/products/Section.vue';
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    posts: Array,
  });


  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.2,
      },
      576: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <Section :title="header" :link="route('web.blog.post.index')" class="container">
    <SfArticlesCarousel :posts="posts" :parameters />
  </Section>
</template>
