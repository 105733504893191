<script setup lang="ts">
  import { trans } from '@plenny/translator';
  import { computed, type PropType } from 'vue';
  import type { Statuses } from '$types';

  const props = defineProps({
    status: { type: String as PropType<Statuses>, required: true },
  });

  const statusMap = {
    CANCELLED: { label: trans('Anulowane'), class: 'order-status-label--cancelled' },
    COMPLAINED: { label: trans('Reklamacja'), class: 'order-status-label--complained' },
    COMPLETED: { label: trans('Zrealizowane'), class: 'order-status-label--completed' },
    DISPATCHED: { label: trans('Wysłane'), class: 'order-status-label--dispatched' },
    DRAFT: { label: trans('Koszyk'), class: 'order-status-label--draft' },
    NEW: { label: trans('Nowe'), class: 'order-status-label--new' },
    OFFER: { label: trans('Zapytanie ofertowe'), class: 'order-status-label--offer' },
    PAYMENT_FAILED: { label: trans('Niepowodzenie płatności'), class: 'order-status-label--payment-failed' },
    PAYMENT_ON_HOLD: { label: trans('Oczekiwanie na płatność'), class: 'order-status-label--payment-on-hold' },
    PAYMENT_PAID: { label: trans('Opłacono'), class: 'order-status-label--payment-paid' },
    PAYMENT_PENDING: { label: trans('Przetwarzanie płatności'), class: 'order-status-label--payment-pending' },
    PROCESSING: { label: trans('Przetwarzanie'), class: 'order-status-label--processing' },
    PROCESSING_COLLECTION: { label: trans('W realizacji'), class: 'order-status-label--processing-collection' },
    PROCESSING_ON_HOLD: { label: trans('Wstrzymane'), class: 'order-status-label--processing-on-hold' },
    READY_FOR_COLLECTION: { label: trans('Gotowe do odbioru'), class: 'order-status-label--ready-for-collection' },
    READY_FOR_DISPATCH: { label: trans('Oczekuje na wysyłkę'), class: 'order-status-label--ready-for-dispatch' },
    RETURNED: { label: trans('Zwrot'), class: 'order-status-label--returned' },
    UNKNOWN: { label: trans('Nieznany'), class: 'order-status-label--unknown' },
  };

  const statusClass = computed(() => `${statusMap[props.status].class}`);
  const statusLabel = computed(() => statusMap[props.status].label);
</script>
<template>
  <span :class="statusClass" class="order-status-label">
    {{ statusLabel }}
  </span>
</template>
<style lang="scss">
  :root {
    --order-status-label-color: var(--color-default);
    --order-status-label-background: var(--color-secondary);
    --order-status-label-radius: 0.625rem;
    --order-status-label-font-size: 0.75rem;
    --order-status-label-padding-y: 0.25rem;
    --order-status-label-padding-x: 0.75rem;

    --order-status-label-color-info: var(--sf-status-color-info);
    --order-status-label-background-info: var(--sf-status-background-info);
    --order-status-label-color-danger: var(--sf-status-color-danger);
    --order-status-label-background-danger: var(--sf-status-background-danger);
    --order-status-label-color-warning: var(--sf-status-color-warning);
    --order-status-label-background-warning: var(--sf-status-background-warning);
    --order-status-label-color-severe: var(--sf-status-color-severe);
    --order-status-label-background-severe: var(--sf-status-background-severe);
    --order-status-label-color-success: var(--sf-status-color-success);
    --order-status-label-background-success: var(--sf-status-background-success);
  }

  .order-status-label {
    display: inline-block;
    background-color: var(--order-status-label-background);
    color: var(--order-status-label-color);
    border-radius: var(--order-status-label-radius);
    font-size: var(--order-status-label-font-size);
    padding: var(--order-status-label-padding-y) var(--order-status-label-padding-x);
    font-weight: var(--font-weight-bold);

    &--cancelled,
    &--payment-failed {
      background: var(--order-status-label-background-danger);
      color: var(--order-status-label-color-danger);
    }

    &--complained,
    &--returned {
      background: var(--order-status-label-background-severe);
      color: var(--order-status-label-color-severe);
    }

    &--completed,
    &--dispatched,
    &-payment-paid,
    &--ready-for-collection {
      background: var(--order-status-label-background-success);
      color: var(--order-status-label-color-success);
    }

    &--draft,
    &--new,
    &--offer,
    &--payment-pending,
    &--processing,
    &--processing-collection,
    &--ready-for-dispatch,
    &--unknown {
      background: var(--order-status-label-background-info);
      color: var(--order-status-label-color-info);
    }

    &--payment-on-hold,
    &--processing-on-hold {
      background: var(--order-status-label-background-warning);
      color: var(--order-status-label-color-warning);
    }
  }
</style>
