<script setup>
  import { useBreakpoints } from '$storefront';
  const breakpoints = useBreakpoints();

</script>

<template>
  <div class="about-wrapper">
    <div class="about-header">
      <img class="about-logo" src="../../../../assets/images/logo.png" alt="">
      <SfButton small class="btn-custom" href="/o-nas" v-if="!breakpoints.xl.value">
        <template #default>
          Zobacz więcej
        </template>
        <template #after>
          <IconArrow />
        </template>
      </SfButton>
    </div>

    <p class="text">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.
    </p>
    <div class="button-wrapper" v-if="breakpoints.xl.value">
      <SfButton to="/o-nas">O nas</SfButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 50px;
    padding: 50px 75px;
    background-color: var(--color-white);
    border-radius: 5px;
    flex-grow: 1;

    .about-logo {
      width: 200px;
    }

    .text {
      line-height: 22px;
    }

    .button-wrapper {
      text-align: end;
    }

    .about-header {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: space-between;
      align-items: center;

      .btn-custom {
        border: none;

        svg {
          font-size: 6px;
          transform: rotate(-90deg);
        }
      }
    }
  }

  @media(max-width: 992px) {
    .about-wrapper .about-logo {
      width: 150px;
    }
  }
</style>
