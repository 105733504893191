<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 18.262 14.814">
    <g transform="translate(-1010 -525.613)">
      <path id="Icon_awesome-warehouse" data-name="Icon awesome-warehouse"
        d="M16.466,3.157,9.127.1a1.3,1.3,0,0,0-1,0L.8,3.157A1.3,1.3,0,0,0,0,4.352v9.243a.216.216,0,0,0,.216.216H2.373a.216.216,0,0,0,.216-.216V6.906a.873.873,0,0,1,.879-.863H13.793a.873.873,0,0,1,.879.863v6.689a.216.216,0,0,0,.216.216h2.158a.216.216,0,0,0,.216-.216V4.352A1.3,1.3,0,0,0,16.466,3.157Z"
        transform="translate(1010.5 526.117)" fill="none" stroke="currentColor" stroke-width="1" />
      <g transform="translate(1016 534.014)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1">
        <rect width="6.623" height="5.929" stroke="none" />
        <rect x="0.5" y="0.5" width="5.623" height="4.929" fill="none" />
      </g>
    </g>
  </svg>
</template>