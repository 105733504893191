<script setup lang="ts">
  import { useBreakpoints, type SaleOfferMessage, type SaleOfferQuotation } from '$storefront';
  import type { PropType } from 'vue';

  const breakpoints = useBreakpoints();

  defineProps({
    offer: { type: Object, required: true },
    messages: { type: Array as PropType<SaleOfferMessage[]>, required: true },
    quotations: { type: Array as PropType<SaleOfferQuotation[]>, required: true },
  });
</script>
<template>
  <div class="offer">
    <SfTile large>
      <template #title>
        <div class="offer__header">
          <h1 class="offer__heading" v-if="breakpoints.lg">
            {{ $t('Zapytanie Nr #:nr', { nr: offer.id }) }}
          </h1>
          <div class="offer__date">
            {{ $t('Data zapytania:') }}
            <SfDateTime :value="offer.created_at" />
          </div>
        </div>
      </template>
      <template #headerAction>
        <div class="offer__status">
          <SfOfferStatus :status="offer.status" />
        </div>
      </template>
    </SfTile>
    <div class="offer__details">
      <OfferChat :offer="offer" :messages="messages" />
      <OfferQuotations :offer="offer" :quotations="quotations" />
    </div>
  </div>
</template>
<style scoped lang="scss">
  @use "$assets/mixins/media";

  .offer {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__header {
      display: flex;
      flex-direction: column;
    }

    &__date {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
      font-weight: var(--font-weight-semi);
    }

    &__status {
      font-weight: var(--font-weight-semi);
      margin-left: 0.375rem;
    }

    &__details {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;

      @include media.query(md) {
        flex-direction: column;
      }
    }
  }
</style>
