<template>
  <article class="product-section">
    <header class="product-section__title">
      <slot name="title" />
    </header>
    <slot />
  </article>
</template>
<style lang="scss" scoped>
  .product-section {
    scroll-margin-top: 12rem;
    &__title {
      color: var(--primary-color);

      :deep(h1), :deep(h2), :deep(h3), :deep(h4), :deep(h5), :deep(h6) {
        font-size: 1rem;
        padding: 1rem 0 2rem;
      }
    }
  }
</style>
