<script lang="ts" setup>
  import { VisitorLink, useShared } from '@plenny/visitor';

  defineProps({
    header: { type: String, required: true },
    content: { type: String, required: true },
  });

  const shared = useShared();
</script>

<template>
  <div class="wide">
    <Section :title="header" class="container">
      <div class="contact-wrapper">
        <div class="contact-wrapper__left">
          <div class="contact-wrapper__content" v-html="content" />
        </div>
        <div class="contact-wrapper__right">
          <Newsletter />
          <div class="contact-wrapper__media">
            <div class="contact-wrapper__row contact-wrapper__row--bold">
              <a :href="`callto:${shared.department.phone}`" class="contact-wrapper__link">
                <IconPhone />
                {{ shared.department.phone }}</a>
            </div>

            <div class="contact-wrapper__row">
              <a :href="`mailto:${shared.department.email}`" class="contact-wrapper__link">
                <IconEnvelope />
                {{ shared.department.email }}</a>
            </div>
          </div>
          <VisitorLink :href="route('web.contact.index')" class="contact-wrapper__redirect">{{ $t('Kontakt') }}</VisitorLink>
        </div>
      </div>
    </Section>
  </div>
</template>

<style lang="scss" scoped>
  .wide {
    background: var(--color-white);
    // section renderer has 2rem gap, for sections in 'o-nas' page we don't need to have extra space
    margin-bottom: -2rem;
  }

  .contact-wrapper {
    display: grid;
    grid-template-columns: 1fr 480px;
    gap: 6rem;
    padding-bottom: 3rem;

    @media(max-width: 1200px) {
      gap: 3rem;
    }

    @media(max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding-bottom: 0;
    }

    &__left {
      display: flex;
      flex-direction: column;

      h2 {
        color: black;
      }

      .orange-text {
        color: var(--primary-color);
      }
    }

    &__right {
      border: 1px solid var(--color-primary-black);
      border-radius: 20px;
      padding: 2.5rem 2.5rem 2rem 2.5rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-self: flex-start;

      @media (max-width: 992px) {
        padding: 0;
        border-radius: 0;
        border: none;
      }
    }

    &__content {
      margin-left: 8rem;
      letter-spacing: 0.32px;
      line-height: 26px;

      @media (max-width: 1200px) {
        margin-left: 3rem;
      }

      @media (max-width: 992px) {
        margin-left: 0;
        line-height: 22px;
      }
    }

    &__media {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media (max-width: 992px) {
        display: none;
      }
    }

    &__row {
      display: flex;
      gap: 1rem;

      &--bold {
        font-weight: bold;
      }

    }

    &__link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__redirect {
      text-transform: uppercase;
      font-weight: 600;
      color: var(--primary-color);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
</style>