<script lang="ts" setup>
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    search: { type: String, required: false },
    category: { type: Object, required: false },
    module: { type: Object, required: false },
    total: { type: Number, required: false },
  });

  const title = computed(() => {
    if (props.search) {
      return trans('Wyszukiwanie: :search', { search: props.search });
    } else if (props.category) {
      return props.category.title;
    } else if (props.module) {
      return props.module.title;
    } else {
      return trans('Produkty');
    }
  });

  const description = computed(() => {
    if (props.category) {
      return props.category.description_short;
    } else if (props.module) {
      return props.module.description_short;
    } else {
      return null;
    }
  });
</script>
<template>
  <header class="products-heading">
    <h1 class="products-heading__header">
      <span class="products-heading__title" v-html="title" />
      <span v-if="total" class="products-heading__quantity" v-html="`(${total})`" />
    </h1>
    <p v-if="description" class="products-heading__description" v-html="description" />
  </header>
</template>
<style lang="scss">
  :root {
    --sf-products-heading-gap: var(--gap-card);
    --sf-products-heading-color: var(--color-default);
    --sf-products-heading-transform: normal;
    --sf-products-heading-size: 1.75rem;
    --sf-products-heading-line-height: 2.5rem;

    --sf-products-heading-quantity-size: 1.25rem;
    --sf-products-heading-quantity-color: var(--color-secondary);
  }

  .products-heading {
    display: flex;
    flex-direction: column;
    gap: var(--sf-products-heading-gap);
    color: var(--sf-products-heading-color);
    text-transform: var(--sf-products-heading-transform);

    &__header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-size: var(--sf-products-heading-size);
      line-height: var(--sf-products-heading-line-height);
    }

    &__quantity {
      font-size: var(--sf-products-heading-quantity-size);
      color: var(--sf-products-heading-quantity-color);
    }
  }
</style>
