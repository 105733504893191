<script setup lang="ts">
  import { computed } from 'vue';

  const props = defineProps({
    value: { type: String, required: false },
    time: { type: Boolean, required: false, default: false },
  });

  const normalized = computed(() => {
    return props.value ? new Date(props.value) : null;
  });

  const formatter = new Intl.DateTimeFormat(__i18n_current, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: props.time ? '2-digit' : undefined,
    minute: props.time ? '2-digit' : undefined,
    timeZone: __app_timezone,
  });
</script>
<template>
  <data v-if="normalized" :value="value">
    {{ formatter.format(normalized) }}
  </data>
</template>
