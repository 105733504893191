<script setup>
  import { useShared } from '@plenny/visitor';
  import { useScrollWidth } from '$storefront';
  import { computed } from 'vue';

  const props = defineProps({
    sticky: Boolean,
  });

  const shared = useShared();
  const scroll = useScrollWidth();

  const scrollWidth = computed(() => scroll.value + 'px');
</script>
<template>
  <div :class="{ 'sticky': sticky }" class="cat">
    <CategoriesList />
  </div>
</template>
<style lang="scss" scoped>
  .cat {
    --space: v-bind(scrollWidth);

    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 1600px;
    z-index: 99;

    &.sticky {
      top: 90px;
      left: calc(50% + 150px);
      max-width: 1300px;
      max-height: calc(100svh - 100px);

      @media(max-width: 1650px) {
        left: calc(50% + 30px);
        max-width: 1100px;
      }
      @media(max-width: 1200px) {
        left: calc(50% + 30px);
        max-width: 850px;
      }

      @media(max-width: 992px) {
        top: 70px;
        max-width: 700px;
      }
    }
  }
</style>
