<script setup lang="ts">
  import { useCart } from '$storefront';

  defineProps({
    order: { type: Object, required: false },
  });

  const { cart } = useCart();
</script>
<template>
  <footer class="order-footer">
    <div class="order-footer__content">
      <div class="order-footer__column-left">
        <VisitorLink :href="route('web.terms_and_condition.index')" target="_blank">
          {{ $t('Regulamin') }}
        </VisitorLink>
        <VisitorLink :href="route('web.privacy_policy.index')" target="_blank">
          {{ $t('Polityka prywatności') }}
        </VisitorLink>
      </div>
      <div class="order-footer__column order-footer__column-middle">
        <CopyrightMessage class="order-footer__copy" />
      </div>
      <div class="order-footer__column order-footer__column-right">
        <p v-if="order?.id || cart.id" class="order-footer__card-id">
          {{ $t('Numer zamówienia') + ':' }}
          <span class="order-footer__id">{{ order?.id || cart.id }}</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-footer-border: var(--color-black);
    --sf-order-footer-background: var(--color-black);
  }

  .order-footer {
    border-top: 1px solid var(--sf-order-footer-border);

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.625rem 0;
      width: 98%;
      max-width: 1600px;
      margin: 0 auto;
    }

    &__column-left {
      display: flex;
      flex-direction: column;
      row-gap: 0.125rem;
    }
  }

  @include media.query(md) {
    .order-footer {
      border: none;
      background-color: var(--sf-tile-background);

      &__content {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }

      &__column-left {
        flex-direction: row;
        order: -1;
        gap: 1rem;
        margin-right: 1rem;
      }

      &__column-right {
        order: 2;
        padding: 0.25rem 0;
      }

      &__column-middle {
        order: 3;
        width: 100%;
        text-align: center;
        margin-top: 0.375rem;
        padding-top: 0.375rem;
        border-top: 1px solid var(--sf-order-footer-border);
      }
    }
  }
</style>
