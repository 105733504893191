<script lang="ts" setup>

  defineProps({
    value: { type: [String, Number], required: true },
  });

  const percentage = new Intl.NumberFormat(__i18n_current, {
    style: 'percent',
  });

  function formatPercent(value: string | number) {
    const numeric = Number(value);

    if (isNaN(numeric)) {
      throw new Error(`"${value}" cannot be cast to a numeric value.`);
    }

    return percentage.format(Math.abs(numeric) / -100);
  }

</script>

<template>
  {{ formatPercent(value) }}
</template>
