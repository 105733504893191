<script setup lang="ts">
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';
  import { UnitFormatter } from '$support';

  const props = defineProps({
    variant: { type: Object, required: true },
    amount: { type: Boolean, default: false },
  });

  const classes = computed(() => {
    return {
      'product-availability--available': props.variant.stock_available || props.variant.stock_on_order,
    };
  });

  const stockAvailable = computed(() => {
    const stock = Number(props.variant.stock_available);
    const unit = props.variant.stock_unit;

    if (props.variant.stock_on_order) {
      return trans('Dostępne');
    }

    if (stock > 0) {
      if (props.amount) {
        return trans('Dostępne: <span class=\'product-availability__amount\'>:stock</span>', { stock: UnitFormatter.short(unit, stock) });
      }
      return trans('Dostępne');
    }

    return trans('Niedostępne');
  });
</script>
<template>
  <span class="product-availability" :class="classes" v-html="stockAvailable" />
</template>
<style lang="scss" scoped>
  .product-availability {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    font-size: 0.75rem;

    &::before {
      content: "";
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      background: var(--color-red);
      border-radius: 50%;
    }

    :deep(.product-availability__amount) {
      color: var(--primary-color);
    }

    &--available::before {
      background: var(--color-green);
    }
  }
</style>
