<script lang="ts" setup>
  import { onMounted, onUnmounted, computed, watch, ref } from 'vue';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const props = defineProps({
    small: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
    left: { type: Boolean, required: false, default: false },
  });

  const htmlModalElement = ref();
  const visible = ref(false);

  const drawerClasses = computed(() => {
    return {
      'drawer--left': props.left,
      'drawer--right': !props.left,
    };
  });

  const containerClasses = computed(() => {
    return {
      'drawer__container--left': props.left,
      'drawer__container--right': !props.left,
    };
  });

  const wrapperClasses = computed(() => {
    return {
      'drawer__wrapper--small': props.small,
      'drawer__wrapper--large': props.large,
      'drawer__wrapper--left': props.left,
      'drawer__wrapper--right': !props.left,
    };
  });

  function show() {
    htmlModalElement.value.showModal();
    visible.value = true;
  }

  // Dialog element is closed using transition's "after leave" event.
  // Otherwise, leaving transition wouldn't work.
  function hide() {
    visible.value && (visible.value = false);
  }

  function close() {
    htmlModalElement.value.close();
    open.value = false;
  }

  function onPopstate() {
    hide();
  }

  onMounted(() => {
    window.addEventListener('popstate', onPopstate);
    open.value && show();
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', onPopstate);
  });

  // We need to watch for open model change.
  // Otherwise, the modal won't hide properly when model is changed from parent.
  watch(open, (value) => {
    if (value) {
      show();
    } else {
      hide();
    }
  });
</script>
<template>
  <dialog ref="htmlModalElement" class="drawer" :class="drawerClasses" @close.prevent="open = false" @click.stop="void 0">
    <transition name="drawer-slide-in" @after-leave="close">
      <div v-if="visible" class="drawer__viewport">
        <div class="drawer__container" :class="containerClasses">
          <div class="drawer__wrapper" :class="wrapperClasses">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </dialog>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-drawer-backdrop-background: var(--backdrop-default);
    --sf-drawer-background: rgb(255 255 255);
    --sf-drawer-shadow: none;

    --sf-drawer-gap-x: 2rem;
    --sf-drawer-gap-y: 2rem;
    --sf-drawer-padding-x: 2rem;
    --sf-drawer-padding-y: 2rem;
    --sf-drawer-max-width: 600px;
    --sf-drawer-border-radius: 0;

    --sf-drawer-gap-x-sm: 1rem;
    --sf-drawer-gap-y-sm: 1rem;
    --sf-drawer-padding-x-sm: 1rem;
    --sf-drawer-padding-y-sm: 1rem;
    --sf-drawer-max-width-sm: 400px;
    --sf-drawer-border-radius-sm: 0;

    --sf-drawer-gap-x-lg: 3rem;
    --sf-drawer-gap-y-lg: 3rem;
    --sf-drawer-padding-x-lg: 3rem;
    --sf-drawer-padding-y-lg: 3rem;
    --sf-drawer-max-width-lg: 800px;
    --sf-drawer-border-radius-lg: 0;
  }

  .drawer {
    position: fixed;
    background: none;
    border: none;
    width: 100%;
    height: 100dvh;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;

    &::backdrop {
      background-color: var(--sf-drawer-backdrop-background, --backdrop-default);
    }

    &__viewport {
      position: fixed;
      width: 100%;
      height: 100dvh;
      top: 0;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-height: 100%;

      &.drawer__container--left {
        justify-content: flex-start;
      }
    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--sf-drawer-gap-y) var(--sf-drawer-gap-x);
      width: 100%;
      max-width: var(--sf-drawer-max-width);
      min-height: 100dvh;
      padding: var(--sf-drawer-padding-x) var(--sf-drawer-padding-y);
      background-color: var(--sf-drawer-background);
      box-shadow: var(--sf-drawer-shadow);

      @include media.query(lg) {
        gap: calc(var(--sf-drawer-gap-x) / 2) calc(var(--sf-drawer-gap-y) / 2);
        padding: calc(var(--sf-drawer-padding-x) / 2) calc(var(--sf-drawer-padding-y) / 2);
      }

      &--small {
        gap: var(--sf-drawer-gap-x-sm) var(--sf-drawer-gap-y-sm);
        padding: var(--sf-drawer-padding-x-sm) var(--sf-drawer-padding-y-sm);
        border-radius: var(--sf-drawer-border-radius-sm);

        @include media.query(lg) {
          gap: calc(var(--sf-drawer-gap-x-sm) / 2) calc(var(--sf-drawer-gap-y-sm) / 2);
          padding: calc(var(--sf-drawer-padding-x-sm) / 2) calc(var(--sf-drawer-padding-y-sm) / 2);
        }
      }

      &--large {
        gap: var(--sf-drawer-gap-x-lg) var(--sf-drawer-gap-y-lg);
        padding: var(--sf-drawer-padding-x-lg) var(--sf-drawer-padding-y-lg);
        max-width: var(--sf-drawer-max-width-lg);
        border-radius: var(--sf-drawer-border-radius-lg);

        @include media.query(lg) {
          gap: calc(var(--sf-drawer-gap-x-lg) / 2) calc(var(--sf-drawer-gap-y-lg) / 2);
          padding: calc(var(--sf-drawer-padding-x-lg) / 2) calc(var(--sf-drawer-padding-y-lg) / 2);
        }
      }

      @include media.query(lg) {
        border-radius: 0;
      }
    }

    &--left {
      .drawer-slide-in-enter-from,
      .drawer-slide-in-leave-to {
        transform: translateX(-800px);
      }
    }
  }

  .drawer-slide-in-enter-active,
  .drawer-slide-in-leave-active {
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
    transition-duration: 250ms;
  }

  .drawer-slide-in-enter-from,
  .drawer-slide-in-leave-to {
    transform: translateX(600px);
  }
</style>
