<script setup lang="ts">
  import { route, $delete } from '@plenny/visitor';

  function actionLogout() {
    $delete(route('web.account.session.destroy'));
  }
</script>
<template>
  <div class="verify">
    <SfTile large>
      <template #title>
        <h1 class="account-heading verify__heading">{{ $t('Twoje konto nie zostało jeszcze zaakceptowane') }}</h1>
      </template>
      <template #default>
        <div class="verify__message">
          <p class="verify__text">{{ $t('Twoje konto nie zostało jeszcze zweryfikowane przez obsługę sklepu.') }}</p>
          <p class="verify__text">{{ $t('Gdy twoje konto zostanie zatwierdzone otrzymasz powiadomienie e-mail.') }}</p>
        </div>
        <div class="verify__resend">
          <SfButton @click="actionLogout">
            <template #before>
              <SfIconPower />
            </template>
            <template #default>
              {{ $t('Wyloguj się') }}
            </template>
          </SfButton>
        </div>
      </template>
    </SfTile>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .verify {
    width: 98%;
    max-width: 1200px;
    margin: 100px auto;

    &__message {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      line-height: 1.5;
    }

    &__resend {
      display: flex;
      gap: 2rem;
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }

  @include media.query(lg) {
    .verify {
      margin: 50px auto;
      text-align: center;

      .tile__content {
        padding: 1.5rem 0.625rem;

        .tile__header {
          justify-content: center;
        }
      }

      &__header {
        margin-bottom: 0.625rem;
      }


      &__heading {
        font-size: 1.375rem;
        text-align: center;
      }

      &__resend {
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
        margin-top: 1rem;
      }
    }
  }
</style>
