<script setup lang="ts">
  import type { PropType } from 'vue';
  import type { SaleOfferMessage } from '$storefront';
  import { $post, route } from '@plenny/visitor';

  const props = defineProps({
    offer: { type: Object, required: true },
    messages: { type: Object as PropType<SaleOfferMessage[]>, required: true },
  });

  function sendMessage(data) {
    return $post(route('web.account.offers.messages.store', { offer: props.offer.id }), data);
  }
</script>
<template>
  <div class="chat">
    <SfTile>
      <VisitorForm @submit="sendMessage" v-slot="{ submitting }">
        <div class="chat__form">
          <div class="chat__control">
            <SfFormTextarea label="Wyślij wiadomość" name="message" rows="1" />
          </div>
          <div class="chat__submit">
            <SfButton type="submit" primary :loading="submitting">
              {{ $t('Wyślij') }}
            </SfButton>
          </div>
        </div>
      </VisitorForm>
      <div class="chat__messages">
        <OfferChatMessage v-for="message in messages" :message="message" />
      </div>
    </SfTile>
  </div>
</template>
<style scoped lang="scss">
  .chat {
    flex-grow: 0;
    width: 100%;

    :deep(textarea) {
      &.control {
        min-height: 0;
      }
    }

    &__form {
      display: flex;
      align-items: flex-start;
      gap: 0.25rem;
      width: 100%;
    }

    &__control {
      width: 100%;
    }

    &__messages {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
</style>
