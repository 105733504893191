<script lang="ts" setup>
  import type { PropType } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { $patch, route } from '@plenny/visitor';

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  type Notification = {
    'review_reminder': boolean;
  }

  defineProps({
    url: { type: String, required: false },
    notifications: { type: Object as PropType<Notification>, required: true },
  });

  const breakpoints = useBreakpoints();

  function actionUpdate(data: Notification) {
    return $patch(route('web.account.notifications.update'), data);
  }
</script>

<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Powiadomienia') }}
  </AccountMobileNavigation>
  <VisitorForm :initial="notifications" :submit="actionUpdate" v-slot="{ submitting }" class="account-notifications">
    <SfTile large class="account-notifications__content">
      <template #title v-if="breakpoints.lg">
        <h1 class="account__heading">{{ $t('Powiadomienia') }}</h1>
      </template>

      <template #default>
        <div class="account-notifications__data">
          <div class="account-notifications__column">
            <h2 class="account-notifications__column-heading">{{ $t('Zmiana danych') }}</h2>
            <SfFormSwitch :label="$t('Przypomnienia o opiniach')" name="review_reminder" />
          </div>
        </div>
      </template>
      <template #footer>
        <SfButton type="submit" primary :loading="submitting" class="account-notifications__submit">
          {{ $t('Zapisz zmiany') }}
        </SfButton>
      </template>
    </SfTile>
  </VisitorForm>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .account-notifications {
    &__column-heading,
    &__newsletter-heading {
      font-size: 1.125rem;
    }

    &__data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
    }

    &__column {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
    }

    &__newsletter {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  @include media.query(xl) {

    .account-notifications {
      &__column-heading,
      &__newsletter-heading {
        font-size: 1rem;
      }

      &__data {
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      &__column {
        row-gap: 1.25rem;
      }

      &__newsletter {
        margin: 1rem 0;
        gap: 0.5rem;
      }

      &__submit {
        width: 100%;
      }
    }
  }


</style>
