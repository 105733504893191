<script lang="ts" setup>
  import { ref, watch, onBeforeUnmount, onMounted, computed, type Ref } from 'vue';
  import { useShared, $post, route } from '@plenny/visitor';

  type Shared = {
    active_country: number,
    active_country_code: string,
    active_currency: string,
    active_currency_code: string,
    active_locale: string,
    countries: { value: number, label: string }[];
    currencies: { value: number, label: string }[];
    locales: { value: string, label: string }[];
  }

  const shared = useShared() as Ref<Shared>;

  const country = ref(shared.value.active_country);
  const currency = ref(shared.value.active_currency);
  const locale = ref(shared.value.active_locale);

  const switcherHtml = ref();
  const open = ref(false);

  const activeLocale = computed(() => shared.value.active_locale.toUpperCase());
  const activeCurrency = computed(() => shared.value.active_currency_code.toUpperCase());

  function onClickOutside(event: MouseEvent) {
    if (switcherHtml.value && !switcherHtml.value.contains(event.target as Node)) {
      open.value = false;
    }
  }

  function toggleCountry(event) {
    $post(route('web.country.store'), { country: event.target.value });
  }

  function toggleCurrency(event) {
    $post(route('web.currency.store'), { currency: event.target.value });
  }

  function toggleLocale(event) {
    $post(route('web.locale.store'), { locale: event.target.value });
  }

  onMounted(() => {
    document.addEventListener('click', onClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', onClickOutside);
  });

  watch(() => shared.value.active_country, (value) => {
    country.value = value;
  });

  watch(() => shared.value.active_currency, (value) => {
    currency.value = value;
  });

  watch(() => shared.value.active_locale, (value) => {
    locale.value = value;
  });
</script>
<template>
  <div ref="switcherHtml" class="switcher">
    <div class="switcher__btn" role="button" tabindex="0" @click="open = !open" @keydown.space.enter="open = !open">
      <SfFlag :code="shared.active_country_code" gradient="real-linear" has-border has-border-radius has-drop-shadow size="m" />
      {{ activeLocale + '/' + activeCurrency }}
      <SfIconChevronDown :class="{ 'switcher__btn__arrow--rotated': open }" class="switcher__btn__arrow" />
    </div>
    <Transition name="fade">
      <div v-if="open" class="switcher__dropdown">
        <SfFormSelect v-model="country" :label="$t('Dostawa do')" :options="shared.countries" required @change="toggleCountry" />
        <SfFormSelect v-model="locale" :label="$t('Język')" :options="shared.locales" required @change="toggleLocale" />
        <SfFormSelect v-model="currency" :label="$t('Waluta')" :options="shared.currencies" required @change="toggleCurrency" />
      </div>
    </Transition>
  </div>
</template>
<style lang="scss" scoped>
  .switcher {
    display: flex;
    align-items: center;
    position: relative;

    &__btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      height: 40px;
      margin-right: 1rem;

      &__arrow {
        transition: transform 0.3s ease;

        &--rotated {
          transform: rotate(180deg);
        }
      }
    }

    &__dropdown {
      position: absolute;
      right: 0;
      top: 100%;
      width: 300px;
      background: var(--color-white);
      padding: 1.25rem;
      z-index: 999999;
      border-bottom-right-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      box-shadow: var(--secondary-box-shadow-100);
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
  }
</style>
