<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    selected: {
      type: Boolean,
      default: false,
      required: false,
    },
    interactive: {
      type: Boolean,
      default: false,
      required: false,
    },
  });

  const listCardClasses = computed(() => {
    return {
      'list-card--selected': props.selected,
      'list-card--interactive': props.interactive,
    };
  });
</script>

<template>
  <div :class="listCardClasses" class="list-card">
    <div class="list-card__wrapper">
      <div v-if="$slots.image" class="list-card__image">
        <slot name="image" />
      </div>
      <div class="list-card__content">
        <div class="list-card__content-default">
          <slot name="default" />
        </div>
        <div v-if="$slots.icons || $slots.actions" class="list-card__actions">
          <div v-if="$slots.icons" class="list-card__actions-icons">
            <slot name="icons" />
          </div>
          <div v-if="$slots.actions" class="list-card__actions-content">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-list-card-border-radius: 0;
    --sf-list-card-gap: 1rem;
    --sf-list-card-padding-x: 1rem;
    --sf-list-card-padding-y: 1rem;

    --sf-list-card-color: var(--color-default);
    --sf-list-card-background: var(--background-default);
    --sf-list-card-border: 1px solid var(--secondary-border);
    --sf-list-card-box-shadow: none;

    --sf-list-card-color-alt: var(--color-secondary);
    --sf-list-card-color-alt-hover: var(--secondary-foreground-hover);
    --sf-list-card-color-alt-selected: var(--primary-foreground-active);

    --sf-list-card-color-hover: var(--secondary-foreground-hover);
    --sf-list-card-background-hover: var(--secondary-background-hover);
    --sf-list-card-border-hover: 1px solid var(--secondary-border-hover);
    --sf-list-card-box-shadow-hover: var(--secondary-box-shadow-200);

    --sf-list-card-color-selected: var(--primary-foreground-active);
    --sf-list-card-background-selected: var(--primary-background-active);
    --sf-list-card-border-selected: 1px solid var(--primary-border-active);
    --sf-list-card-box-shadow-selected: var(--primary-box-shadow-200);

    --sf-list-card-transition-timing-func: ease-out;
    --sf-list-card-transition-duration: 200ms;

    --sf-list-card-price-current-font-size: 1rem;
    --sf-list-card-price-current-font-line-height: 1rem;
    --sf-list-card-price-catalog-font-size: 0.75rem;
    --sf-list-card-price-catalog-font-line-height: 1rem;
  }

  .list-card {
    position: relative;
    flex-grow: 1;
    width: 100%;
    color: var(--sf-list-card-color);
    background-color: var(--sf-list-card-background);
    border: var(--sf-list-card-border);
    border-radius: var(--sf-list-card-border-radius);
    box-shadow: var(--sf-list-card-box-shadow);
    transition-property: border, background-color, box-shadow;
    transition-timing-function: var(--sf-list-card-transition-timing-func);
    transition-duration: var(--sf-list-card-transition-duration);


    &--interactive {
      cursor: pointer;
    }

    &--interactive:not(.list-card--selected):hover,
    &--interactive:not(.list-card--selected):focus {
      --sf-list-card-color: var(--sf-list-card-color-hover);
      --sf-list-card-color-alt: var(--sf-list-card-color-alt-hover);
      --sf-list-card-background: var(--sf-list-card-background-hover);
      --sf-list-card-border: var(--sf-list-card-border-hover);
      --sf-list-card-box-shadow: var(--sf-list-card-box-shadow-hover);
    }

    &--selected {
      --sf-list-card-color: var(--sf-list-card-color-selected);
      --sf-list-card-color-alt: var(--sf-list-card-color-alt-selected);
      --sf-list-card-background: var(--sf-list-card-background-selected);
      --sf-list-card-border: var(--sf-list-card-border-selected);
      --sf-list-card-box-shadow: var(--sf-list-card-box-shadow-selected);
    }

    &__wrapper {
      display: flex;
      justify-content: flex-start;
      position: relative;
      padding: var(--sf-list-card-padding-y) var(--sf-list-card-padding-x);
      gap: var(--sf-list-card-gap);
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex: 1 1 auto;
      gap: var(--sf-list-card-gap);
    }

    &__content-default {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      color: var(--sf-list-card-color);
      gap: calc(var(--sf-list-card-gap) / 2);

    }

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      gap: var(--sf-list-card-gap);
    }

    &__actions-icons {
      display: flex;
      align-items: center;
      gap: calc(var(--sf-list-card-gap) / 2);
    }

    &__actions-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: calc(var(--sf-list-card-gap) / 2);

      .omnibus {
        text-align: right;
      }
    }

    .price {
      --sf-product-price-current-font-size: var(--sf-list-card-price-current-font-size);
      --sf-product-price-current-font-line-height: var(--sf-list-card-price-current-font-line-height);
      --sf-product-price-catalog-font-size: var(--sf-list-card-price-catalog-font-size);
      --sf-product-price-catalog-font-line-height: var(--sf-list-card-price-catalog-font-line-height);
    }
  }

  @include media.query(lg) {
    .list-card {
      &__wrapper {
        padding: calc(var(--sf-list-card-padding-y) / 2) calc(var(--sf-list-card-padding-x) / 2);
        gap: var(--sf-list-card-gap);
      }

      &__content {
        flex-direction: column;
      }

      &__actions {
        gap: 0;
      }

      &_actions-content {
        gap: 0;
      }
    }
  }
</style>
