import { useShared } from '@plenny/visitor';
import { computed } from 'vue';

export function useCart() {
  const shared = useShared();

  const cart = computed(() => ({ ...shared.value.cart }));
  const items = computed(() => cart.value?.items || []);
  const count = computed(() => items.value.length);

  return { cart, items, count };
}
