<script lang="ts" setup>
  import { computed } from 'vue';
  import { useShared } from '@plenny/visitor';

  const props = defineProps({
    value: { type: [String, Number], required: true },
    code: { type: String, required: false },
    decimals: { type: Number, required: false },
  });

  const shared = useShared();

  const formatter = computed(() => {
    return new Intl.NumberFormat(__i18n_current, {
      style: 'currency',
      currency: props.code || shared.value.active_currency_code,
      minimumFractionDigits: props.decimals || shared.value.active_currency_decimals,
      maximumFractionDigits: props.decimals || shared.value.active_currency_decimals,
    });
  });

  const normalized = computed(() => {
    if (props.value !== undefined) {
      const parsed = Number(props.value);
      return isNaN(parsed) ? null : parsed;
    }
    return null;
  });
</script>
<template>
  <data v-if="normalized !== null" :value="normalized" class="money">
    <slot v-bind="{ formatter, normalized }">
      {{ formatter.format(normalized) }}
    </slot>
  </data>
</template>
