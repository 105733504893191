<script setup lang="ts">
  import { type PropType, onMounted, ref, nextTick, onBeforeUnmount, computed } from 'vue';
  import { $get, route } from '@plenny/visitor';

  type AttributeOption = {
    label: string,
    value: number,
  };

  type Attribute = {
    label: string,
    options: AttributeOption[],
    value: number,
  };

  type Variant = {
    id: number;
    slug: string;
    title: string;
    photo: string | null;
  }

  const variant = defineModel('variant', { type: Object, required: false });
  const attributes = defineModel('attributes', { type: Array as PropType<Attribute[]>, required: true });
  const selected = defineModel('selected', { type: Object, required: true });
  const variants = defineModel('variants', { type: Object, required: true });
  const series = defineModel('series', { type: Object, required: true });

  const props = defineProps({
    product: { type: Object, required: true },
    show: { type: String as PropType<'series' | 'variants'>, required: true },
  });

  const wrapperHtml = ref();
  const innerHtml = ref();
  const isOverflown = ref(false);
  const open = ref(false);
  const height = ref();
  const size = ref('40px');

  const elements = computed(() => {
    if (props.show === 'series') {
      return series.value;
    } else {
      return variants.value;
    }
  });

  function actionSelect(choosen: Variant) {
    $get(route('api.v1.web.product.variant.show', {
      product: props.product.id,
      variant: choosen.id,
    })).then((response) => {
      variant.value = response.variant;
      attributes.value = response.attributes;
      selected.value = response.selected;
      variants.value = response.variants;
      series.value = response.series;
    });
  }

  function toggle() {
    open.value = !open.value;
  }

  function resize() {
    let card = wrapperHtml.value.querySelector('.variants-grid-card') as HTMLElement;
    let rect = card.getBoundingClientRect();

    height.value = wrapperHtml.value.scrollHeight + 'px';
    size.value = rect.width + 'px';

    nextTick(() => {
      setTimeout(() => {
        isOverflown.value = innerHtml.value.scrollHeight > innerHtml.value.clientHeight;
      }, 200);
    });
  }

  onMounted(() => {
    window.addEventListener('resize', resize, { passive: true });
    resize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', resize);
  });
</script>
<template>
  <div class="variants-grid">
    <h2 class="variants-grid-header" v-if="$slots.title">
      <slot name="title" />
    </h2>
    <div class="variants-grid__container" :class="{ 'variants-grid__container--expanded': open }">
      <div ref="innerHtml" class="variants-grid__inner">
        <div ref="wrapperHtml" class="variants-grid__wrapper">
          <div v-for="variant in elements" class="variants-grid-card" :class="{ 'variants-grid-card--active': variant.slug === $props.variant.slug }" role="button" tabindex="0" :title="variant.title" @click="actionSelect(variant)">
            <MediaPicture :photo="variant.photo" sizes="240x240 2x, 120x120" />
          </div>
        </div>
      </div>
      <div v-if="isOverflown" class="variants-grid-show-more" role="button" tabindex="0" @click="toggle">
        {{ open ? $t('Pokaż mniej') : $t('Pokaż więcej') }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-variants-grid-gap: 3px;

    --sf-variants-grid-card-padding: 1px;
    --sf-variants-grid-card-radius: 0;
    --sf-variants-grid-card-border: var(--secondary-border);
    --sf-variants-grid-card-border-hover: var(--secondary-border-hover);
    --sf-variants-grid-card-border-active: var(--primary-border);
    --sf-variants-grid-card-border-active-hover: var(--primary-border-hover);

    --sf-variants-grid-more-foreground: var(--secondary-foreground);
    --sf-variants-grid-more-foreground-hover: var(--secondary-foreground-hover);
    --sf-variants-grid-more-background: var(--secondary-background);
    --sf-variants-grid-more-background-hover: var(--secondary-background-hover);
    --sf-variants-grid-more-border: var(--secondary-border);
    --sf-variants-grid-more-border-hover: var(--secondary-border-hover);
    --sf-variants-grid-more-border-radius: 0;
  }

  .variants-grid {
    --card-size: v-bind(size);
    --grid-height: v-bind(height);

    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

    &__container {
      display: grid;
      grid-template-rows: min(var(--grid-height), calc((var(--card-size) * 2) + var(--sf-variants-grid-gap)));
      transition: grid-template-rows 200ms ease-in-out;
      position: relative;
      width: 100%;

      &--expanded {
        grid-template-rows: calc(var(--grid-height) + var(--card-size) + var(--sf-variants-grid-gap));
      }
    }

    &__inner {
      overflow: hidden;
      min-height: 0;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
      gap: var(--sf-variants-grid-gap);
      width: 100%;
      min-height: 0;
    }
  }

  .variants-grid-header {
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
  }

  .variants-grid-card {
    display: flex;
    aspect-ratio: 1;
    padding: var(--sf-variants-grid-card-padding);
    transition: var(--primary-transition);
    border: 1px solid var(--sf-variants-grid-card-border);
    border-radius: var(--sf-variants-grid-card-radius);
    cursor: pointer;

    &:hover {
      border-color: var(--sf-variants-grid-card-border-hover);
    }

    &--active {
      border-color: var(--sf-variants-grid-card-border-active);

      &:hover {
        border-color: var(--sf-variants-grid-card-border-active-hover);
      }
    }

    picture,
    img {
      display: flex;
      object-fit: contain;
      object-position: center;
      aspect-ratio: 1;
    }
  }

  .variants-grid-show-more {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--sf-variants-grid-more-foreground);
    background: var(--sf-variants-grid-more-background);
    border: 1px solid var(--sf-variants-grid-more-border);
    border-radius: var(--sf-variants-grid-more-border-radius);
    transition-property: color, background-color, border-color;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    width: calc((var(--card-size) * 3) + (var(--sf-variants-grid-gap) * 2));
    height: var(--card-size);
    font-size: 0.75rem;
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    &:hover {
      color: var(--sf-variants-grid-more-foreground-hover);
      background: var(--sf-variants-grid-more-background-hover);
      border-color: var(--sf-variants-grid-more-border-hover);
    }
  }
</style>
