<script setup >
  import { onMounted, ref, onBeforeUnmount, watch, computed } from 'vue';
  import { route } from '@plenny/visitor';
  import { useBreakpoints, useScrollWidth, useScrollDetector } from '$storefront';

  const props = defineProps({
    breadcrumbs: { type: Array, required: true },
    sections: { type: Array, required: true },
    product: { type: Object, required: true },
    reviews: { type: Object, required: true },
    variant: { type: Object, required: false, default: null },
    brand: Object,
    categories: Array,
    recommended: Array,
    posts: Array,
    page: Object,
    review_available: { type: Boolean, required: true },
    reviews_summary: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();
  const scroll = useScrollWidth();

  const wrapperHtmlElement = ref();
  const wrapperIsNotIntersecting = ref(false);

  const variant = ref(props.variant);
  const related = ref([]);
  const success = ref(false);
  const visible = ref(false);

  const scrollWidth = computed(() => scroll.value + 'px');

  useScrollDetector({
    threshold: 8,
    callback: ({ down }) => {
      if (down) {
        if (wrapperIsNotIntersecting.value) {
          visible.value = true;
        }
      } else {
        visible.value = false;
      }
    },
  });

  let observer;

  onMounted(() => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        wrapperIsNotIntersecting.value = !entry.isIntersecting;
      });
    }, {
      root: document.body,
      threshold: 0.25,
    });

    observer.observe(wrapperHtmlElement.value);
  });

  onBeforeUnmount(() => {
    observer.disconnect();
  });

  watch(() => variant.value.slug, (value, oldValue) => {
    if (oldValue && value !== oldValue) {
      let loc = route('web.product.show', { slug: value });

      window.history.replaceState(loc, '', loc);
    }
  });
</script>
<template>
  <div class="container">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
  </div>

  <transition name="fade">
    <div v-if="visible" :class="{ visible }" aria-hidden="true" class="top-panel">
      <div class="container container-top-panel">
        <div class="info-wrapper">
          <div class="image-wrapper">
            <MediaPicture :photo="product.photo" sizes="100 x 100" />
          </div>
          <span class="title" v-html="variant?.title || product.title" />
        </div>
        <div class="price-container">
          <div>
            <SfProductPrice :price="variant || product" class="price-wrapper" />
            <SfProductOmnibus :price="variant || product" />
          </div>
          <SfCartButton v-model:variant="variant" :product="product">
            <SfButton :square="!breakpoints.lg" primary>
              <template #before>
                <IconCart />
              </template>
              <span v-if="breakpoints.lg" class="price-container__txt">
                {{ $t('Dodaj do koszyka') }}
              </span>
            </SfButton>
          </SfCartButton>
        </div>
      </div>
    </div>
  </transition>

  <div class="grid container">
    <div class="grid__left">
      <div ref="wrapperHtmlElement">
        <ProductDetail v-model:related="related" v-model:success="success" v-model:variant="variant" :brand="brand" :categories="categories" :page="page" :product="product" :sections="sections" />
      </div>
      <ProductDescription :product :recommended :reviews :sections :reviewAvailable="review_available" :reviewsSummary="reviews_summary" />
    </div>
    <ProductSide v-if="breakpoints.lg" v-model:related="related" v-model:success="success" v-model:variant="variant" :product="product" />
  </div>

  <CartButtonSuccessModal v-model:open="success" :product="product" :related="related" :variant="variant" />
</template>
<style lang="scss" scoped>
  .grid {
    display: grid;
    grid-template-columns: 1fr 360px;
    gap: 1.25rem;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 290px;
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 3.125rem;

      @media (max-width: 576px) {
        gap: 1.5rem;
      }
    }
  }

  .top-panel {
    --space: v-bind(scrollWidth);
    position: fixed;
    //height of header
    top: 105px;
    right: var(--space);
    left: 0;
    z-index: 95;
    transform: translateY(-120%);
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-menu);
    transition: var(--transition-primary);

    @media (max-width: 992px) {
      --sf-btn-gap: 0;
    }

    @media (max-width: 992px) {
      top: 65px;
    }

    @media (max-width: 576px) {
      top: 52px;
    }

    &.visible {
      transform: translateY(0);
    }

    .container-top-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 10px;

      .info-wrapper,
      .price-container {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 576px) {
          gap: 10px;
        }
      }

      .price-container {
        width: 35%;
        justify-content: flex-end;
      }

      .info-wrapper {
        width: 65%;

        .title {
          font-size: 1.25rem;
          font-weight: 700;

          @media (max-width: 576px) {
            font-size: 0.75rem;
          }
        }

        .image-wrapper {
          height: 50px;
          min-width: 60px;

          :deep(img) {
            height: 100%;
          }
        }
      }
    }
  }

  .fixed-bar {
    position: fixed;
    bottom: 0;
    height: 58px;
    width: 100%;
    z-index: 1;
    background-color: var(--color-white);
    box-shadow: 0 3px 10px #000029;
    transform: translateY(58px);
    transition: transform 0.3s;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 1rem;
    gap: 1rem;
    font-size: 12px;

    &.visible {
      transform: translateY(0);

      &.isScrollingUp {
        transform: translateY(-58px);
      }
    }

    .photo {
      height: 100%;
      aspect-ratio: 1;

      :deep(img) {
        height: 58px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      gap: 1rem;
    }
  }

  .container-additional {
    display: flex;
    gap: 60px;
    margin: 60px auto;

    .newsletter-wrapper {
      width: 60%;

      .newsletter {
        height: 100%;
      }
    }

    .carousel-article {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: auto;
      background-color: var(--color-white);
      border-radius: 5px;
      padding: 26px 10px;
    }
  }

  @media (max-width: 1600px) {
    .container-additional {
      gap: 30px;

      .newsletter-wrapper,
      .carousel-article {
        width: 50%;
      }
    }

    .newsletter {
      padding: 30px;
    }
  }

  @media (max-width: 1200px) {
    .container-additional {
      flex-direction: column;

      .newsletter-wrapper {
        width: 100%;
      }
    }
  }

  @media (max-width: 992px) {
    .container-additional {
      margin: 20px auto 40px;

      .newsletter-wrapper {
        width: 100%;

        .newsletter {
          padding: 20px 10px 17px;
        }
      }
    }
  }
</style>

