import { useShared } from '@plenny/visitor';
import { computed, toValue } from 'vue';
import type { MaybeRefOrGetter, ComputedRef } from '@vue/reactivity';

type CartItem = {
  variant_id: number;
  amount: string;
}

type Cart = {
  items: CartItem[];
}

type Variant = {
  id: number;
  stock_on_order: boolean;
  stock_available: string,
}

type Source = MaybeRefOrGetter<Variant | undefined> | ComputedRef<Variant | undefined> | undefined;

export function useVariantStock(source?: Source) {
  const shared = useShared();

  const stockOnOrder = computed(() => {
    return toValue(source)?.stock_on_order || false;
  });

  const amountInStock = computed(() => {
    return toValue(source)?.stock_available || 0;
  });

  const amountInCart = computed(() => {
    const cart = shared.value.cart as Cart;
    const variant = toValue(source);

    if (!cart || !variant) {
      return 0;
    }

    const item = cart.items.find((item) => {
      return item.variant_id === variant!.id;
    });

    if (!item) {
      return 0;
    }

    return item.amount;
  });

  const amountAvailable = computed(() => {
    return Number(amountInStock.value) - Number(amountInCart.value);
  });

  const inStock = computed(() => {
    if (stockOnOrder.value) {
      return true;
    }

    return Number(amountInStock.value) > 0;
  });

  const available = computed(() => {
    if (stockOnOrder.value) {
      return true;
    }

    if (Number(amountInStock.value) > 0) {
      return Number(amountInCart.value) < Number(amountInStock.value);
    }

    return false;
  });

  return {
    stockOnOrder,
    amountInStock,
    amountInCart,
    amountAvailable,
    inStock,
    available,
  };
}
