<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    product: { type: Object, required: true },
    horizontal: { type: Boolean, default: false },
    table: { type: Boolean, default: false },
  });

  const hasListAttributes = computed(() => {
    return props.product.list_attributes && props.product.list_attributes.length > 0;
  });
</script>
<template>
  <div>
    <dl v-if="hasListAttributes && !table" v-show="horizontal" class="product-attributes">
      <div v-for="attribute in product.list_attributes" :key="attribute.name" class="product-attributes__column">
        <dt class="product-attributes__property">{{ attribute.name }}:</dt>
        <dd :title="attribute.value" class="product-attributes__value">{{ attribute.value }}</dd>
      </div>
    </dl>
    <table v-else-if="hasListAttributes && table" class="product-attributes__table">
      <tbody>
        <tr v-for="attribute in product.list_attributes" :key="attribute.name">
          <th>{{ attribute.name }}:</th>
          <td>{{ attribute.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
  .product-attributes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0.375rem;
    font-size: 0.75rem;
    margin-top: 1.25rem;
    column-gap: 2.5rem;

    @media(max-width: 1400px) {
      grid-template-columns: 1fr;
      font-size: 0.75rem;
    }

    &__column {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    &__property {
      display: inline;
      font-weight: 500;
      margin-right: 0.25rem;
    }

    &__value {
      display: inline;
      color: var(--color-dark-gray);
    }

    &__table {
      tbody {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.25rem;

        @media (max-width: 576px) {
          grid-template-columns: 1fr;
        }

        tr {
          display: flex;
          padding: 0.75rem;

          th {
            text-align: left;
            font-weight: normal;
            margin-right: 0.5rem;
          }

          td {
            font-weight: bold;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          &:nth-child(4n + 1),
          &:nth-child(4n + 2) {
            background-color: #f2f2f2;
          }

          @media (max-width: 576px) {
            padding: 0.5rem;
            font-size: 0.875rem;

            &:nth-child(4n + 1),
            &:nth-child(4n + 2) {
              background-color: white;
            }

            &:nth-child(odd) {
              background-color: #f2f2f2;
            }
          }
        }
      }
    }
  }
</style>
