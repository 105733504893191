<script setup lang="ts">
  import { watch, ref } from 'vue';
  import { useShared } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  const shared = useShared();
  const current = ref(undefined);
  const category = ref();
  const open = defineModel('open', { type: Boolean, required: false, default: false });

  type CategoryTree = {
    category_id?: number;
    children: CategoryTree[];
    name: string | null;
    url: string;
    parent: CategoryTree | null;
  }

  function findCategory(tree: CategoryTree, category_id: number | undefined, depth = 1) {
    if (!category_id) {
      return tree;
    }

    for (let item of tree.children) {
      if (item.category_id === category_id) {
        return { ...item, parent: tree };
      }

      let result = findCategory(item, category_id, depth + 1);

      if (result) {
        return result;
      }
    }

    if (depth <= 1) {
      return tree;
    }

    return null;
  }

  watch(current, (value) => {
    category.value = findCategory({
      name: trans('Wszystkie'),
      parent: null,
      url: '',
      children: shared.value.categories,
    }, value);
  }, {
    immediate: true,
  });
</script>
<template>
  <SfDrawer v-model:open="open" left class="categories-drawer">
    <SfDrawerHeader v-if="category.parent" v-model:open="open" @click="current = category.parent.category_id">
      <div class="categories-drawer__header-content">
        <div class="categories-drawer__back-icon">
          <SfIconChevronLeft />
        </div>
        {{ category.name || $t('Wszystkie') }}
      </div>
    </SfDrawerHeader>
    <SfDrawerHeader v-else v-model:open="open">
      {{ $t('Kategorie') }}
    </SfDrawerHeader>
    <div class="categories-drawer__content">
      <ul class="categories-drawer__categories">
        <li v-for="item in category.children" class="categories-drawer__item">
          <template v-if="item.children.length > 0">
            <span @click="current = item.category_id" class="categories-drawer__category">
              <span class="categories-drawer__category-name">
                {{ item.name }}
                <small class="categories-drawer__category-available">
                  ({{ item.available }})
                </small>
              </span>
              <SfIconChevronRight class="categories-drawer__next-icon" />
            </span>
          </template>
          <template v-else>
            <VisitorLink  :href="item.url" @click.capture="open = false" class="categories-drawer__category categories-drawer__category--link">
              <span class="categories-drawer__category-name">
                {{ item.name }}
                <small class="categories-drawer__category-available">
                  ({{ item.available }})
                </small>
              </span>
            </VisitorLink>
          </template>
        </li>
      </ul>
    </div>
    <div v-if="category.parent" class="categories-drawer__show">
      <SfButton :href="category.url" @click.capture="open = false" primary>
        {{ $t('Pokaż oferty') }}
      </SfButton>
    </div>
  </SfDrawer>
</template>
<style lang="scss">
  :root {
    --sf-categories-drawer-box-shadow: var(--secondary-box-shadow-100);

    --sf-categories-drawer-border: none;
    --sf-categories-drawer-border-width: 0;
    --sf-categories-drawer-border-style: none;
  }

  .categories-drawer {
    --sf-drawer-header-font-size: 1.25rem;
    --sf-drawer-header-gap: 0.75rem;
    --sf-drawer-header-line-height: 2.375rem;
    --sf-drawer-header-padding-y: 0.75rem;
    --sf-drawer-header-padding-x: 1rem;
    --sf-drawer-header-background: white;

    --sf-drawer-padding-x: 0;
    --sf-drawer-padding-y: 0;
    --sf-drawer-gap-y: 0;

    .drawer__header {
      position: sticky;
      top: 0;
      box-shadow: var(--sf-categories-drawer-box-shadow);
      border-bottom-color: var(--sf-categories-drawer-border);
      border-bottom-width: var(--sf-categories-drawer-border-width);
      border-bottom-style: var(--sf-categories-drawer-border-style);
      z-index: 9;
    }

    &__header-content {
      display: flex;
      line-height: 2.375rem;;
      gap: 0.625rem;
    }

    &__content,
    &__content-categories,
    &__categories-wrapper,
    &__categories {
      display: flex;
      flex-direction: column;
    }


    &__back-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--sf-drawer-header-line-height);
      font-size: 1rem;
    }

    &__content {
      flex-grow: 1;
      padding: 0.5rem 0 0.5rem;
      width: 100%;
      max-height: 100%;
      background-color: var(--color-white);
    }

    &__content-categories {
      justify-content: space-between;
      flex-grow: 1;
      overflow-y: auto;
      max-height: 100%;
      padding-top: 0.625rem;
    }

    &__categories-wrapper {
      flex-grow: 1;
      max-height: 100%;
    }

    &__categories {
      list-style: none;
    }

    &__category-available {
      color: var(--color-gray);
      font-weight: var(--font-weight-regular);
    }

    &__category {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1rem;
      font-weight: var(--font-weight-semi);
    }

    &__next-icon {
      font-size: 0.75rem;
    }

    &__show {
      position: sticky;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0.75rem 1rem;
      font-weight: var(--font-weight-semi);
      background-color: var(--color-white);
      box-shadow: var(--sf-categories-drawer-box-shadow);
      border-top-color: var(--sf-categories-drawer-border);
      border-top-width: var(--sf-categories-drawer-border-width);
      border-top-style: var(--sf-categories-drawer-border-style);
      z-index: 9;
    }
  }
</style>
