<script setup lang="ts">
  import { useToasts } from '@plenny/visitor';

  const { toasts } = useToasts();
</script>

<template>
  <div class="toasts-manager">
    <TransitionGroup name="toast">
      <SfToast v-for="toast in toasts" :key="toast.id" v-bind="toast" />
    </TransitionGroup>
  </div>
</template>

<style lang="scss">
  .toasts-manager {
    display: contents;

    .toast {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      transition-property: all;
      transition-duration: 150ms;
      transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);

      &:nth-child(1) {
        opacity: 1;
        top: 16px;
        z-index: 900005;
      }

      &:nth-child(2) {
        opacity: 0.6;
        top: 22px;
        z-index: 900004;
        transform: translateX(-50%) scale(0.94);
        color: transparent !important;
      }

      &:nth-child(3) {
        opacity: 0.4;
        top: 30px;
        z-index: 900003;
        transform: translateX(-50%) scale(0.86);
        color: transparent !important;
      }

      &:nth-child(4) {
        opacity: 0.2;
        top: 40px;
        z-index: 900002;
        transform: translateX(-50%) scale(0.76);
        color: transparent !important;
      }

      &:nth-child(n+5) {
        opacity: 0;
        top: 52px;
        z-index: 900002;
        transform: translateX(-50%) scale(0.64);
        color: transparent !important;
      }
    }
  }

  .toast-enter-from {
    opacity: 0 !important;
    transform: translateX(-50%) translateY(20px);
  }

  .toast-leave-to {
    opacity: 0 !important;
    transform: translateX(-50%) translateY(-20px);
  }
</style>
