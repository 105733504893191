<script lang="ts" setup>
  import { ref, onMounted, type PropType, type Ref, onUpdated, onBeforeUpdate, onBeforeUnmount, computed } from 'vue';
  import type { Photo } from '@plenny/picture';
  import type { NavButtons } from '$types';
  import type { SwiperOptions } from 'swiper/types';
  import Swiper from 'swiper';
  import { Navigation, Pagination } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import { useBreakpoints } from '$storefront';

  type Item = {
    files: string[];
    id: number;
    link: string;
    photos: Photo[];
  }

  const props = defineProps({
    items: { type: Array as PropType<Item[]>, required: true },
    parameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
  });

  const breakpoints = useBreakpoints();

  const photoType = computed(() => {
    return breakpoints.value.lg ? 'background_desktop' : 'background_mobile';
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlPagination = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;

  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      ...props.parameters,
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: htmlPagination.value,
        clickable: true,
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel baner-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container baner-carousel__container">
      <div class="swiper-wrapper">
        <div v-for="item in items" class="swiper-slide items-carousel__slide baner-carousel__slide">
          <VisitorLink :href="item.link || '#'" class="baner-carousel__link">
          <MediaPicture :photo="item.photos.filter(photo => photo.type === photoType)" :type="photoType" class="baner-carousel__media"  sizes="2800x900 2x min 992, 1400x450 min 992, 1944x852 2x, 972x426" />
          </VisitorLink>
          <div class="swiper-lazy-preloader" />
        </div>
      </div>
      <div ref="htmlPagination" class="swiper-pagination" />
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style scoped lang="scss">
  .baner-carousel__link {
    width: 100%;

    :deep(picture) img {
      object-fit: cover;
      aspect-ratio: 1400/450;
    }
  }

  @media(max-width: 992px) {
    .baner-carousel__link {
      :deep(picture) img {
        height: 100%;
        aspect-ratio: 300/130;
      }
    }
  }
</style>

