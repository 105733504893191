<script setup>
  import { route } from '@plenny/visitor';
  import { computed } from 'vue';

  const props = defineProps({
    product: Object,
  });

  const target = computed(() => {
    return route('web.product.show', { product: props.product, variant: props.product.variant_id });
  });
</script>
<template>
  <div v-if="product" class="product-wrapper" @click="$redirect(target)">
    <div class="image-wrapper">
      <MediaPicture :photo="product.photo" sizes="450x340 2x, 225x170" />
    </div>
    <div class="content-wrapper">
      <div>
        <p class="title">{{ product.title }}</p>
        <div v-if="product.description_short" class="description" v-html="product.description_short" />
      </div>
      <div class="action-container">
        <div class="price-wrapper">
          <SfProductPrice :price="product" />
        </div>
        <SfWishlistButton :variant="product.variant_id" class="wishlist-btn" />
        <SfCartButton :product="product">
          <SfButton>
            <template #default>
              {{ $t('Dodaj do koszyka') }}
            </template>
            <template #after>
              <IconCart class="icon-cart" />
            </template>
          </SfButton>
        </SfCartButton>
      </div>
      <SfProductOmnibus :price="product" class="special-product-omnibus" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    column-gap: 0.625rem;
    border: 1px solid var(--color-primary-green);
    border-radius: 0.375rem;
    height: 100%;
    padding: 1.5rem;
    cursor: pointer;

    .image-wrapper {
      padding: 0.5rem;
      height: 200px;
      width: 200px;
      flex-shrink: 0;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .special-product-omnibus {
        margin-top: 0.125rem;
      }

      .title {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
      }

      .description {
        font-size: 0.875rem;
        color: var(--color-gray);
        padding: 0.5rem 0;
      }

      .action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 0.5rem;

        .wishlist-btn :deep(svg), svg {
          font-size: 1.125rem;
        }
      }
    }

    .price-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 1rem;
      left: -1.25rem;
      background-color: var(--color-white);
      border: 1px solid var(--primary-color);
      border-radius: 1.5rem;
      padding: 0.25rem 1.5rem;
    }
  }

  @media(max-width: 992px) {
    .product-wrapper {
      flex-direction: column;
      padding: 1rem;

      .content-wrapper {
        gap: 0.625rem;

        .action-container {
          flex-wrap: wrap;
          justify-content: center;
        }
      }

      .price-wrapper {
        position: static;
      }

      .wishlist-btn {
        position: absolute;
        display: block;
        top: 0.625rem;
        right: 0.625rem;
      }
    }
  }

  @media(max-width: 576px) {
    .product-wrapper .content-wrapper .action-container {
      gap: 0.625rem;
    }
  }
</style>
