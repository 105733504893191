import { useSession } from '@plenny/visitor';
import { computed } from 'vue';
import { Features } from '$features';

export function useCustomerOrderAbility() {
  const session = useSession();

  return computed(() => {
    let user = session.value.user;

    if (user) {
      if (Features.forceAuthenticatedAndAccepted.isEnabled()) {
        return (
          !!user.email_verified_at &&
          !!user.accepted_at
        );
      } else {
        return true;
      }
    }

    return Features.ordersByGuests.isEnabled();
  });
}
