<script setup lang="ts">
  import { ref, computed, type PropType, watch } from 'vue';
  import { $post, route } from '@plenny/visitor';
  import { useVariantStock } from '$storefront';
  import { closestMultipleOf } from '$support';

  type Variant = {
    id: number,
    variant_id: number,
    stock_on_order: boolean,
    stock_available: string,
    stock_unit_base: string,
  }

  const emit = defineEmits([
    'added',
  ]);

  const props = defineProps({
    variant: { type: Object as PropType<Variant>, required: false },
  });

  const amount = ref(Number(props.variant?.stock_unit_base || '1.0000'));
  const error = ref(null);
  const loading = ref(false);

  const variant = computed(() => {
    return props.variant;
  });

  const { available, amountAvailable } = useVariantStock(variant);

  const stockUnitBase = computed(() => {
    if (props.variant) {
      return props.variant.stock_unit_base;
    } else {
      return '1.0000';
    }
  });

  const isDisabled = computed(() => {
    return !available.value;
  });

  function actionAddToCart() {
    if (props.variant) {
      loading.value = true;
      error.value = null;

      $post(route('api.v1.web.cart.item.store'), {
        variant: props.variant.variant_id || props.variant.id,
        amount: amount.value,
      }).then((res) => {
        emit('added', res);
      }).catch((err) => {
        error.value = err.errors.amount[0];
      }).finally(() => {
        loading.value = false;
      });
    }
  }

  function findClosesMultipleOf() {
    amount.value = closestMultipleOf(Number(amount.value), Number(props.variant?.stock_unit_base || '1.0000'));
  }

  watch(() => props.variant, (variant) => {
    amount.value = Number(variant?.stock_unit_base || '1.0000');
  });
</script>
<template>
  <div class="cart-store-button">
    <div class="cart-store-button__button">
      <SfFormNumber
        :disabled="isDisabled"
        :min="stockUnitBase"
        :step="stockUnitBase"
        :max="amountAvailable"
        v-model="amount"
        @change="findClosesMultipleOf"
        required
      />
      <SfButton class="btn" primary :loading="loading" :disabled="isDisabled" @click="actionAddToCart">
        <template #before v-if="$slots.before">
          <slot name="before" />
        </template>
        <template #default>
          {{ variant ? $t('Dodaj do koszyka') : $t('Wybierz opcje') }}
        </template>
        <template #after v-if="$slots.after">
          <slot name="after" />
        </template>
      </SfButton>
    </div>
    <div class="cart-store-button__error">
      {{ error }}
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .cart-store-button {
    display: flex;
    flex-direction: column;

    &__button {
      display: flex;
      gap: 1rem;

      .control__wrapper {
        flex: 0 1;
      }

      .btn {
        flex: 1 0;
      }
    }

    &__error {
      color: var(--sf-input-error-color);
      font-size: var(--sf-input-error-font-size);
      padding: var(--sf-input-error-padding-y) var(--sf-input-error-padding-x);
      scroll-margin-top: var(--sf-input-error-scroll-margin-top);
      scroll-margin-bottom: var(--sf-input-error-scroll-margin-bottom);
    }
  }

  @include media.query(md) {
    .cart-store-button__button {
      flex-direction: column;
    }
  }
</style>
