const implicitCountries = ['PL'];

export function useCountryAddresses() {
  return {
    isAddressImplicit(code: string | null | undefined) {
      if (code) {
        return implicitCountries.includes(code);
      } else {
        return false;
      }
    },
  };
}
