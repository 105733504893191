<script setup lang="ts">
  import { watch, ref } from 'vue';
  import { $get, route, $patch, useVisitorForm } from '@plenny/visitor';

  type OrderBillingForm = {
    id: number;
    shipping_option_id: number;
    billing_method_id: number;
    billing_method_option_id: string;
  }

  type BillingOption = {
    id: string;
    billing_method_id: number;
    icon: string;
    name: string;
  }

  const props = defineProps({
    offer: { type: Boolean, required: true },
  });

  const { data } = useVisitorForm<OrderBillingForm>();

  const loading = ref(true);
  const options = ref([] as BillingOption[]);

  function onChoose(method: number, option: string) {
    $patch(route('api.v1.web.order.billing.update', { order: data.value.id, offer: props.offer }), { method, option }).then((res) => {
      data.value = { ...data.value, ...res };
    });
  }

  watch(() => data.value.shipping_option_id, (value) => {
    if (value) {
      loading.value = true;

      $get(route('api.v1.web.order.billing.show', { order: data.value.id, offer: props.offer })).then((res) => {
        options.value = res;
        loading.value = false;
      });
    } else {
      options.value = [];
      loading.value = false;
    }
  }, {
    immediate: true,
  });
</script>
<template>
  <SfDataLoader v-if="data.shipping_option_id" :loading="loading">
    <div class="billing-method-form" v-if="options.length > 0">
      <SfListCard
        interactive
        class="billing-method-form__option"
        v-for="option in options"
        @click="onChoose(option.billing_method_id, option.id)"
        @keydown.space="onChoose(option.billing_method_id, option.id)"
        :selected="data.billing_method_id === option.billing_method_id && data.billing_method_option_id === option.id"
        tabindex="0"
        role="button"
      >
        <header class="billing-method-form__header">
          <img :src="option.icon" :alt="option.name" class="billing-method-form__image" />
          <div class="billing-method-form__name-container">
            <h3 class="billing-method-form__name">{{ option.name }}</h3>
          </div>
        </header>
      </SfListCard>
    </div>
  </SfDataLoader>
  <SfDataPlaceholder v-else>
    {{ $t('Musisz najpierw wybrać opcję dostawy.') }}
  </SfDataPlaceholder>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .billing-method-form {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: var(--form-gap-y) var(--form-gap-x);

    &__option {
      display: flex;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      height: 100%;
    }

    &__image {
      flex: 0 0 auto;
      width: 100%;
      object-position: center;
      object-fit: scale-down;
      aspect-ratio: 4/3;
    }

    &__name-container {
      flex: 1 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__name {
      font-weight: var(--font-weight-regular);
      font-size: 0.75rem;
      text-align: center;
    }

    @include media.query(md) {
      grid-template-columns: repeat(4, 1fr);
      gap: 0.75rem;
    }

    @include media.query(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
