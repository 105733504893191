<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 42.104 42.104">
    <path fill="currentColor"
      d="M30,44.1H16.1a4.156,4.156,0,0,1-2.947-1.263L3.263,32.946A4.156,4.156,0,0,1,2,30V16.1a4.156,4.156,0,0,1,1.263-2.947l9.894-9.894A4.156,4.156,0,0,1,16.1,2H30a4.156,4.156,0,0,1,2.947,1.263l9.894,9.894A4.156,4.156,0,0,1,44.1,16.1V30a4.156,4.156,0,0,1-1.263,2.947l-9.894,9.894A4.156,4.156,0,0,1,30,44.1ZM16.1,6.21,6.21,16.1V30L16.1,39.893H30L39.893,30V16.1L30,6.21Z"
      transform="translate(-2 -2)" />
    <path fill="currentColor"
      d="M1.444,17.488A1.443,1.443,0,0,1,.42,17.142a1.443,1.443,0,0,1,0-2.034L12.667,2.86a1.443,1.443,0,0,1,2.106,1.962L2.454,17.142A1.443,1.443,0,0,1,1.444,17.488Z"
      transform="translate(13 11.559)" />
    <path fill="currentColor"
    d="M15.132,16.891a1.443,1.443,0,0,1-1.01-.418L1.875,4.225A1.443,1.443,0,0,1,3.909,2.191l12.32,12.247a1.443,1.443,0,0,1,.067,2.039q-.032.035-.067.067a1.443,1.443,0,0,1-1.1.346Z"
    transform="translate(11.544 12.156)" />
</svg></template>