<script setup lang="ts">
  import type { PropType } from 'vue';
  import { useBreakpoints } from '$storefront';

  type Affiliate = {
    order_spent_points: number;
    order_per_currency_points: number;
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    url: { type: String, required: false },
    affiliate: { type: Object as PropType<Affiliate>, required: false },
  });

  const breakpoints = useBreakpoints();
</script>

<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Program partnerski') }}
  </AccountMobileNavigation>
  <SfTile v-if="affiliate" large class="account-affiliate">
    <template #title v-if="breakpoints.lg">
      <h1 v-if="breakpoints.lg" class="account-heading account-affiliate__heading">
        {{ $t('Program partnerski') }}
      </h1>
    </template>
    <template #default>
      <section class="account-affiliate__section">
        <p class="account-affiliate__text">{{ $t('Aby korzystać z programu partnerskiego musisz posługiwać się podanym linkiem:') }}</p>
        <span class="account-affiliate__url">{{ url }}</span>
      </section>
      <section class="account-affiliate__section">
        <p class="account-affiliate__text">{{ $t('Benefity wynikające z programu partnerskiego') }}</p>
        <table class="account-affiliate__table">
          <tbody class="account-affiliate__table-body">
            <tr class="account-affiliate__table-body-row">
              <th scope="row" class="account-affiliate__table-body-row">{{ $t('Punkty lojalnościowe za złożone zamówienie przez partnera') }}</th>
              <td class="account-affiliate__table-body-desc">{{ affiliate.order_spent_points }}</td>
            </tr>
            <tr class="account-affiliate__table-body-row">
              <th scope="row" class="account-affiliate__table-body-row">{{ $t('Punkty lojalnościowe za każdą wydaną jednostkę waluty') }}</th>
              <td class="account-affiliate__table-body-desc">{{ affiliate.order_per_currency_points }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </template>
  </SfTile>
  <SfTile v-else large class="account-affiliate">
    <template #title v-if="breakpoints.lg">
      <h1 v-if="breakpoints.lg" class="account-heading account-affiliate__heading">
        {{ $t('Program partnerski') }}
      </h1>
    </template>
    <template #default>
      <section class="account-affiliate__section">
        <p class="account-affiliate__text">
          {{ $t('Obecnie, nie bierzesz udziału w programie partnerskim. Jeśli chcesz dołączyć do programu i zdobywać dodatkowe punkty i rabaty w naszym sklepie skontaktuj się z nami.') }}
        </p>
      </section>
      <section class="account-affiliate__section">
        <SfButton primary :href="route('web.contact.index')">
          {{ $t('Kontakt') }}
        </SfButton>
      </section>
    </template>
  </SfTile>
</template>
<style lang="scss">
  .account-affiliate {

    &__section {
      margin-bottom: 0.75rem;
    }

    &__text {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    &__url {
      font-weight: var(--font-weight-bold);
    }

    &__table {
      border-collapse: collapse;
      border: 1px solid var(--sf-account-color-black);
      margin-top: 0.625rem;
    }

    &__table-body-row {
      background-color: var(--sf-account-color-light-background);
      padding: 0.5rem;
      text-align: left;
      border: 1px solid var(--sf-account-color-black);
    }

    &__table-body-desc {
      padding: 0.5rem;
    }
  }
</style>
