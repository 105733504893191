<script setup lang="ts">
  const open = defineModel('open', { type: Boolean, required: false, default: false });
</script>

<template>
  <header class="modal__header">
    <h2>
      <slot />
    </h2>
    <SfButton @click="open = false" square rounded>
      <SfIconClose />
    </SfButton>
  </header>
</template>

<style lang="scss">
  :root {
    --sf-modal-header-gap: 1rem;
    --sf-modal-header-align-items: flex-start;
    --sf-modal-header-font-family: var(--font-family-sans);
    --sf-modal-header-font-size: 1.5rem;
    --sf-modal-header-color: var(--color-black);
    --sf-modal-header-padding-y: 0;
    --sf-modal-header-padding-x: 0;
    --sf-modal-header-background: transparent;
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: var(--sf-modal-header-align-items);
    gap: var(--sf-modal-header-gap);
    font-family: var(--sf-modal-header-font-family);
    color: var(--sf-modal-header-color);
    background-color: var(--sf-modal-header-background);
    padding: var(--sf-modal-header-padding-y) var(--sf-modal-header-padding-x);

    h2 {
      font-size: var(--sf-modal-header-font-size);
    }
  }
</style>
