<script lang="ts" setup>
  import { trans } from '@plenny/translator';
  import { computed, type PropType } from 'vue';

  const props = defineProps({
    status: { type: String as PropType<'NEW' | 'PROCESSING' | 'ACCEPTED' | 'REJECTED'>, required: true },
  });

  const statusMap = {
    NEW: { label: trans('Nowe'), class: 'offer-status-label--new' },
    PROCESSING: { label: trans('Przetwarzanie'), class: 'offer-status-label--processing' },
    REJECTED: { label: trans('Odrzucone'), class: 'offer-status-label--rejected' },
    ACCEPTED: { label: trans('Zaakceptowane'), class: 'offer-status-label--accepted' },
    CANCELLED: { label: trans('Anulowane'), class: 'offer-status-label--cancelled' },
    COMPLETED: { label: trans('Zakończone'), class: 'offer-status-label--completed' },
  };

  const statusClass = computed(() => `${statusMap[props.status].class}`);
  const statusLabel = computed(() => statusMap[props.status].label);
</script>
<template>
  <span class="offer-status-label" :class="statusClass">
    {{ statusLabel }}
  </span>
</template>
<style lang="scss">
  :root {
    --order-status-label-color: var(--color-default);
    --order-status-label-background: var(--color-secondary);
    --order-status-label-radius: 0.625rem;
    --order-status-label-font-size: 0.75rem;
    --order-status-label-padding-y: 0.25rem;
    --order-status-label-padding-x: 0.75rem;

    --order-status-label-color-info: var(--sf-status-color-info);
    --order-status-label-background-info: var(--sf-status-background-info);
    --order-status-label-color-danger: var(--sf-status-color-danger);
    --order-status-label-background-danger: var(--sf-status-background-danger);
    --order-status-label-color-warning: var(--sf-status-color-warning);
    --order-status-label-background-warning: var(--sf-status-background-warning);
    --order-status-label-color-severe: var(--sf-status-color-severe);
    --order-status-label-background-severe: var(--sf-status-background-severe);
    --order-status-label-color-success: var(--sf-status-color-success);
    --order-status-label-background-success: var(--sf-status-background-success);
  }

  .offer-status-label {
    display: inline-block;
    background-color: var(--order-status-label-background);
    color: var(--order-status-label-color);
    border-radius: var(--order-status-label-radius);
    font-size: var(--order-status-label-font-size);
    padding: var(--order-status-label-padding-y) var(--order-status-label-padding-x);
    font-weight: var(--font-weight-bold);

    &--rejected,
    &--cancelled {
      background: var(--order-status-label-background-danger);
      color: var(--order-status-label-color-danger);
    }

    &--accepted,
    &--completed {
      background: var(--order-status-label-background-success);
      color: var(--order-status-label-color-success);
    }

    &--new,
    &--processing {
      background: var(--order-status-label-background-info);
      color: var(--order-status-label-color-info);
    }
  }
</style>
