<script setup lang="ts">
  import { computed, watchEffect } from 'vue';
  import { useToasts } from '@plenny/visitor';
  import { SfIconInfo, SfIconSuccess, SfIconWarning, SfIconError } from '$components';

  const { toasts, remove } = useToasts();

  const props = defineProps({
    id: { type: String, required: true },
    description: { type: String, required: true },
    kind: { type: String, required: false, default: 'info' },
    duration: { type: Number, required: true },
  });

  const icon = computed(() => {
    if (props.kind === 'info') {
      return SfIconInfo;
    } else if (props.kind === 'success') {
      return SfIconSuccess;
    } else if (props.kind === 'warning') {
      return SfIconWarning;
    } else if (props.kind === 'severe') {
      return SfIconWarning;
    } else if (props.kind === 'danger') {
      return SfIconError;
    } else {
      return SfIconInfo;
    }
  });

  watchEffect(() => {
    if (toasts.value[0]?.id === props.id) {
      setTimeout(() => remove(props.id), props.duration * 1000);
    }
  });
</script>
<template>
  <div class="toast" :class="['toast--' + kind]">
    <span class="toast__icon">
      <component :is="icon" />
    </span>
    <span class="toast__content">{{ description }}</span>
    <span class="toast__icon toast__close" @click="remove(props.id)">
      <SfIconClose />
    </span>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-toast-background: rgb(255, 255, 255);
    --sf-toast-color: rgb(0, 0, 0);
    --sf-toast-font-size: 0.875rem;
    --sf-toast-line-height: 1.625rem;
    --sf-toast-gap: 0.75rem;
    --sf-toast-max-width: 480px;
    --sf-toast-border-radius: 1.25rem;
    --sf-toast-padding-y: 0.375rem;
    --sf-toast-padding-x: 0.75rem;

    --sf-toast-icon-size: 1.625rem;
    --sf-toast-close-size: 0.75rem;

    --sf-toast-info-shadow: 0 67 119;
    --sf-toast-info-background: var(--sf-status-background-info);
    --sf-toast-info-color: var(--sf-status-color-info);

    --sf-toast-success-shadow: 14 112 14;
    --sf-toast-success-background: var(--sf-status-background-success);
    --sf-toast-success-color: var(--sf-status-color-success);

    --sf-toast-warning-shadow: 129 116 0;
    --sf-toast-warning-background: var(--sf-status-background-warning);
    --sf-toast-warning-color: var(--sf-status-color-warning);

    --sf-toast-danger-shadow: 188 47 50;
    --sf-toast-danger-background: var(--sf-status-background-danger);
    --sf-toast-danger-color: var(--sf-status-color-danger);

    --sf-toast-severe-shadow: 196 53 1;
    --sf-toast-severe-background: var(--sf-status-color-severe);
    --sf-toast-severe-color: var(--sf-status-background-severe);
  }

  .toast {
    --toast-background: var(--sf-toast-background);
    --toast-color: var(--sf-toast-color);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--sf-toast-gap);
    padding: var(--sf-toast-padding-y) var(--sf-toast-padding-x);
    width: 100%;
    max-width: var(--sf-toast-max-width);
    background-color: var(--toast-background);
    color: var(--toast-color);
    box-shadow: rgb(var(--toast-shadow) / 0.30) 0 0 2px, rgb(var(--toast-shadow) / 0.5) 0 8px 16px;
    overflow: hidden;
    border-radius: var(--sf-toast-border-radius);
    font-size: var(--sf-toast-font-size);
    line-height: var(--sf-toast-line-height);

    &--info {
      --toast-shadow: var(--sf-toast-info-shadow);
      --toast-background: var(--sf-toast-info-background);
      --toast-color: var(--sf-toast-info-color);
    }

    &--success {
      --toast-shadow: var(--sf-toast-success-shadow);
      --toast-background: var(--sf-toast-success-background);
      --toast-color: var(--sf-toast-success-color);
    }

    &--warning {
      --toast-shadow: var(--sf-toast-warning-shadow);
      --toast-background: var(--sf-toast-warning-background);
      --toast-color: var(--sf-toast-warning-color);
    }

    &--danger {
      --toast-shadow: var(--sf-toast-danger-shadow);
      --toast-background: var(--sf-toast-danger-background);
      --toast-color: var(--sf-toast-danger-color);
    }

    &--severe {
      --toast-shadow: var(--sf-toast-severe-shadow);
      --toast-background: var(--sf-toast-severe-background);
      --toast-color: var(--sf-toast-severe-color);
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 var(--sf-toast-icon-size);
      width: var(--sf-toast-icon-size);
      height: var(--sf-toast-icon-size);
      font-size: var(--sf-toast-icon-size);
    }

    &__content {
      flex: 1;
    }

    &__close {
      color: var(--toast-color);
      font-size: var(--sf-toast-close-size);
      transition: color 150ms cubic-bezier(0.33, 0, 0.67, 1);

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
