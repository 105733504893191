<script setup lang="ts">
  import { ref } from 'vue';
  import { route, $delete, $refresh } from '@plenny/visitor';
  import { useCustomerOrderAbility, useCustomerAccepted } from '$storefront';

  const props = defineProps({
    variant: {
      type: Object,
      required: true,
    },
  });

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const successModalVisible = ref(false);

  function onProductAdded() {
    successModalVisible.value = true;
  }

  function destroy() {
    $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.variant.variant_id })).then(() => $refresh());
  }
</script>
<template>
  <div class="comparator-product">
    <SfButton class="comparator-product__delete" :aria-label="$t('Usuń produkt z porównywarki.')" rounded square transparent danger @click="destroy">
      <template #after>
        <SfIconClose />
      </template>
    </SfButton>
    <VisitorLink :href="route('web.product.show', { slug: variant.slug })" class="comparator-product__link">
      <div class="comparator-product__media">
        <div class="comparator-product__image">
          <MediaPicture :photo="variant.photo" sizes="240x240 2x, 120x120" />
        </div>
        <h2 class="comparator-product__title">
          {{ variant.title }}
        </h2>
      </div>
    </VisitorLink>
    <div v-if="canCustomerOrder">
      <div class="comparator-product__price">
        <SfProductPrice :price="variant" />
        <SfWishlistButton :variant="variant.variant_id" />
      </div>
      <SfProductOmnibus :price="variant" />
      <SfCartStoreButton :variant="variant" @added="onProductAdded" class="comparator-product__add-to-cart" />
      <CartButtonSuccessModal :open="successModalVisible" :product="variant" :variant="variant" @update:open="successModalVisible = $event" />
    </div>
    <div v-else>
      <div class="comparator-product__price__guest">
        <VisitorLink v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" rel="nofollow">
          {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
        </VisitorLink>
        <span v-else>
          {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .comparator-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;

    &__delete.btn {
      position: absolute;
      top: 5px;
      right: 5px;

      svg {
        font-size: 1rem;
      }
    }

    &__add-to-cart .cart-store-button__button {
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1rem;
    }

    &__link {
      font-size: 1rem;
      font-weight: var(--font-weight-regular);
    }

    &__media {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__image {
      display: grid;
      height: 90px;
      grid-template-rows: 1fr;
      transition: all 300ms ease-in-out;

      picture {
        overflow: hidden;

        img {
          height: 100%;
        }
      }
    }

    &__title {
      font-size: 1rem;
      font-weight: var(--font-weight-semi);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }

    &__price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      &__guest {
        color: var(--secondary-color);
        font-size: 0.75rem;
      }
    }

    &.scroll {
      .comparator-product__media .comparator-product__image {
        height: 0;
        grid-template-rows: 0fr;
      }

      .comparator-product__delete {
        display: none;
      }
    }
  }
</style>
