<script setup lang="ts">
  import { ref, onMounted, computed } from 'vue';
  import { $get, route, useVisitorForm } from '@plenny/visitor';

  type Point = {
    id: number;
    name: string | null;
    display_name: string | null;
    first_line: string | null;
    second_line: string | null;
    third_line: string | null;
    email: string | null;
    phone: string | null;
  }

  defineOptions({
    inheritAttrs: false,
  });

  const { data, set } = useVisitorForm();

  const loading = ref(true);
  const points = ref([] as Point[]);

  const current = computed(() => {
    return Number(data.value.shipping_point_id);
  });

  onMounted(() => {
    $get(route('api.v1.web.cart.address.depot.show')).then((res: Point[]) => {
      points.value = res;
      loading.value = false;

      if (!data.value.shipping_point_id && points.value.length > 0) {
        actionSelect(points.value[0]);
      }
    });
  });

  function actionSelect(point: Point) {
    set('shipping_point_id', point.id);
    set('shipping_point_name', point.name || point.display_name);
  }
</script>
<template>
  <div class="collect">
    <div class="collect__section">
      <div class="collect__header">
        <h3 class="collect__heading">{{ $t('Wybierz punkt odbioru') }}</h3>
      </div>
      <div class="collect__points">
        <SfListCard
          v-for="point in points" class="collect__point"
          :selected="current === point.id"
          shadow
          small
          interactive
          @click="actionSelect(point)"
          @keydown.space="actionSelect(point)"
          tabindex="0"
          role="button"
        >
          <template #default>
            <address class="collect__address">
              <p v-if="point.name || point.display_name" class="collect__point-name"><b>{{ point.name || point.display_name }}</b></p>
              <p v-if="point.first_line" class="collect__point-first-line">{{ point.first_line }}</p>
              <p v-if="point.second_line" class="collect__point-second-line">{{ point.second_line }}</p>
              <p v-if="point.third_line" class="collect__point-third-line">{{ point.third_line }}</p>
              <p v-if="point.email" class="collect__point-email">{{ point.email }}</p>
              <p v-if="point.phone" class="collect__point-phone">{{ point.phone }}</p>
            </address>
          </template>
        </SfListCard>
      </div>
    </div>

    <div class="collect__section">
      <div class="collect__header">
        <h3 class="collect__heading">{{ $t('Podaj dane odbiorcy') }}</h3>
      </div>
      <div class="collect__form">
        <div class="collect__form-input">
          <SfFormInput type="text" :label="$t('Imię i nazwisko')" name="shipping_name" required />
        </div>
        <div class="collect__form-input">
          <SfFormInput type="tel" :label="$t('Telefon')" name="shipping_phone" required />
        </div>
        <div class="collect__form-input">
          <SfFormInput type="email" :label="$t('E-mail')" name="shipping_email" required />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .collect {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__points {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__address {
      font-style: normal;
      font-size: 0.875rem;
    }

    &__point-name {
      margin-bottom: 0.5rem;
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: var(--form-gap-y) var(--form-gap-x);
      max-width: 672px;
    }
  }

  @include media.query(lg) {
    .collect {
      &__points {
        gap: 1rem;
      }

      &__heading {
        font-size: 1.25rem;
      }
    }
  }

  @include media.query(md) {
    .collect__points {
      grid-template-columns: 1fr;
    }
  }
</style>
