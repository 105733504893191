<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { VisitorLink } from '@plenny/visitor';

  const props = defineProps({
    href: { type: String, required: false },
    to: { type: String, required: false },
    target: { type: String, required: false },
    type: { type: String, required: false, default: 'button' },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },

    square: { type: Boolean, required: false, default: false },
    rounded: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },

    primary: { type: Boolean, required: false, default: false },
    transparent: { type: Boolean, required: false, default: false },
    danger: { type: Boolean, required: false, default: false },
  });

  const htmlElement = ref();

  const component = computed(() => {
    if (props.href) {
      return VisitorLink;
    } else if (props.to) {
      return 'a';
    } else {
      return 'button';
    }
  });

  const buttonClasses = computed(() => {
    return {
      'btn--square': props.square,
      'btn--rounded': props.rounded,
      'btn--small': props.small,
      'btn--large': props.large,
      'btn--primary': props.primary,
      'btn--transparent': props.transparent,
      'btn--danger': props.danger,
      'btn--loading': props.loading,
      'btn--disabled': props.disabled,
    };
  });

  const spinnerProps = computed(() => {
    return {
      primary: props.primary,
      small: props.small,
      large: props.large,
    };
  });

  const specific = computed(() => {
    if (props.href) {
      return { href: props.href, target: props.target };
    } else if (props.to) {
      return { href: props.to, target: props.target };
    } else {
      return { type: props.type };
    }
  });

  const disabled = computed(() => {
    if (component.value === 'button') {
      return props.disabled;
    } else {
      return undefined;
    }
  });

  defineExpose({ htmlElement, disabled });
</script>
<template>
  <component :is="component" ref="htmlElement" :class="buttonClasses" class="btn" v-bind="{ ...specific, disabled }">
    <span v-if="$slots.before" class="btn__before">
      <slot name="before" />
    </span>
    <span v-if="$slots.default" class="btn__inner">
      <slot />
    </span>
    <span v-if="$slots.after" class="btn__after">
      <slot name="after" />
    </span>
    <span v-if="loading" class="btn__spinner-wrapper">
      <SfSpinner v-bind="{ ...spinnerProps }" />
    </span>
  </component>
</template>

<style lang="scss">
  :root {
    --sf-btn-foreground: var(--secondary-foreground);
    --sf-btn-foreground-hover: var(--secondary-foreground-hover);
    --sf-btn-foreground-active: var(--secondary-foreground-active);
    --sf-btn-foreground-focus: var(--secondary-foreground-focus);
    --sf-btn-foreground-disabled: var(--secondary-foreground-disabled);
    --sf-btn-foreground-loading: var(--sf-btn-foreground);
    --sf-btn-background: var(--secondary-background);
    --sf-btn-background-hover: var(--secondary-background-hover);
    --sf-btn-background-active: var(--secondary-background-active);
    --sf-btn-background-focus: var(--secondary-background-focus);
    --sf-btn-background-disabled: var(--secondary-background-disabled);
    --sf-btn-background-loading: var(--sf-btn-background);
    --sf-btn-border: var(--secondary-border);
    --sf-btn-border-hover: var(--secondary-border-hover);
    --sf-btn-border-active: var(--secondary-border-active);
    --sf-btn-border-focus: var(--secondary-border-focus);
    --sf-btn-border-disabled: var(--secondary-border-disabled);
    --sf-btn-border-loading: var(--sf-btn-border);
    --sf-btn-border-width: 1px;
    --sf-btn-border-style: solid;
    --sf-btn-border-radius: 0;
    --sf-btn-border-radius-sm: 0;
    --sf-btn-border-radius-lg: 0;
    --sf-btn-border-radius-rounded: 0;
    --sf-btn-border-radius-rounded-sm: 0;
    --sf-btn-border-radius-rounded-lg: 0;
    --sf-btn-box-shadow: none;
    --sf-btn-box-shadow-hover: none;
    --sf-btn-box-shadow-active: none;
    --sf-btn-box-shadow-focus: none;
    --sf-btn-box-shadow-disabled: none;
    --sf-btn-box-shadow-loading: none;

    --sf-btn-primary-background: var(--primary-background);
    --sf-btn-primary-background-hover: var(--primary-background-hover);
    --sf-btn-primary-background-active: var(--primary-background-active);
    --sf-btn-primary-background-focus: var(--primary-background-focus);
    --sf-btn-primary-background-disabled: var(--primary-background-disabled);
    --sf-btn-primary-background-loading: var(--sf-btn-primary-background);
    --sf-btn-primary-foreground: var(--primary-foreground);
    --sf-btn-primary-foreground-hover: var(--primary-foreground-hover);
    --sf-btn-primary-foreground-active: var(--primary-foreground-active);
    --sf-btn-primary-foreground-focus: var(--primary-foreground-focus);
    --sf-btn-primary-foreground-disabled: var(--primary-foreground-disabled);
    --sf-btn-primary-foreground-loading: var(--sf-btn-primary-foreground);
    --sf-btn-primary-border: var(--primary-border);
    --sf-btn-primary-border-hover: var(--primary-border-hover);
    --sf-btn-primary-border-active: var(--primary-border-active);
    --sf-btn-primary-border-focus: var(--primary-border-focus);
    --sf-btn-primary-border-disabled: var(--primary-border-disabled);
    --sf-btn-primary-border-loading: var(--sf-btn-primary-border);
    --sf-btn-primary-box-shadow: none;
    --sf-btn-primary-box-shadow-hover: none;
    --sf-btn-primary-box-shadow-active: none;
    --sf-btn-primary-box-shadow-focus: none;
    --sf-btn-primary-box-shadow-disabled: none;
    --sf-btn-primary-box-shadow-loading: var(--sf-btn-primary-box-shadow);

    --sf-btn-danger-foreground: var(--danger-foreground);
    --sf-btn-danger-foreground-hover: var(--danger-foreground-hover);
    --sf-btn-danger-foreground-active: var(--danger-foreground-active);
    --sf-btn-danger-foreground-focus: var(--danger-foreground-focus);
    --sf-btn-danger-foreground-disabled: var(--danger-foreground-disabled);
    --sf-btn-danger-foreground-loading: var(--sf-btn-danger-foreground);
    --sf-btn-danger-background: var(--danger-background);
    --sf-btn-danger-background-hover: var(--danger-background-hover);
    --sf-btn-danger-background-active: var(--danger-background-active);
    --sf-btn-danger-background-focus: var(--danger-background-focus);
    --sf-btn-danger-background-disabled: var(--danger-background-disabled);
    --sf-btn-danger-background-loading: var(--sf-btn-danger-background);
    --sf-btn-danger-border: var(--sf-btn-danger-background);
    --sf-btn-danger-border-hover: var(--sf-btn-danger-background-hover);
    --sf-btn-danger-border-active: var(--sf-btn-danger-background-active);
    --sf-btn-danger-border-focus: var(--sf-btn-danger-background-focus);
    --sf-btn-danger-border-disabled: var(--sf-btn-danger-background-disabled);
    --sf-btn-danger-border-loading: var(--sf-btn-danger-background-loading);
    --sf-btn-danger-box-shadow: none;
    --sf-btn-danger-box-shadow-hover: none;
    --sf-btn-danger-box-shadow-active: none;
    --sf-btn-danger-box-shadow-focus: none;
    --sf-btn-danger-box-shadow-disabled: none;
    --sf-btn-danger-box-shadow-loading: none;

    --sf-btn-transparent-background: transparent;
    --sf-btn-transparent-background-hover: transparent;
    --sf-btn-transparent-background-active: transparent;
    --sf-btn-transparent-background-focus: transparent;
    --sf-btn-transparent-background-disabled: transparent;
    --sf-btn-transparent-background-loading: var(--secondary-background);
    --sf-btn-transparent-foreground: var(--secondary-color);
    --sf-btn-transparent-foreground-hover: var(--secondary-color-hover);
    --sf-btn-transparent-foreground-active: var(--secondary-color-active);
    --sf-btn-transparent-foreground-focus: var(--secondary-color-focus);
    --sf-btn-transparent-foreground-disabled: var(--secondary-color-disabled);
    --sf-btn-transparent-foreground-loading: var(--sf-btn-transparent-foreground);
    --sf-btn-transparent-border: transparent;
    --sf-btn-transparent-border-hover: transparent;
    --sf-btn-transparent-border-active: transparent;
    --sf-btn-transparent-border-focus: transparent;
    --sf-btn-transparent-border-disabled: transparent;
    --sf-btn-transparent-border-loading: transparent;
    --sf-btn-transparent-box-shadow: none;
    --sf-btn-transparent-box-shadow-hover: none;
    --sf-btn-transparent-box-shadow-active: none;
    --sf-btn-transparent-box-shadow-focus: none;
    --sf-btn-transparent-box-shadow-disabled: none;
    --sf-btn-transparent-box-shadow-loading: none;
    --sf-btn-transparent-danger-foreground: var(--danger-color);
    --sf-btn-transparent-danger-foreground-hover: var(--danger-color-hover);
    --sf-btn-transparent-danger-foreground-active: var(--danger-color-active);
    --sf-btn-transparent-danger-foreground-focus: var(--danger-color-focus);
    --sf-btn-transparent-danger-foreground-disabled: var(--danger-color-disabled);
    --sf-btn-transparent-danger-foreground-loading: var(--sf-btn-transparent-danger-foreground);

    --sf-btn-font-family: var(--font-family-sans);
    --sf-btn-font-weight: 500;
    --sf-btn-font-weight-sm: 500;
    --sf-btn-font-weight-lg: 600;
    --sf-btn-font-size: 1rem;
    --sf-btn-font-size-sm: 0.875rem;
    --sf-btn-font-size-lg: 1.125rem;
    --sf-btn-font-size-icon: 1.25rem;
    --sf-btn-font-size-icon-sm: 1rem;
    --sf-btn-font-size-icon-lg: 1.5rem;
    --sf-btn-line-height: 1.5rem;
    --sf-btn-line-height-sm: 1.5rem;
    --sf-btn-line-height-lg: 1.5rem;

    --sf-btn-padding-y: 0.625rem;
    --sf-btn-padding-y-sm: 0.375rem;
    --sf-btn-padding-y-lg: 1rem;

    --sf-btn-padding-x: 1.25rem;
    --sf-btn-padding-x-sm: 0.75rem;
    --sf-btn-padding-x-lg: 2rem;

    --sf-btn-gap: 0.375rem;
    --sf-btn-gap-sm: 0.5rem;
    --sf-btn-gap-lg: 0.625rem;
    --sf-btn-transition-timing-function: ease-in;
    --sf-btn-transition-duration: 150ms;

    --sf-btn-justify-content: center;

    --sf-btn-outline-color-focus: rgb(0, 19, 121);
  }

  .btn {
    position: relative;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: var(--sf-btn-justify-content);
    align-items: center;
    min-width: 0;
    padding: var(--sf-btn-padding-y) var(--sf-btn-padding-x);
    gap: var(--sf-btn-gap);
    font-family: var(--sf-btn-font-family);
    font-weight: var(--sf-btn-font-weight);
    font-size: var(--sf-btn-font-size);
    line-height: var(--sf-btn-line-height);
    cursor: pointer;
    vertical-align: middle;
    color: var(--sf-btn-foreground);
    background-color: var(--sf-btn-background);
    border-color: var(--sf-btn-border);
    border-width: var(--sf-btn-border-width);
    border-style: var(--sf-btn-border-style);
    border-radius: var(--sf-btn-border-radius);
    box-shadow: var(--sf-btn-box-shadow);
    transition-timing-function: var(--sf-btn-transition-timing-function);
    transition-property: background-color, border, color, box-shadow, opacity;
    transition-duration: var(--sf-btn-transition-duration);

    &--primary {
      --sf-btn-foreground: var(--sf-btn-primary-foreground);
      --sf-btn-foreground-hover: var(--sf-btn-primary-foreground-hover);
      --sf-btn-foreground-active: var(--sf-btn-primary-foreground-active);
      --sf-btn-foreground-focus: var(--sf-btn-primary-foreground-focus);
      --sf-btn-foreground-disabled: var(--sf-btn-primary-foreground-disabled);
      --sf-btn-foreground-loading: var(--sf-btn-primary-foreground-loading);
      --sf-btn-background: var(--sf-btn-primary-background);
      --sf-btn-background-hover: var(--sf-btn-primary-background-hover);
      --sf-btn-background-active: var(--sf-btn-primary-background-active);
      --sf-btn-background-focus: var(--sf-btn-primary-background-focus);
      --sf-btn-background-disabled: var(--sf-btn-primary-background-disabled);
      --sf-btn-background-loading: var(--sf-btn-primary-background-loading);
      --sf-btn-border: var(--sf-btn-primary-border);
      --sf-btn-border-hover: var(--sf-btn-primary-border-hover);
      --sf-btn-border-active: var(--sf-btn-primary-border-active);
      --sf-btn-border-focus: var(--sf-btn-primary-border-focus);
      --sf-btn-border-disabled: var(--sf-btn-primary-border-disabled);
      --sf-btn-border-loading: var(--sf-btn-primary-border-loading);
      --sf-btn-box-shadow: none;
      --sf-btn-box-shadow-hover: none;
      --sf-btn-box-shadow-active: none;
      --sf-btn-box-shadow-focus: none;
      --sf-btn-box-shadow-disabled: none;
      --sf-btn-box-shadow-loading: none;
    }

    &--danger {
      --sf-btn-foreground: var(--sf-btn-danger-foreground);
      --sf-btn-foreground-hover: var(--sf-btn-danger-foreground-hover);
      --sf-btn-foreground-active: var(--sf-btn-danger-foreground-active);
      --sf-btn-foreground-focus: var(--sf-btn-danger-foreground-focus);
      --sf-btn-foreground-disabled: var(--sf-btn-danger-foreground-disabled);
      --sf-btn-foreground-loading: var(--sf-btn-danger-foreground-loading);
      --sf-btn-background: var(--sf-btn-danger-background);
      --sf-btn-background-hover: var(--sf-btn-danger-background-hover);
      --sf-btn-background-active: var(--sf-btn-danger-background-active);
      --sf-btn-background-focus: var(--sf-btn-danger-background-focus);
      --sf-btn-background-disabled: var(--sf-btn-danger-background-disabled);
      --sf-btn-background-loading: var(--sf-btn-danger-background-loading);
      --sf-btn-border: var(--sf-btn-danger-border);
      --sf-btn-border-hover: var(--sf-btn-danger-border-hover);
      --sf-btn-border-active: var(--sf-btn-danger-border-active);
      --sf-btn-border-focus: var(--sf-btn-danger-border-focus);
      --sf-btn-border-disabled: var(--sf-btn-danger-border-disabled);
      --sf-btn-border-loading: var(--sf-btn-danger-border-loading);
      --sf-btn-box-shadow: none;
      --sf-btn-box-shadow-hover: none;
      --sf-btn-box-shadow-active: none;
      --sf-btn-box-shadow-focus: none;
      --sf-btn-box-shadow-disabled: none;
      --sf-btn-box-shadow-loading: none;
    }

    &--transparent {
      --sf-btn-foreground: var(--sf-btn-transparent-foreground);
      --sf-btn-foreground-hover: var(--sf-btn-transparent-foreground-hover);
      --sf-btn-foreground-active: var(--sf-btn-transparent-foreground-active);
      --sf-btn-foreground-focus: var(--sf-btn-transparent-foreground-focus);
      --sf-btn-foreground-disabled: var(--sf-btn-transparent-foreground-disabled);
      --sf-btn-foreground-loading: var(--sf-btn-transparent-foreground-loading);
      --sf-btn-background: var(--sf-btn-transparent-background);
      --sf-btn-background-hover: var(--sf-btn-transparent-background-hover);
      --sf-btn-background-active: var(--sf-btn-transparent-background-active);
      --sf-btn-background-focus: var(--sf-btn-transparent-background-focus);
      --sf-btn-background-disabled: var(--sf-btn-transparent-background-disabled);
      --sf-btn-background-loading: var(--sf-btn-transparent-background-loading);
      --sf-btn-border: var(--sf-btn-transparent-border);
      --sf-btn-border-hover: var(--sf-btn-transparent-border-hover);
      --sf-btn-border-active: var(--sf-btn-transparent-border-active);
      --sf-btn-border-focus: var(--sf-btn-transparent-border-focus);
      --sf-btn-border-disabled: var(--sf-btn-transparent-border-disabled);
      --sf-btn-border-loading: var(--sf-btn-transparent-border-loading);

      &.btn--danger {
        --sf-btn-foreground: var(--sf-btn-transparent-danger-foreground);
        --sf-btn-foreground-hover: var(--sf-btn-transparent-danger-foreground-hover);
        --sf-btn-foreground-active: var(--sf-btn-transparent-danger-foreground-active);
        --sf-btn-foreground-focus: var(--sf-btn-transparent-danger-foreground-focus);
        --sf-btn-foreground-disabled: var(--sf-btn-transparent-danger-foreground-disabled);
        --sf-btn-foreground-loading: var(--sf-btn-transparent-danger-foreground-loading);
      }
    }

    &--small {
      --sf-btn-gap: var(--sf-btn-gap-sm);
      --sf-btn-font-size: var(--sf-btn-font-size-sm);
      --sf-btn-font-size-icon: var(--sf-btn-font-size-icon-sm);
      --sf-btn-font-weight: var(--sf-btn-font-weight-sm);
      --sf-btn-line-height: var(--sf-btn-line-height-sm);
      --sf-btn-padding-y: var(--sf-btn-padding-y-sm);
      --sf-btn-padding-x: var(--sf-btn-padding-x-sm);
      --sf-btn-border-radius: var(--sf-btn-border-radius-sm);
      --sf-btn-border-radius-rounded: var(--sf-btn-border-radius-rounded-sm);
    }

    &--large {
      --sf-btn-gap: var(--sf-btn-gap-lg);
      --sf-btn-font-size: var(--sf-btn-font-size-lg);
      --sf-btn-font-size-icon: var(--sf-btn-font-size-icon-lg);
      --sf-btn-font-weight: var(--sf-btn-font-weight-lg);
      --sf-btn-line-height: var(--sf-btn-line-height-lg);
      --sf-btn-padding-y: var(--sf-btn-padding-y-lg);
      --sf-btn-padding-x: var(--sf-btn-padding-x-lg);
      --sf-btn-border-radius: var(--sf-btn-border-radius-lg);
      --sf-btn-border-radius-rounded: var(--sf-btn-border-radius-rounded-lg);
    }

    &--square {
      padding: var(--sf-btn-padding-y);

      .btn__inner {
        display: inline-flex;
      }
    }

    &--rounded {
      --sf-btn-border-radius: var(--sf-btn-border-radius-rounded);
    }

    &--loading {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--sf-btn-foreground-loading);
      border-color: var(--sf-btn-border-loading);
      background-color: var(--sf-btn-background-loading);
      box-shadow: var(--sf-btn-box-shadow-loading);
    }

    &:active {
      color: var(--sf-btn-foreground-active);
      border-color: var(--sf-btn-border-active);
      background-color: var(--sf-btn-background-active);
      box-shadow: var(--sf-btn-box-shadow-active);
    }

    &:focus-visible {
      color: var(--sf-btn-foreground-focus);
      border-color: var(--sf-btn-border-focus);
      background-color: var(--sf-btn-background-focus);
      box-shadow: var(--sf-btn-box-shadow-focus);
      outline: 2px solid var(--sf-btn-outline-color-focus);
      outline-offset: 4px;
    }

    &:hover {
      color: var(--sf-btn-foreground-hover);
      border-color: var(--sf-btn-border-hover);
      background-color: var(--sf-btn-background-hover);
      box-shadow: var(--sf-btn-box-shadow-hover);
    }

    &:disabled, &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--sf-btn-foreground-disabled);
      border-color: var(--sf-btn-border-disabled);
      background-color: var(--sf-btn-background-disabled);
      box-shadow: var(--sf-btn-box-shadow-disabled);
    }

    &__before {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: var(--sf-btn-font-size-icon);
      }
    }

    &__after {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: var(--sf-btn-font-size-icon);
      }
    }

    &__inner {
      white-space: nowrap;
    }

    &__spinner-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: var(--sf-btn-background-loading);
      border-radius: var(--sf-btn-border-radius);

      .spinner {
        --sf-spinner-color: var(--sf-btn-foreground-loading);
      }
    }
  }
</style>
