<script lang="ts" setup>
  import { VisitorLink, useShared } from '@plenny/visitor';

  defineProps({
    header: { type: String, required: true },
    content: { type: String, required: true },
    link: { type: String },
  });

  const shared = useShared();
</script>

<template>
  <div class="wide">
    <Section :title="header" class="container" square>
      <div class="contact-wrapper">
        <div class="contact-wrapper__left">
          <img alt="APE Logo" class="contact-wrapper__logo" src="../../../../assets/images/logo.png">
          <div class="contact-wrapper__content" v-html="content" />

          <SfButton v-if="link" :href="link" class="contact-wrapper__more">{{ $t('Więcej') }}</SfButton>
        </div>
        <div class="contact-wrapper__right">
          <Newsletter />
          <div class="contact-wrapper__media">
            <a :href="`callto:${shared.department.phone}`" class="contact-wrapper__row contact-wrapper__row--bold">
              <IconPhone />
              {{ shared.department.phone }}</a>

            <a :href="`mailto:${shared.department.email}`" class="contact-wrapper__row">
              <IconEnvelope />
              {{ shared.department.email }}</a>
          </div>
          <VisitorLink :href="route('web.contact.index')" class="contact-wrapper__link">{{ $t('Kontakt') }}</VisitorLink>
        </div>
      </div>
    </Section>
  </div>
</template>

<style lang="scss" scoped>
  .wide {
    background: var(--color-white);

    .section {
      margin-top: 0;
    }
  }

  .contact-wrapper {
    display: grid;
    grid-template-columns: 1fr 480px;
    gap: 7rem;
    width: 90%;
    margin: 0 auto;

    @media (max-width: 1200px) {
      gap: 4rem;
      width: 100%;
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    &__left {
      display: flex;
      flex-direction: column;

      h2 {
        color: var(--primary-color);
      }

      .orange-text {
        color: var(--primary-color);
      }
    }

    &__more {
      align-self: flex-end;
      text-transform: uppercase;
      font-weight: bold;
    }

    &__logo {
      height: 86px;
      width: 262px;
    }

    &__right {
      border: 1px solid var(--color-primary-black);
      border-radius: 20px;
      padding: 2.5rem 2.5rem 2rem 2.5rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-self: start;

      @media (max-width: 992px) {
        padding: 1.5rem 1.5rem 1rem 1.5rem;
      }

      @media (max-width: 576px) {
        padding: 1.25rem;
        gap: 1rem;
      }
    }

    &__content {
      margin-left: 8rem;
      letter-spacing: 0.32px;
      line-height: 26px;

      @media (max-width: 1200px) {
        margin-left: 3rem;
      }

      @media (max-width: 992px) {
        margin-left: 0;
      }

      @media (max-width: 576px) {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }

    &__media {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media (max-width: 576px) {
        gap: 0.5rem;
      }
    }

    &__row {
      display: flex;
      align-items: center;
      gap: 1rem;

      &--bold {
        font-weight: bold;
      }

      svg {
        font-size: 1.125rem;
      }
    }

    &__link {
      text-transform: uppercase;
      font-weight: 600;
      color: var(--primary-color);
    }
  }
</style>
