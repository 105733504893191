<script lang="ts" setup>
  import { type PropType, computed } from 'vue';

  type Page = {
    meta_url: string,
  }

  const props = defineProps({
    page: { type: Object as PropType<Page>, required: true },
  });

  const facebook = computed(() => `https://www.facebook.com/share.php?u=${props.page.meta_url}`);
  const twitter = computed(() => `https://twitter.com/intent/tweet?url=${props.page.meta_url}`);
  const linkedIn = computed(() => `https://www.linkedin.com/sharing/share-offsite/?url=${props.page.meta_url}`);
</script>
<template>
  <div class="share">
    <div class="share__label">
      {{ $t('Udostępnij produkt na') + ':' }}
    </div>
    <div class="share__icons">
      <VisitorLink :href="facebook" class="facebook-share" target="_blank">
        <SfIconFacebook />
      </VisitorLink>
      <VisitorLink :href="twitter" class="twitter-share" target="_blank">
        <SfIconTwitter />
      </VisitorLink>
      <VisitorLink :href="linkedIn" class="linkedin-share" target="_blank">
        <SfIconLinkedIn />
      </VisitorLink>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .share {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__label {
      font-weight: var(--font-weight-semi);
      font-size: 0.875rem;
    }

    &__icons {
      display: flex;
      font-size: 1rem;

      .facebook-share,
      .twitter-share,
      .linkedin-share {
        display: flex;
        align-items: center;
        padding: 0.5rem;
      }
    }
  }
</style>
