<script setup>

  const props = defineProps({
    post: { type: Object, required: true },
    horizontal: { type: Boolean, default: false },
    header: {
      type: String,
      default: 'h3',
    },
  });

  const articleCardClasses = {
    'article-card--horizontal': props.horizontal,
  };

  const photoSizes = props.horizontal ? '200x200 2x, 100x100' : '';

</script>
<template>
  <VisitorLink :href="route('web.blog.post.show', { post })">
    <div :class="articleCardClasses" class="article-card">
      <div class="article-card__photo">
        <MediaPicture :photo="post.photo" :sizes="photoSizes" />
      </div>
      <section class="article-card__content">
        <component :is="header" :title="post.title" class="article-card__title">{{ post.title }}</component>
        <div v-if="!horizontal" class="article-card__description" v-html="post.description_short" />
        <div class="article-card__bottom">
          <div>
            <SfDateTime :value="post.date" class="article-card__date" />
          </div>
          <span v-if="!horizontal" class="article-card__more">{{ $t('Czytaj') }}</span>
        </div>
      </section>
    </div>
  </VisitorLink>
</template>
<style lang="scss" scoped>
  .article-card {
    background: var(--color-white);
    border-radius: 20px;
    border: 1px solid var(--color-medium-gray);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    height: 100%;
    max-height: 500px;
    max-width: 270px;

    &__photo {
      min-height: 205px;

      :deep(picture) {
        img {
          border-radius: 6px;
          object-fit: cover;
        }
      }
    }

    &__title {
      font-size: 1rem;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 42px;
    }

    &__content {
      padding: 1rem 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;
      width: 100%;
      height: 100%;
    }

    &__description {
      font-size: 0.875rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__date {
      font-size: 0.875rem;
      color: var(--color-gray);
    }

    &__more {
      color: var(--primary-color);
      font-weight: 600;
      text-transform: uppercase;
    }

    &--horizontal {
      flex-direction: row;
      align-items: flex-start;
      border: none;

      .article-card__photo {
        min-height: unset;
        flex: 0 0 70px;
      }

      .article-card__title {
        -webkit-line-clamp: 3;
        height: 52px;
        font-size: 0.875rem;
        line-height: 18px;
      }

      .article-card__content {
        padding: 0 0.5rem;
        gap: 0.25rem;
      }
    }
  }
</style>
