<script setup lang="ts">
  const open = defineModel('open', { type: Boolean, required: false, default: false });
</script>

<template>
  <header class="drawer__header">
    <h2>
      <slot />
    </h2>
    <SfButton @click="open = false" square rounded :aria-label="$t('Zamknij')">
      <SfIconClose />
    </SfButton>
  </header>
</template>

<style lang="scss">
  :root {
    --sf-drawer-header-gap: 1rem;
    --sf-drawer-header-align-items: flex-start;
    --sf-drawer-header-font-family: var(--font-family-sans);
    --sf-drawer-header-font-size: 1.5rem;
    --sf-drawer-header-line-height: 1.5;
    --sf-drawer-header-color: var(--color-black);
    --sf-drawer-header-padding-y: 0;
    --sf-drawer-header-padding-x: 0;
    --sf-drawer-header-background: transparent;
  }

  .drawer__header {
    display: flex;
    justify-content: space-between;
    align-items: var(--sf-drawer-header-align-items);
    gap: var(--sf-drawer-header-gap);
    font-family: var(--sf-drawer-header-font-family);
    color: var(--sf-drawer-header-color);
    background-color: var(--sf-drawer-header-background);
    padding: var(--sf-drawer-header-padding-y) var(--sf-drawer-header-padding-x);

    h2 {
      font-size: var(--sf-drawer-header-font-size);
      line-height: var(--sf-drawer-header-line-height);
    }
  }
</style>
