<script setup lang="ts">
  import { ref, nextTick, type PropType } from 'vue';
  import { $refresh } from '@plenny/visitor';
  import { Unit } from '$types';

  interface OrderItem {
    id: number,
    variant_id: number,
    photo: string | null,
    variant_title: string,
    variant_sku: string,
    currency_code: string,
    currency_decimals: number,
    regular_gross: string,
    price_gross: string,
    stock_unit: Unit,
    amount: string,
    review_url: string | undefined,
  }

  interface Product {
    id: number,
    variant_id: number,
    photo: string | null,
    title: string,
    sku: string,
  }

  defineProps({
    item: { type: Object as PropType<OrderItem>, required: true },
  });

  const product = ref<Product>();
  const url = ref<string>();
  const open = ref(false);

  function sendReview(item: OrderItem) {
    product.value = {
      id: item.id,
      variant_id: item.variant_id,
      photo: item.photo,
      title: item.variant_title,
      sku: item.variant_sku,
    };

    url.value = item.review_url;

    nextTick(() => {
      open.value = true;
    });
  }
</script>
<template>
  <SfListCard class="line" large>
    <template #image>
      <MediaPicture :photo="item.photo" sizes="140x140 2x, 70x70" />
    </template>
    <template #default>
      <div class="line__content">
        <h2 class="line__heading" v-html="item.variant_title" />
        <p class="line__sku" v-html="$t('SKU: :code', { code: item.variant_sku })" />
      </div>
    </template>
    <template #actions>
      <div class="line__pricing">
        <div v-if="item.regular_gross !== item.price_gross" class="line__regular">
          <SfMoney :code="item.currency_code" :decimals="item.currency_decimals" :value="item.regular_gross" />
        </div>
        <div class="line__price">
          <SfUnit :unit="item.stock_unit" :quantity="item.amount" />
          &times;
          <SfMoney :code="item.currency_code" :decimals="item.currency_decimals" :value="item.price_gross" />
        </div>
      </div>
      <SfButton v-if="item.review_url" primary small @click="sendReview(item)">
        {{ $t('Oceń produkt') }}
      </SfButton>
    </template>
  </SfListCard>
  <ReviewFormModal
    v-if="product"
    :product="product"
    :url="url"
    v-model:open="open"
    @reviewed="$refresh()"
  />
</template>
<style scoped lang="scss">
  @use "$assets/mixins/media";

  .line {
    &__heading {
      font-weight: var(--font-weight-bold);
      font-size: 1rem;
    }

    &__sku {
      font-size: 0.75rem;
      color: var(--secondary-color);
    }

    &__regular {
      color: var(--danger-color);
      font-size: 0.875rem;
      text-decoration: line-through;
    }

    &__price {
      white-space: nowrap;
    }

    :deep(.list-card__image) {
      flex: 0 0 70px;

      @include media.query(md) {
        flex: 0 0 45px;
      }

      picture, img {
        display: flex;
        aspect-ratio: 1/1;
      }
    }
  }
</style>
