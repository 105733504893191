<template>
  <svg height="1em" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor"
      d="m506.607 104.396-99.996-100.002c-2.813-2.814-6.629-4.394-10.607-4.394h-230.004c-24.813 0-45 20.187-45 45v197.838c-67.406 7.486-120 64.793-120 134.162 0 74.439 60.561 135 135 135h330c24.813 0 45-20.187 45-45v-351.997c0-3.979-1.58-7.793-4.393-10.607zm-95.607-53.183 32.59 32.591 16.196 16.196h-48.786zm-380 325.787c0-57.897 47.103-105 105-105s105 47.103 105 105-47.103 105-105 105-105-47.103-105-105zm435 105h-245.251c26.963-21.805 45.389-53.759 49.414-90h155.837c8.284 0 15-6.716 15-15s-6.716-15-15-15h-155.838c-2.446-22.02-10.204-42.459-21.968-60h177.806c8.284 0 15-6.716 15-15s-6.716-15-15-15h-205.251c-19.521-15.786-43.511-26.248-69.749-29.162v-197.838c0-8.271 6.729-15 15-15h215v85c0 8.284 6.716 15 15 15h85v337c0 8.271-6.729 15-15 15z">
    </path>
    <path fill="currentColor"
      d="m426 182h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
    <path fill="currentColor"
      d="m195.606 339.394c-5.857-5.857-15.355-5.857-21.213 0l-59.393 59.393-19.394-19.394c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l30 30c2.929 2.929 6.768 4.394 10.607 4.394s7.678-1.465 10.606-4.394l70-70c5.858-5.857 5.858-15.355 0-21.212z">
    </path>
  </svg>
</template>