<script lang="ts" setup>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
  import { $get, route, useQuery, $redirect } from '@plenny/visitor';
  import { useScrollDetector, useBreakpoints } from '$storefront';

  defineProps({
    placeholder: { type: String, required: false },
    searchButtonText: { type: String, required: false },
  });

  const results = ref();
  const search = ref('');
  const isFocused = ref(false);
  const loading = ref(true);
  const searchHtml = ref();

  const query = useQuery();
  const breakpoints = useBreakpoints();

  let timeout;

  watch(search, (query) => {
    loading.value = true;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      $get(route('api.v1.web.product.search', { query })).then((res) => {
        results.value = res;
        loading.value = false;
      });
    }, 250);
  });

  useScrollDetector({
    threshold: 8,
    callback: ({ down }) => {
      if (down && !breakpoints.value.lg) {
        search.value = '';
      }
    },
  });

  function target(product: any) {
    return route('web.product.show', { slug: product.slug });
  }

  function onClickOutside(event: MouseEvent) {
    if (searchHtml.value && !searchHtml.value.contains(event.target as Node)) {
      isFocused.value = false;
    }
  }

  onMounted(() => {
    document.addEventListener('click', onClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', onClickOutside);
  });

  function onFocus() {
    isFocused.value = true;
  }

  function onFocusOut() {
    isFocused.value = false;
  }

  function onRedirect() {
    $redirect(route('web.product.index', { search: search.value }));
  }

  function onSearchClick() {
    onFocusOut();
    onRedirect();
  }

  watch(() => query.value.search, (value) => {
    if (value) {
      search.value = value;
    } else {
      search.value = '';
    }
  });

</script>

<template>
  <div ref="searchHtml" :class="{ 'search--open': isFocused && search?.length > 0 }" class="search">
    <transition name="fade">
      <div v-if="isFocused && search?.length > 0" class="search__backdrop" @click.stop="onFocusOut" />
    </transition>
    <div class="search__content">
      <input
        v-model="search"
        :placeholder="placeholder"
        class="search__input"
        name="search"
        type="search"
        @focus="onFocus"
      />
      <div class="search__icons">
        <transition name="fade">
          <SfButton v-if="search?.length > 0" class="search__action-btn" small transparent @click="search = ''">
            <template #after>
              <SfIconClean />
            </template>
          </SfButton>
        </transition>

        <SfButton :disabled="search?.length <= 0" class="search__action-btn" small transparent @click="onSearchClick">
          <template #after>
            <span v-if="searchButtonText" class="search__text">{{ searchButtonText }}</span>
            <SfIconSearch v-else />
          </template>
        </SfButton>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isFocused && search?.length > 0" class="search__results-wrapper" @focusout="onFocusOut" @wheel.prevent @touchmove.prevent>
        <template v-if="!loading">
          <div v-if="results?.data?.length > 0" class="search__results" @focusout.stop="void 0">
            <VisitorLink
              v-for="product in results.data"
              :key="product.id"
              :href="target(product)"
            >
              <div class="search__product" @click="onFocusOut">
                <div class="search__product-left">
                  <MediaPicture :photo="product.photo" class="search__product-img" sizes="60x60" />
                  <span class="search__product-title">{{ product.title }}</span>
                </div>
                <SfProductPrice :price="product" current />
              </div>
            </VisitorLink>
          </div>
          <SfDataPlaceholder v-else>{{ $t('Niestety nic nie znaleźliśmy.') }}</SfDataPlaceholder>
          <div class="search__buttons">
            <!--button propagates focus out by default, we want to block focus out on 1st button, otherwise it will close the modal automatically-->
            <SfButton class="search__button" primary @click="onSearchClick" @focusout.stop>{{ $t('Pokaż wszystkie produkty') }}</SfButton>
            <SfButton class="search__button" @click="onFocusOut">{{ $t('Anuluj') }}</SfButton>
          </div>
        </template>
        <SfSpinner v-else class="search__spinner" />
      </div>
    </transition>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-search-padding: 0.75rem 7rem 0.75rem 1rem;

    --sf-search-border-style: var(--sf-input-border-style);
    --sf-search-border-bottom-width: 1px;
    --sf-search-border-top-width: 1px;
    --sf-search-border-right-width: 1px;
    --sf-search-border-left-width: 1px;
    --sf-search-border-color: var(--secondary-border);
    --sf-search-border-color-active: var(--primary-color);
    --sf-search-border-radius: 0;

    --sf-search-icons-border-left-width: 0;
    --sf-search-icons-border-right-width: 0;
    --sf-search-icons-border-bottom-width: 0;
    --sf-search-icons-border-top-width: 0;

    --sf-search-icons-padding: 0 0.5rem 0 0.5rem;
    --sf-search-icons-background: transparent;
    --sf-search-icons-color: var(--color-default);
    --sf-search-icons-color-hover: var(--primary-color);

    --sf-search-results-wrapper-background: white;
    --sf-search-results-wrapper-padding: 3.5rem 1rem 1rem 1rem;

    --sf-search-results-gap: 1rem;
    --sf-search-results-padding: 1rem 0 0;

    --sf-search-title-font-size: 0.875rem;

    --sf-search-spinner-padding: 1rem 0 0 0;

    --sf-search-product-left-gap: 0.5rem;

    --sf-search-buttons-margin: 1rem 0 0 0;
    --sf-search-buttons-gap: 1rem;

    --sf-serach-product-gap: 0.5rem;

    --sf-search-text-font-size: 1rem;
    --sf-search-text-decoration: normal;
    --sf-search-text-weight: var(--font-weight-regular);
    --sf-search-text-letter-spacing: auto;
  }

  .search {
    position: relative;
    width: 100%;

    &--open {
      @media (max-width: 992px) {
        position: absolute;
        padding: 0 1rem;
        left: 0;
        right: 0;
      }
    }

    &__text {
      text-transform: var(--sf-search-text-decoration);
      font-weight: var(--sf-search-text-weight);
      letter-spacing: var(--sf-search-text-letter-spacing);
      color: var(--sf-search-icons-color);
      transition: var(--primary-transition);

      &:hover {
        color: var(--sf-search-icons-color-hover);
      }
    }

    &__backdrop {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--backdrop-default);
      z-index: 9999;

      @media (max-width: 992px) {
        background: var(--sf-search-results-wrapper-background);
      }
    }

    &__content {
      width: 100%;
      position: relative;
      z-index: 99999;

      @include media.query(lg) {
      }
    }

    &__icons {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      padding: var(--sf-search-icons-padding);
      background: var(--sf-search-icons-background);
      height: 100%;
      border-bottom-right-radius: var(--sf-search-border-radius);
      border-top-right-radius: var(--sf-search-border-radius);
      border-top: 1px var(--sf-search-border-style) var(--sf-search-icons-background);
      border-right: 1px var(--sf-search-border-style) var(--sf-search-icons-background);
      border-bottom: 1px var(--sf-search-border-style) var(--sf-search-icons-background);
      border-left: 1px var(--sf-search-border-style) var(--sf-search-border-color);
    }

    &__action-btn {
      svg {
        transition: var(--primary-transition);
        color: var(--sf-search-icons-color);
      }

      &:hover {
        svg {
          color: var(--sf-search-icons-color-hover);
        }
      }
    }

    &__input {
      width: 100%;
      padding: var(--sf-search-padding);
      border-style: var(--sf-search-border-style);
      border-bottom-width: var(--sf-search-border-bottom-width);
      border-top-width: var(--sf-search-border-top-width);
      border-right-width: var(--sf-search-border-right-width);
      border-left-width: var(--sf-search-border-left-width);
      border-color: var(--sf-search-border-color);
      border-radius: var(--sf-search-border-radius);

      &:focus, &:active {
        border-color: var(--sf-search-border-color-active);
        outline: 2px solid var(--sf-search-border-color-active);
      }

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    &__results-wrapper {
      position: absolute;
      top: -1rem;
      left: -1rem;
      right: -1rem;
      padding: var(--sf-search-results-wrapper-padding);
      background: var(--sf-search-results-wrapper-background);
      box-shadow: var(--secondary-box-shadow-100);
      border-radius: var(--sf-search-border-radius);
      z-index: 9999;

      @media (max-width: 992px) {
        left: 0rem;
        right: 0rem;
        box-shadow: none;
        height: 100vh;
      }
    }

    &__results {
      display: flex;
      flex-direction: column;
      gap: var(--sf-search-results-gap);
      padding: var(--sf-search-results-padding);
    }

    &__spinner {
      padding: var(--sf-search-spinner-padding);
    }

    &__product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--sf-serach-product-gap);
    }

    &__product-left {
      display: flex;
      align-items: center;
      gap: var(--sf-search-product-left-gap);
    }

    &__product-img {
      min-width: 60px;
    }

    &__product-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      font-size: var(--sf-search-title-font-size);
    }

    &__buttons {
      display: flex;
      gap: var(--sf-search-buttons-gap);
      margin: var(--sf-search-buttons-margin);

      button, a {
        flex: 1;
      }

      @include media.query(lg) {
        flex-direction: column;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  // 01010010 01101111 01100100 01111010 01111001 01101110 00100000 01101101 01111001 11000101 10011011 01101100 01101001 00100000 11000101 10111100 01100101 00100000 01101001 01101110 01110110 01101001 01110100 01110010 01101111 00100000 01110100 01101111 00100000 01110000 01101001 01111010 01111010 01100001
</style>
