<script setup lang="ts">
  import type { PropType } from 'vue';
  import { $post, route } from '@plenny/visitor';

  interface Product {
    id: number,
    variant_id: number,
    photo: string | null,
    title: string,
    sku: string | null,
  }

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const emit = defineEmits({
    reviewed: () => void 0,
  });

  const props = defineProps({
    product: { type: Object as PropType<Product>, required: true },
    url: { type: String, required: false },
  });

  async function onSubmit(data) {
    let url = props.url || route('api.v1.web.customer.review.store');
    let response = await $post(url, { product_id: props.product.id, ...data });

    open.value = false;
    emit('reviewed');

    return response;
  }
</script>
<template>
  <SfModal v-model:open="open">
    <VisitorForm @submit="onSubmit" class="review">
      <SfModalHeader v-model:open="open">
        {{ $t('Dodaj opinię') }}
      </SfModalHeader>
      <div class="review__main">
        <SfListCard>
          <template #image>
            <MediaPicture :photo="product.photo" sizes="140x140 2x, 70x70" />
          </template>
          <template #default>
            <div class="review__product">
              <h3 class="review__product__title">
                {{ product.title }}
              </h3>
              <p class="review__product__sku">
                SKU: {{ product.sku }}
              </p>
            </div>
          </template>
        </SfListCard>
        <SfFormStars :label="$t('Twoja ocena')" name="rating" required />
        <SfFormTextarea :label="$t('Twoja opinia')" name="comment" />
      </div>
      <SfModalFooter>
        <SfButton primary type="submit">
          {{ $t('Dodaj opinię') }}
        </SfButton>
      </SfModalFooter>
    </VisitorForm>
  </SfModal>
</template>
<style scoped lang="scss">
  .review {
    &__main {
      display: flex;
      flex-direction: column;
      gap: var(--form-gap-y);
      //--sf-form-stars-padding-x: 1px;
    }
  }
</style>
