import { transChoice, trans } from '@plenny/translator';
import { Unit } from '$types';
import { isFloat } from '../Math/IsFloat';

export const UnitFormatter = {
  symbol(type: Unit): string {
    return trans(`UNIT.${type}.SHORT`);
  },
  name(type: Unit): string {
    return transChoice(`UNIT.${type}.LONG`, 1);
  },
  short(type: Unit, value: string | number, options: Intl.NumberFormatOptions = {}): string {
    let formatted = createValueFormatter(options).format(Number(value));
    let key = `UNIT.${type}.SHORT`;

    return formatted + '&nbsp;' + trans(key);
  },
  long(type: Unit, value: string | number, options: Intl.NumberFormatOptions = {}): string {
    let formatted = createValueFormatter(options).format(Number(value));
    let key = `UNIT.${type}.LONG`;

    // Gdy wartość posiada miejsca po przecinku zazwyczaj używamy dopełniacza mówiąc na przykład "pół metra".
    if (isFloat(value)) {
      return UnitFormatter.genitive(type, value, options);
    }

    return formatted + '&nbsp;' + transChoice(key, Number(value));
  },
  genitive(type: Unit, value: string | number, options: Intl.NumberFormatOptions = {}): string {
    let formatted = createValueFormatter(options).format(Number(value));
    let key = `UNIT.${type}.LONG_GENITIVE`;

    if (isFloat(value)) {
      value = 1;
    }

    return formatted + '&nbsp;' + transChoice(key, Number(value));
  },
  accusative(type: Unit, value: string | number, options: Intl.NumberFormatOptions = {}): string {
    let formatted = createValueFormatter(options).format(Number(value));
    let key = `UNIT.${type}.LONG_ACCUSATIVE`;

    if (isFloat(value)) {
      return UnitFormatter.genitive(type, value, options);
    }

    return formatted + '&nbsp;' + transChoice(key, Number(value));
  },
};

function createValueFormatter(options: Intl.NumberFormatOptions = {}): Intl.NumberFormat {
  return new Intl.NumberFormat(__i18n_current, { style: 'decimal', maximumFractionDigits: 4, ...options });
}
