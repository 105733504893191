import { useSession } from '@plenny/visitor';
import { computed } from 'vue';

export function useCustomerAccepted() {
  const session = useSession();

  return computed(() => {
    let user = session.value.user;

    if (user) {
      return (
        !!user.email_verified_at &&
        !!user.accepted_at
      );
    }

    return undefined;
  });
}
