import { transChoice } from '@plenny/translator';

export function useRealizationTime({ realization_from: min, realization_to: max }) {
  if (min && max && (min !== max)) {
    return transChoice(':min-:max dzień roboczy|:min-:max dni robocze|:min-:max dni roboczych', max, { min, max });
  }

  if (max) {
    return transChoice(':max dzień roboczy|:max dni robocze|:max dni roboczych', max, { max });
  }

  return null;
}
