<script lang="ts" setup>
  const props = defineProps({
    node: { type: Object, required: true },
    open: { type: Boolean, required: true },
  });

  const openItemId = defineModel('openItemId', { type: Number });

  function show() {
    openItemId.value = props.node.id;
  }

</script>

<template>
  <li :class="{'item--open': open}"
    aria-controls="category-content"
    class="item"
    tabindex="0"
    @click.prevent.stop="show"
    @mouseenter="show"
    @keydown.enter.space.prevent="show"
  >
    <div class="item__title">
      <IconLightning />
      {{ node.title }}
    </div>
    <Subcategories v-if="open" id="category-content" :node  />
  </li>
</template>

<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    @media (max-width: 992px) {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      min-width: 140px;
      width: 100%;
    }

    @media (max-width: 576px) {
      font-size: 0.75rem;
      line-height: 1;
    }

    &__title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0 0.5rem 0.5rem;

      @media (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 1rem;
      }
    }

    &--open {
      background: var(--color-white);
      color: var(--primary-color);
    }
  }
</style>