import type { InjectionKey, App } from 'vue';
import { reactive } from 'vue';

export interface SettingsRegistry {
  // Use TS module augmentation to attach settings into this interface.
}

export const SettingsKey = Symbol('SettingsRegistry') as InjectionKey<SettingsRegistry>;

export const SettingsRegistry = {
  install(app: App) {
    const registry = reactive(Settings);

    app.provide(SettingsKey, registry);
    app.config.globalProperties.$settings = registry;
  },
};

declare global {
  var Settings: SettingsRegistry;
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $settings: SettingsRegistry;
  }
}
