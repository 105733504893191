<script lang="ts" setup>
  import { trans } from '@plenny/translator';

  defineProps({
    perView: { type: Number, default: 5 },
    node: { type: Object, required: true },
  });

  const parameters = {
    speed: 300,
    spaceBetween: 10,
    grid: {
      rows: 2,
    },
    breakpoints: {
      1: {
        slidesPerView: 1.25,
        grid: {
          rows: 1,
        },
      },
      360: {
        slidesPerView: 1.75,
        grid: {
          rows: 1,
        },
      },
      576: {
        slidesPerView: 3,
        grid: {
          rows: 1,
        },
      },
      766: {
        slidesPerView: 4,
        grid: {
          rows: 1,
        },
      },
      992: {
        slidesPerView: 2,
        grid: {
          rows: 2,
        },
      },
      1200: {
        slidesPerView: 3,
        grid: {
          rows: 2,
        },
      },
      1400: {
        slidesPerView: 4,
        grid: {
          rows: 2,
        },
      },
    },
  };

  function getRedirectTitle(category: string) {
    return trans('Sprawdź wszystkie produkty w: <span class="orange">:category</span>', { category });
  }

</script>
<template>
  <div class="categories-swiper ">
    <VisitorLink :href="route('web.product.category', { category: node })" class="categories-swiper__redirect">
      <span v-html="getRedirectTitle(node.title)" />
    </VisitorLink>
    <SubcategoriesCarousel v-if="node.children.length" :node :parameters />
    <SfDataPlaceholder v-else>
      {{$t('Brak podkategorii do wyświetlenia.')}}
    </SfDataPlaceholder>
  </div>
</template>
<style lang="scss" scoped>
  .categories-swiper {
    --sf-carousel-button-space: 0.375rem;

    overflow: hidden;
    width: 60%;
    height: 100%;
    position: absolute;
    left: 310px;
    top: 0;
    padding: 0.5rem 0 0 2rem;
    cursor: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (max-width: 1400px) {
      width: 50%;
    }

    @media (max-width: 1200px) {
      left: 278px;
      padding-left: 1rem;
    }

    @media (max-width: 992px) {
      left: 0;
      width: 100%;
      height: 200px;
      top: 110px;
    }

    @media (max-width: 576px) {
      padding: 0.75rem 0 0 0.5rem;
    }
  }
</style>

