<script setup>
  import { $patch, route } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const signUpEmail = ref(false);

  function onSubmit(data) {
    return $patch(route('api.v1.web.newsletter.update'), data).then(() => {
      signUpEmail.value = true;
    });
  }

  const subscribe = computed(() => {
    return trans('Zapisz się na <span>news</span>letter!');
  });

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <section class="newsletter">
    <template v-if="!signUpEmail">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit">
        <p class="newsletter__text" v-html="subscribe" />
        <div class="newsletter__content">
          <SfFormInput :label="$t('E-mail')" name="email" required tabindex="1" />
          <SfButton :loading="submitting" tabindex="3" transparent type="submit">
            {{ $t('Zapisz się') }}
          </SfButton>
        </div>
        <div class="newsletter__consent">
          <SfFormSwitch name="acceptance_rodo" required tabindex="2">
            <span v-html="privacyPolicy" />
          </SfFormSwitch>
        </div>
      </VisitorForm>
    </template>
    <template v-else>
      <p class="subscribed">{{ $t('Dziękujemy za zapisanie się do naszego newslettera') }}</p>
    </template>
  </section>
</template>
<style lang="scss" scoped>
  .newsletter {
    &__text {
      font-weight: 600;

      :deep(span) {
        color: var(--primary-color);
      }
    }

    &__content {
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid var(--color-primary-black);
      gap: 0.2rem;
      margin-top: 0.5rem;
      position: relative;

      :deep(.btn) {
        border-radius: 0;
        border: none;
        padding: 0.25rem 0.5rem;
      }

      :deep(.control) {
        border-radius: 0;
        border: none;
      }

      :deep(.control__label) {
        color: var(--primary-color);
      }

      :deep(.control__error) {
        position: absolute;
      }
    }

    &__consent {
      margin-top: 1rem;

      :deep(.switch__label) {
        font-size: 0.75rem;
        font-weight: normal;
      }
    }
  }
</style>

