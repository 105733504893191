<script lang="ts" setup>
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const productRequestContent = computed(() => {
    return trans('<span>Masz pytania?</span> <a href="mailto:hello@apepartner.com">Zapytaj o produkt</a>');
  });
</script>

<template>
  <div class="meta">
    <div class="meta__request" v-html="productRequestContent" />
    <div class="meta__shipment-info">
      <p>
        {{ $t('Darmowy zwrot do 30 dni') }}
      </p>
      <p>
        {{ $t('Bezpieczne połączenie SSL') }}
      </p>
    </div>
    <div class="meta__order-info">
      <div>
        <p>{{ $t('Akceptujemy') }}</p>
        <img :alt="$t('Akceptowane metody płatności: Blik, Visa, Mastercard, Przelewy 24, Przelew tradycyjny')" src="../../../assets/images/billing-methods.png">
      </div>
      <p>{{ $t('Sprawdź opcje dostawy i zwrotu') }}</p>
    </div>
  </div>
</template>

<style lang="scss">
  .meta {
    &__request {
      border-bottom: 1px solid var(--color-light-background);
      padding: 0.2rem 0;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;

      span {
        color: var(--primary-color);
        font-size: 0.875rem;
      }

      a {
        text-transform: uppercase;
        font-weight: bold;
      }

      @media (max-width: 1200px) {
        flex-direction: column;
        gap: 0.25rem;
      }
    }

    &__shipment-info {
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      margin-top: 0.25rem;
    }

    &__order-info {
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.375rem;

      img {
        width: 100%;
        margin-top: 0.2rem;
      }
    }
  }
</style>