<script setup lang="ts">
  import { computed, type PropType, ref, onBeforeUnmount, watch } from 'vue';
  import { useBreakpoints, useScrollWidth, useScrollDetector, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';

  interface Product {
    photo: string | null,
    is_new: boolean,
  }

  interface Variant {
    title: string,
    photo: string | null,
    price_net: string,
    price_gross: string,
    regular_net: string,
    regular_gross: string,
  }

  const variant = defineModel('variant', { type: Object as PropType<Variant>, required: true });

  const props = defineProps({
    product: { type: Object as PropType<Product>, required: true },
    wrapper: { type: Object as PropType<HTMLElement>, required: false },
  });

  const observer = ref<IntersectionObserver>();

  const wrapperIsNotIntersecting = ref(false);
  const visible = ref(false);

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const breakpoints = useBreakpoints();
  const scroll = useScrollWidth();

  const width = computed(() => {
    return scroll.value + 'px';
  });

  const classes = computed(() => {
    return {
      'product-banner--visible': visible.value,
    };
  });

  useScrollDetector({
    threshold: 8,
    callback: ({ down }) => {
      if (down) {
        if (wrapperIsNotIntersecting.value) {
          visible.value = true;
        }
      } else {
        visible.value = false;
      }
    },
  });

  watch(() => props.wrapper, (wrapper) => {
    if (observer.value) {
      observer.value.disconnect();
    }

    if (!wrapper) {
      return;
    }

    observer.value = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        wrapperIsNotIntersecting.value = !entry.isIntersecting;
      });
    }, {
      root: document.body,
      threshold: 0.25,
    });

    observer.value.observe(wrapper);
  });

  onBeforeUnmount(() => {
    if (observer.value) {
      observer.value.disconnect();
    }
  });
</script>
<template>
  <div class="product-banner" :class="classes" aria-hidden="true">
    <div class="container product-banner__container">
      <div class="product-banner__info">
        <MediaPicture :photo="variant.photo || product.photo" class="product-banner__image" sizes="200x200 2x, 100x100" />
        <div class="product-banner__title" v-html="variant.title" />
      </div>
      <div v-if="canCustomerOrder" class="product-banner__price">
        <SfProductPrice current :price="variant" />
        <SfCartButton :product="product" v-model:variant="variant">
          <SfButton :rounded="!breakpoints.lg" :square="!breakpoints.lg">
            <template #before>
              <SfIconCartAdd />
            </template>
            <template v-if="breakpoints.lg" #default>
              {{ $t('Dodaj do koszyka') }}
            </template>
          </SfButton>
        </SfCartButton>
      </div>
      <div v-else>
        <div class="product-banner__price__guest">
          <VisitorLink v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" rel="nofollow">
            {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
          </VisitorLink>
          <span v-else>
            {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --product-banner-top: 137px;
    --product-banner-box-shadow: var(--secondary-box-shadow-100);
  }
</style>
<style scoped lang="scss">
  .product-banner {
    --space: v-bind(width);

    position: fixed;
    top: var(--product-banner-top);
    right: var(--space);
    width: 100%;
    z-index: 95;
    transform: translateY(-120%);
    background-color: var(--color-white);
    transition: var(--primary-transition);

    &--visible {
      box-shadow: var(--product-banner-box-shadow);
      transform: translateY(0);
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 10px;
    }

    &__info {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      gap: 20px;
    }

    &__price {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;

      &__guest {
        color: var(--secondary-color);
        font-size: 0.75rem;
      }
    }

    :deep(.product-banner__image) {
      display: flex;

      img {
        width: 75px;
        aspect-ratio: 1 / 1;
      }
    }

    &__title {
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  @media (max-width: 992px) {
    .product-banner {
      --sf-product-price-current-font-size: 0.875rem;
      --sf-product-price-per-font-size: 0.625rem;

      top: 50px;

      &__title {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 575px) {
    .product-banner {
      --sf-product-price-current-font-size: 0.75rem;
      --sf-product-price-per-font-size: 0.625rem;

      &__container, &__info, &__price {
        gap: 10px;
      }

      :deep(.product-banner__image) {
        img {
          width: 46px;
          aspect-ratio: 1 / 1;
        }
      }

      &__title {
        font-size: 0.625rem;
      }
    }
  }
</style>
