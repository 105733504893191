<script lang="ts" setup>

  defineProps({
    value: { type: [String, Number], required: true },
  });

  const numerical = new Intl.NumberFormat(__i18n_current, {
    style: 'currency',
    currency: __currency_code,
    minimumFractionDigits: __currency_decimals,
    maximumFractionDigits: __currency_decimals,
  });

  function formatNumber(value: string | number) {
    const numeric = Number(value);

    if (isNaN(numeric)) {
      throw new Error(`"${value}" cannot be cast to a numeric value.`);
    }

    return numerical.format(Math.abs(numeric) * -1);
  }
</script>

<template>
  {{ formatNumber(value) }}
</template>
