<script lang="ts" setup>
  import { ref, type PropType, onUpdated, onMounted, type Ref, onBeforeUpdate, onBeforeUnmount } from 'vue';
  import Swiper from 'swiper';
  import { Autoplay, Pagination } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/autoplay';
  import 'swiper/css/pagination';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    items: { type: Array as PropType<any>, default: [] },
  });

  const htmlElement = ref() as Ref<HTMLElement>;

  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Autoplay, Pagination],
      autoplay: true,
      loop: true,
      pagination: true,
      slidesPerView: 1,
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <section class="hero-slider-block">
    <div ref="htmlElement" class="swiper">
      <div class="swiper-wrapper">
        <div v-for="item in items" class="swiper-slide">
          <div class="background">
            <MediaPicture :model="item" photoable="SectionItem" type="background" />
          </div>
          <div class="content">
            <component :is="item.header_type || 'h2'" v-if="item.header" v-html="item.header" />
            <div v-if="item.content" v-html="item.content" />
          </div>
        </div>
      </div>
      <div class="swiper-pagination" />
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .swiper-slide {
    position: relative;
    box-sizing: border-box;

    .background {
      box-sizing: border-box;

      :deep(img) {
        max-width: 100%;
        aspect-ratio: 4 / 2;
        object-fit: cover;
        object-position: center;
      }
    }

    .content {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
      background: rgb(0 0 0 / 0.5);
      color: #fff;
    }
  }
</style>
