<script lang="ts" setup>
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: { type: String },
    content: { type: String },
    products: { type: Array },
  });

  const parameters = ref({
    spaceBetween: 20,
    breakpoints: {
      1: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
    },
  });

</script>
<template>
  <section class="block-slider">
    <h2 class="block-slider__header">{{ header }}</h2>
    <div class="block-slider__wrapper">
      <div class="block-slider__content" v-html="content" />
      <SfProductsCarousel :parameters="parameters" :products="products" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @use "$assets/mixins/media";

  .block-slider {
    background: white;
    padding: 2rem;
    border-radius: var(--border-radius);

    @include media.query(md) {
      padding: 1rem;
    }

    &__content {
      padding: 1rem 0 1.5rem 1rem;

      @include media.query(md) {
        font-size: 0.875rem;
        padding: 0.5rem 0 1rem 0.5rem;
      }
    }
  }
</style>
