import { ref, computed } from 'vue';

type Options = {
  timestamp?: number,
  timestampMs?: number,
  callback?: () => void
}

export function useTimer({ timestamp, timestampMs, callback }: Options) {
  const timer = ref(initializeTimer({ timestamp, timestampMs }));
  const formatted = computed(() => formatTimer(timer.value));
  let interval: number | undefined;

  interval = setInterval(() => {
    if (timer.value <= 0 && interval) {
      clearInterval(interval);
      if (callback) callback();
    }

    timer.value = timer.value - 1;
  }, 1000);

  const stopTimer = () => {
    if (interval) {
      clearInterval(interval);
      interval = undefined;
    }
  };

  return { timer, formatted, stopTimer };
}

function initializeTimer({ timestamp, timestampMs }) {
  if (timestamp) {
    return timestamp - Math.round(Date.now() / 1000);
  }

  if (timestampMs) {
    return Math.round(timestampMs / 1000) - Math.round(Date.now() / 1000);
  }

  return 0;
}

function formatTimer(time: number): string {
  let minutes = Math.floor(time / 60);
  let seconds = Math.round(time - (minutes * 60));

  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
}
