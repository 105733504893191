<script lang="ts" setup>
  import { ref, type Ref, onMounted, onBeforeUnmount, onUpdated } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    header: { type: String },
    place_details: { type: Object },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;

  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    if (props.place_details) {
      swiper.value = new Swiper(htmlElement.value, {
        modules: [Navigation],
        slidesPerView: 1,
        navigation: {
          nextEl: htmlNavigation.value.next.htmlElement,
          prevEl: htmlNavigation.value.prev.htmlElement,
        },
        breakpoints: {
          576: {
            slidesPerView: 1.5,
          },
          992: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      });
    }
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <section class="google-review container">
    <h2 class="google-review__header">
      {{ header }}
    </h2>
    <div v-if="place_details" class="reviews-carousel">
      <div class="reviews-carousel__left">
        <div class="reviews-carousel__average">
          {{ place_details.averageRating.toFixed(1) }}
        </div>
        <div>
          {{ $tc(':ratingsTotal opinia|:ratingsTotal opinie|:ratingsTotal opinii Google', place_details.ratingsTotal, { ratingsTotal: place_details.ratingsTotal }) }}
        </div>
      </div>
      <div class="items-carousel">
        <div ref="htmlElement" class="swiper reviews-carousel__container">
          <div class="swiper-wrapper">
            <div v-for="review in place_details.reviews" class="swiper-slide items-carousel__slide">
              <div class="reviews-carousel__slide">
                <div class="reviews-carousel__author-name">
                  {{ review.author_name }}
                </div>
                <SfDateTime :value="review.created_at" />
                <SfStars :rating="review.rating" />
                <p class="reviews-carousel__text">
                  {{ review.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <SfCarouselButtons ref="htmlNavigation" />
      </div>
    </div>
    <div v-else>
      {{ $t('Brak dostępnych opinii') }}
    </div>
  </section>
</template>
<style lang="scss">
  .google-review {
    text-align: center;

    &__header {
      margin-bottom: 2rem;
    }

    .items-carousel {
      width: 80%;

      @media (max-width: 992px) {
        width: 70%;
      }

      @media (max-width: 576px) {
        width: 90%;
      }
    }
  }

  .reviews-carousel {
    display: flex;
    gap: 5rem;

    @media (max-width: 992px) {
      gap: 2rem;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.875rem;
      font-weight: bold;
      width: 20%;
    }

    &__average {
      font-size: 3rem;
      font-weight: bold;
    }

    &__slide {
      height: 250px;
      background: white;
      padding: 1.5rem;
      border-radius: 20px;
      width: 100%;
      text-align: left;
    }

    &__author-name {
      font-weight: bold;
    }

    &__text {
      overflow-y: auto;
      max-height: 130px;
      padding-right: 0.2rem;
      font-size: 0.875rem;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--primary-border);
        border-radius: 4px;
      }
    }
  }
</style>
