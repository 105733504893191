<script setup>
  import { ref } from 'vue';
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    items: Array,
  });

  const parameters = ref({
    slidesPerView: 1,
    navigation: true,
    pagination: {
      clickable: true,
    },
    loop: true,
  });
</script>
<template>
  <div class="hero-container container">
    <CategoriesList v-if="breakpoints.lg" />
    <SfBanerCarousel :items :parameters class="hero-container__slider" />
  </div>
</template>
<style lang="scss">
  .hero-container {
    display: flex;
    justify-content: space-between;
    max-height: 582px;
    position: relative;

    &__slider {
      width: calc(100% - 300px);
      padding: 1rem;
      --sf-carousel-slide-padding-y: 0;
      --sf-carousel-slide-padding-x: 0;
      --sf-carousel-button-space: 0.625rem;

      @media(max-width: 1200px) {
        width: calc(100% - 260px);
      }

      @media(max-width: 992px) {
        width: 100%;
      }

      @media(max-width: 576px) {
        padding: 0.25rem;
      }
    }

    .baner-carousel {
      z-index: 1;

      &__container {
        height: 100%;
        width: 100%;
      }

      &__slide {
        border-radius: 0.375rem;
        overflow: hidden;
      }

      &__link {
        width: 100%;

        picture img {
          object-fit: cover;
          aspect-ratio: auto;
          height: 100%;
        }
      }

      .items-carousel__button--prev {
        left: 2.125rem;
      }

      .items-carousel__button--next {
        right: 2.125rem;
      }
    }
  }

  @media(max-width: 992px) {
    .hero-container {
      height: 50vw;
    }
  }
</style>
