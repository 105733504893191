<script lang="ts" setup>
  import { computed } from 'vue';
  import { useVisitorForm } from '@plenny/visitor';

  defineOptions({
    inheritAttrs: false,
  });

  type OrderShippingForm = {
    shipping_option_delivery_types: string[];
    shipping_option_service: string;
  }

  const { data } = useVisitorForm<OrderShippingForm>();

  const type = computed(() => {
    let isService = data.value.shipping_option_delivery_types.includes('service');
    let isMachine = data.value.shipping_option_delivery_types.includes('machine');

    if (isService && isMachine) {
      return undefined;
    }

    if (isService) {
      return 'service_point';
    }

    return 'parcel_machine';
  });
</script>
<template>
  <div class="furgonetka-point">
    <div class="furgonetka-point__content">
      <div class="furgonetka-point__section">
        <div class="furgonetka-point__header">
          <h3 class="furgonetka-point__heading">{{ $t('Wybierz punkt odbioru') }}</h3>
        </div>
        <div class="furgonetka-point__map-wrapper">
          <ShippingFurgonetkaMapWidget :service="data.shipping_option_service" :type="type" class="furgonetka-point__map" />
        </div>
      </div>
      <div class="furgonetka-point__section">
        <div class="furgonetka-point__header">
          <h3 class="furgonetka-point__heading">{{ $t('Podaj dane odbiorcy') }}</h3>
        </div>
        <div class="furgonetka-point__form">
          <div class="furgonetka-point__form-item">
            <SfFormInput :label="$t('Imię i nazwisko')" class="furgonetka-point__form-name" name="shipping_name" required type="text" />
          </div>
          <div class="furgonetka-point__form-item">
            <SfFormInput :label="$t('Telefon')" class="furgonetka-point__form-phone" name="shipping_phone" required type="tel" />
          </div>
          <div class="furgonetka-point__form-item">
            <SfFormInput :label="$t('E-mail')" class="furgonetka-point__form-email" name="shipping_email" required type="email" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .furgonetka-point {
    max-width: 672px;

    &__content,
    &__section,
    &__form {
      display: flex;
      flex-direction: column;
    }

    &__content {
      gap: 2rem;
    }

    &__section {
      gap: 1.5rem;
    }

    &__form {
      gap: var(--form-gap-y) var(--form-gap-x);
    }

    &__heading {
      font-size: 1.25rem;
    }
  }
</style>
