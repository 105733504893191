<script setup lang="ts">
  import { computed } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    name: { type: String, required: true, default: '' },
    avatar: { type: String, required: false },
    circle: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
  });

  const classes = computed(() => {
    return {
      'avatar--circle': props.circle,
      'avatar--large': props.large,
    };
  });

  const initials = computed(() => {
    return props.name.split(' ').slice(0, 2).map((p) => p.charAt(0).toUpperCase()).join('');
  });

  const source = computed(() => {
    if (props.avatar) {
      return `/photos/128x128/${props.avatar}`;
    }
  });
</script>
<template>
  <span class="avatar" :class="classes">
    <img v-if="avatar" class="avatar__image" :src="source" :alt="name" />
    <span v-else class="avatar__initials" v-html="initials" />
  </span>
</template>
<style lang="scss">
  :root {
    --ui-avatar-border: var(--secondary-border);
    --ui-avatar-background: var(--secondary-background);
    --ui-avatar-color: var(--secondary-color);
  }
</style>
<style scoped lang="scss">
  .avatar {
    align-items: center;
    border: 2px solid var(--ui-avatar-border);
    border-radius: 8px;
    display: flex;
    flex: 0 0 3rem;
    height: 3rem;
    justify-content: center;
    padding: 2px;
    transition: border-color 150ms ease-in-out;
    width: 3rem;

    &__image {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-position: center;
      object-fit: cover;
    }

    &__initials {
      align-items: center;
      background-color: var(--ui-avatar-background);
      border-radius: 4px;
      color: var(--ui-avatar-color);
      display: flex;
      flex: 1 1 100%;
      font-size: 1rem;
      font-weight: var(--font-weight-bold);
      height: 100%;
      justify-content: center;
      line-height: 1rem;
      width: 100%;
    }

    &--circle {
      border-radius: 50%;

      .avatar__image,
      .avatar__initials {
        border-radius: 50%;
      }
    }

    &--large {
      flex: 0 0 8rem;
      width: 8rem;
      height: 8rem;

      .avatar__initials {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
</style>
