<script setup lang="ts">
  import { type PropType, computed } from 'vue';
  import type { SaleOfferQuotation } from '$storefront';
  import { useShared } from '@plenny/visitor';

  const shared = useShared();

  const props = defineProps({
    offer: { type: Object, required: true },
    quotation: { type: Object as PropType<SaleOfferQuotation>, required: true },
  });

  const total = computed(() => {
    if (shared.value.include_vat) {
      return props.quotation.total_gross;
    } else {
      return props.quotation.total_net;
    }
  });
</script>
<template>
  <div class="quotation" role="button" @click="$get(route('web.account.offers.quotations.show', { offer: offer.id, quotation: quotation.id }))">
    <div class="quotation__header">
      <div class="quotation__title">
        {{ $t('Oferta #:nr', { nr: quotation.id }) }}
      </div>
      <div class="quotation__date">
        {{ $t('Ostatnia aktualizacja') + ':' }}
        <SfDateTime time :value="quotation.updated_at" />
      </div>
    </div>
    <div class="quotation__content">
      <SfOrderStatus :status="quotation.status" />
      <div class="quotation__total">
        <SfMoney :value="total" :code="quotation.currency_code" :decimals="quotation.currency_decimals" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .quotation {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
    border-radius: var(--border-radius);
    background-color: var(--secondary-background);
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;

    &:hover {
      background-color: var(--secondary-background-hover);
    }

    &__title {
      font-weight: var(--font-weight-bold);
    }

    &__date {
      color: var(--secondary-color);
      font-size: 0.675rem;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__total {
      font-weight: var(--font-weight-bold);
    }
  }
</style>
