<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.633"
    height="1em"
    viewBox="0 0 18.633 18.633"
  >
    <path
      d="M17.967,8.515H13.59c1.7-2.363,1.2-4.726,1.278-5.578V2.9A1.869,1.869,0,0,0,13.009,1c-1.278,0-1.7,1.085-2.131,2.208A6.442,6.442,0,0,1,7.547,7.237l-1.395.736V7.9a.777.777,0,0,0-.775-.775H2.937A1.918,1.918,0,0,0,1,9.058v8.329a1.918,1.918,0,0,0,1.937,1.937H5.377a.777.777,0,0,0,.775-.775V18.51c.659.155,1.2.31,1.7.426a14.718,14.718,0,0,0,4.106.7h4.106A1.645,1.645,0,0,0,17.7,17.967a1.517,1.517,0,0,0-.349-1.007,1.65,1.65,0,0,0,1.046-1.55,1.517,1.517,0,0,0-.349-1.007,1.65,1.65,0,0,0,1.046-1.55,1.762,1.762,0,0,0-.387-1.085,1.7,1.7,0,0,0,.93-1.511A1.721,1.721,0,0,0,17.967,8.515ZM2.937,18.51a1.141,1.141,0,0,1-1.162-1.162V9.019A1.141,1.141,0,0,1,2.937,7.857H5.377V18.471H2.937Zm15.031-7.438H15.682a.387.387,0,1,0,0,.775h1.743a.892.892,0,0,1,0,1.782H14.985a.387.387,0,1,0,0,.775h1.743a.892.892,0,0,1,0,1.782H14.287a.366.366,0,0,0-.387.387c0,.659.968.31,2.131.387a.869.869,0,0,1,.852.891.941.941,0,0,1-.852.968H11.924a13.806,13.806,0,0,1-3.913-.659c-.542-.155-1.162-.31-1.9-.5V8.825L7.9,7.973a7.37,7.37,0,0,0,3.719-4.455c.465-1.162.7-1.7,1.395-1.7A1.136,1.136,0,0,1,14.094,2.9c-.077.968.387,3.06-1.162,5.191l-.426.581a.393.393,0,0,0,.31.62h5.152a.892.892,0,0,1,0,1.782Z"
      transform="translate(-1 -1)"
      fill="currentColor"
    />
  </svg>
</template>
