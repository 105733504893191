<script lang="ts" setup>
  import { route, $delete, $refresh } from '@plenny/visitor';
  import { ref, type PropType } from 'vue';
  import { trans } from '@plenny/translator';
  import { useBreakpoints } from '$storefront';
  import type { Wishlist } from '$types';

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    wishlist: { type: Object as PropType<Wishlist>, required: true },
    products: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();
  const open = ref(false);

  function onSaved() {
    $refresh();
  }

  function onDestroy(wishlist: Wishlist, variant: number) {
    if (confirm(trans('Czy na pewno chcesz usunąć ten produkt z listy?'))) {
      $delete(route('api.v1.web.wishlist.variant.destroy', { wishlist, variant })).then(() => {
        $refresh();
      });
    }
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ wishlist.name }}
  </AccountMobileNavigation>
  <SfTile large class="account-wishlist-record">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">
        {{ wishlist.name }}
      </h1>
    </template>
    <template #default>
      <div v-if="products.data.length > 0" class="account-wishlist-record__items">
        <ProductCard v-for="product in products.data" :key="product.id" :horizontal="breakpoints.md" :product="product">
          <template #icons>
            <SfButton rounded square danger transparent @click.prevent.stop="onDestroy(wishlist, product.variant_id)">
              <template #after>
                <SfIconTrash />
              </template>
            </SfButton>
          </template>
        </ProductCard>
      </div>
      <SfDataPlaceholder v-else>
        <p>{{ $t('Na tej liście nie ma zapisanych żadnych produktów.') }}</p>
      </SfDataPlaceholder>
    </template>
    <template #footer>
      <SfButton primary @click="open = true">
        {{ $t('Edytuj listę') }}
      </SfButton>
      <SfButton :href="route('web.account.wishlist.index')">
        {{ $t('Powrót') }}
      </SfButton>
    </template>
  </SfTile>
  <SfPagination :lastPage="products.last_page" :links="products.links" :currentPage="products.current_page" />
  <WishlistEditModal v-model:open="open" :wishlist="wishlist" @saved="onSaved" />
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-wishlist-record {
    &__items {
      display: flex;
      flex-direction: column;
      row-gap: 1.875rem;
    }
  }

  @include media.query(sm) {
    .account-wishlist-record .tile__footer {
      flex-wrap: wrap;

      .btn {
        flex-grow: 1;
      }
    }
  }
</style>
