<script lang="ts" setup>
  import { ref } from 'vue';

  const props = defineProps({
    tree: { type: Object, required: true },
    brands: { type: Object, required: true },
  });

  const openItemId = ref(props.tree[0]?.children[0]?.id);

  function setOpenItem(id: number) {
    openItemId.value = id;
  }
</script>
<template>
  <Section v-if="tree.length > 0" :title="tree[0].title" class="popular-category container">
    <div class="popular-category__content">
      <ul v-if="tree[0].children.length" class="popular-category__list" @mouseleave="setOpenItem(tree[0].children[0].id)">
        <PopularCategoryListItem
          v-for="node in tree[0].children"
          v-model:openItemId="openItemId"
          :node="node"
          :open="openItemId === node.id"
        />
      </ul>
      <div v-else class="popular-category__empty">{{ $t('Brak podkategorii do wyświetlenia') }}</div>
      <ul class="popular-category__brand-list">
        <li v-for="brand in brands" :key="brand.id" class="popular-category__brand-item">
          <VisitorLink :href="route('web.product.index', { brands: [brand.id] })" :title="brand.name">
            <MediaPicture :photo="brand.photo" />
          </VisitorLink>
        </li>
      </ul>
    </div>
  </Section>
</template>

<style lang="scss">

  .orange {
    color: var(--primary-color);
  }

  .popular-category {

    &__content {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 200px;

      @media (max-width: 992px) {
        grid-template-columns: auto;
        gap: 220px;
        grid-template-rows: 100px 1fr;
      }
    }

    &__list {
      list-style: none;
      background: var(--color-medium-gray);
      max-width: 310px;
      border-radius: 20px;
      padding: 1.25rem 0 1.25rem 1.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 0.875rem;
      min-height: 350px;

      @media (max-width: 1200px) {
        max-width: 278px;
      }

      @media (max-width: 992px) {
        max-width: 100%;
        flex-direction: row;
        overflow: auto;
        padding-bottom: 0;
        min-height: auto;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }

      @media (max-width: 768px) {
        padding: 0.75rem 0.75rem 0 0.75rem;
      }
    }

    &__empty {
      font-weight: bold;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__brand-list {
      list-style: none;
      display: grid;
      gap: 0.75rem;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &__name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
