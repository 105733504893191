<script setup lang="ts">
  import { computed, type PropType, type Ref } from 'vue';
  import { useShared } from '@plenny/visitor';

  interface Price {
    regular_gross: string;
    regular_net: string;
    price_gross: string;
    price_net: string;
    omnibus_gross: string;
    omnibus_net: string;
  }

  interface Shared {
    include_vat: boolean;
  }

  const props = defineProps({
    price: { type: Object as PropType<Price>, required: true },
  });

  const shared = useShared() as Ref<Shared>;

  const regular = computed(() => {
    if (shared.value.include_vat) {
      return props.price.regular_gross;
    } else {
      return props.price.regular_net;
    }
  });

  const price = computed(() => {
    if (shared.value.include_vat) {
      return props.price.price_gross;
    } else {
      return props.price.price_net;
    }
  });

  const omnibus = computed(() => {
    if (shared.value.include_vat) {
      return props.price.omnibus_gross;
    } else {
      return props.price.omnibus_net;
    }
  });

  const promotion = computed(() => {
    return price.value != regular.value;
  });
</script>
<template>
  <div v-if="promotion" class="omnibus">
    <template v-if="omnibus">
      {{ $t('Najniższa cena z ostatnich 30 dni przed obniżką:') }}
      <SfMoney :value="omnibus" />
    </template>
    <template v-else>
      {{ $t('Cena przekreślona to najniższa cena z ostatnich 30 dni przed obniżką.') }}
    </template>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-omnibus-color-text: var(--color-default);
    --sf-omnibus-font-family: var(--font-family-sans);
    --sf-omnibus-font-size: var(--font-size-sm);
    --sf-omnibus-font-weight: var(--font-weight-regular);
    --sf-omnibus-padding-y: 0;
    --sf-omnibus-padding-x: 0;
    --sf-omnibus-margin-y: 0;
    --sf-omnibus-margin-x: 0;
  }

  .omnibus {
    font-size: var(--sf-omnibus-font-size);
    font-family: var(--sf-omnibus-font-family);
    color: var(--sf-omnibus-color-text);
    font-weight: var(--sf-omnibus-font-weight);
    padding: var(--sf-omnibus-padding-y) var(--sf-omnibus-padding-x);
    margin: var(--sf-omnibus-margin-y) var(--sf-omnibus-margin-x);
  }
</style>
