<script setup lang="ts">
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const name = import.meta.env.VITE_APP_NAME || 'Promoznawcy';
  const from = import.meta.env.VITE_CREATION_YEAR || 2024;
  const to = (new Date()).getFullYear();

  const message = computed(() => {
    if (from != to) {
      return trans('&copy; :from-:to :name. Wszystkie prawa zastrzeżone.', { name, from, to });
    } else {
      return trans('&copy; :from :name. Wszystkie prawa zastrzeżone.', { name, from });
    }
  });
</script>
<template>
  <span v-html="message" />
</template>
