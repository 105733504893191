<script setup lang="ts">
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    href: { type: String, required: false },
  });
</script>
<template>
  <div class="nav-account" v-if="!breakpoints.lg">
    <SfButton v-if="href" :href="href" transparent square rounded class="nav-account__back">
      <SfIconChevronLeft />
    </SfButton>
    <h1 class="nav-account__title">
      <slot name="default" />
    </h1>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-account-nav-background: rgb(255, 255, 255);
    --sf-account-nav-font-size: 1.125rem;
    --sf-account-nav-font-weight: var(--font-weight-semi);
    --sf-account-nav-font-family: var(--font-family-sans);
    --sf-account-nav-line-height: var(--line-height);
    --sf-account-nav-icon-font-size: 1rem;
    --sf-account-nav-color: rgb(0, 0, 0);
    --sf-account-nav-margin-top: 0;
    --sf-account-nav-margin-bottom: 1rem;
    --sf-account-nav-padding-y: 0.75rem;
    --sf-account-nav-padding-x: 0;
    --sf-account-nav-box-shadow: none;
  }

  .nav-account {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: var(--sf-account-nav-padding-y) var(--sf-account-nav-padding-x);
    background-color: var(--sf-account-nav-background);
    margin-top: var(--sf-account-nav-margin-top);
    margin-bottom: var(--sf-account-nav-margin-bottom);
    box-shadow: var(--sf-account-nav-box-shadow);

    &__title {
      width: 80%;
      text-align: center;
      font-size: var(--sf-account-nav-font-size);
      font-family: var(--sf-account-nav-font-family);
      line-height: var(--sf-account-nav-line-height);
      font-weight: var(--sf-account-nav-font-weight);
      color: var(--sf-account-nav-color);
    }

    &__back {
      position: absolute;
      top: 50%;
      left: calc(var(--sf-account-nav-icon-font-size) / 2);
      transform: translateY(-50%);

      svg {
        font-size: var(--sf-account-nav-icon-font-size);
      }
    }
  }
</style>
