<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    href: { type: String, required: false, default: '' },
    shadow: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    numeric: { type: Boolean, required: false, default: false },
    interactive: { type: Boolean, required: false, default: false },
    selected: { type: Boolean, required: false, default: false },
    default: { type: Boolean, required: false, default: false },
  });

  const tileClasses = computed(() => {
    return {
      'tile--small': props.small,
      'tile--large': props.large,
      'tile--shadow': props.shadow,
      'tile--numeric': props.numeric,
      'tile--interactive': props.interactive,
      'tile--selected': props.selected,
      'tile--default': props.default,
    };
  });

  const component = computed(() => {
    if (props.href) {
      return 'VisitorLink';
    }
    return 'div';
  });

  const specificProps = computed(() => {
    if (props.href) {
      return { href: props.href };
    }
  });
</script>
<template>
  <component :is="component" :class="{ ...tileClasses }" class="tile" v-bind="{ ...specificProps }">
    <div v-if="$slots.outer" class="tile__outer">
      <slot name="outer" />
    </div>
    <div class="tile__content">
      <div v-if="$slots.title || $slots.headerAction" class="tile__header">
        <slot name="title" />
        <slot name="headerAction" />
      </div>
      <slot />
      <div v-if="$slots.footer" class="tile__footer">
        <slot name="footer" />
      </div>
    </div>
  </component>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-tile-outer-gap: 1rem;
    --sf-tile-outer-padding-y: 0rem;
    --sf-tile-outer-padding-x: 2rem;

    --sf-tile-transition-timing-func: ease-out;
    --sf-tile-transition-duration: 200ms;

    --sf-tile-padding: 2rem;
    --sf-tile-large-padding: 3rem;
    --sf-tile-small-padding: 1rem;

    --sf-tile-gap: 1rem;
    --sf-tile-large-gap: 3rem;
    --sf-tile-small-gap: 1rem;

    --sf-tile-background: rgb(255, 255, 255);
    --sf-tile-box-shadow: none;
    --sf-tile-border-color: transparent;
    --sf-tile-border-width: 1px;
    --sf-tile-border-style: solid;
    --sf-tile-border-radius: 0;

    --sf-tile-interactive-background: var(--sf-tile-background);
    --sf-tile-interactive-box-shadow: var(--secondary-box-shadow-200);
    --sf-tile-interactive-border-color: var(--sf-tile-border-color);
    --sf-tile-interactive-border-style: var(--sf-tile-border-style);
    --sf-tile-interactive-border-width: var(--sf-tile-border-width);

    --sf-tile-interactive-background-hover: rgb(255, 255, 255);
    --sf-tile-interactive-box-shadow-hover: var(--primary-box-shadow-200);
    --sf-tile-interactive-border-color-hover: var(--primary-background);
    --sf-tile-interactive-border-style-hover: solid;
    --sf-tile-interactive-border-width-hover: 1px;

    --sf-tile-link-box-shadow: var(--secondary-box-shadow-200);
    --sf-tile-link-box-shadow-hover: var(--primary-box-shadow-200);
  }

  .tile {
    display: flex;
    flex-direction: column;
    gap: var(--sf-tile-gap);
    transition: var(--primary-transition);
    transition-property: background-color, border, box-shadow, transform;
    transition-timing-function: var(--sf-tile-transition-timing-func);
    transition-duration: var(--sf-tile-transition-duration);
    border-radius: var(--sf-tile-border-radius);
    scroll-margin: var(--sf-tile-outer-gap);
    box-shadow: var(--sf-tile-box-shadow);

    border-color: var(--sf-tile-border-color);
    border-width: var(--sf-tile-border-width);
    border-style: var(--sf-tile-border-style);

    > .tile__outer {
      display: flex;
      align-items: center;
      gap: calc(var(--sf-tile-gap) * 0.75);
      padding: var(--sf-tile-outer-padding-y) var(--sf-tile-outer-padding-x);
    }

    > .tile__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--sf-tile-gap);
      flex-grow: 1;
      background-color: var(--sf-tile-background);
      border-radius: var(--sf-tile-border-radius);
      padding: var(--sf-tile-padding);
      transition-property: background-color, border, box-shadow, transform;
      transition-timing-function: var(--sf-tile-transition-timing-func);
      transition-duration: var(--sf-tile-transition-duration);

      > .tile__header,
      > .tile__footer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      > .tile__header {
        justify-content: space-between;
      }

      > .tile__footer {
        justify-content: flex-end;
      }
    }

    &--large {
      > .tile__outer {
        gap: var(--sf-tile-large-gap);
        padding-left: var(--sf-tile-large-padding);
      }

      > .tile__content {
        padding: var(--sf-tile-large-padding);
        gap: var(--sf-tile-large-gap);
      }
    }

    &--small {
      > .tile__outer {
        gap: var(--sf-tile-small-gap);
        padding-left: var(--sf-tile-small-padding);
      }

      > .tile__content {
        padding: var(--sf-tile-small-padding);
        gap: var(--sf-tile-small-gap);
      }
    }

    &--shadow {
      box-shadow: var(--sf-tile-link-box-shadow);
    }

    &--numeric {
      .tile__outer {
        padding-left: 0;
      }
    }

    &--interactive {
      cursor: pointer;
      background-color: var(--sf-tile-interactive-background);
      box-shadow: var(--sf-tile-interactive-box-shadow);
      border: var(--sf-tile-interactive-border-color);
      border-style: var(--sf-tile-interactive-border-style);
      border-width: var(--sf-tile-interactive-border-width);

      &:hover {
        background-color: var(--sf-tile-interactive-background-hover);
        box-shadow: var(--sf-tile-interactive-box-shadow-hover);
        border: var(--sf-tile-interactive-border-color-hover);
        border-style: var(--sf-tile-interactive-border-style-hover);
        border-width: var(--sf-tile-interactive-border-width-hover);
      }
    }

    &--selected {
      background-color: var(--sf-tile-interactive-background-hover);
      box-shadow: var(--sf-tile-interactive-box-shadow-hover);
      border: var(--sf-tile-interactive-border-color-hover);
      border-style: var(--sf-tile-interactive-border-style-hover);
      border-width: var(--sf-tile-interactive-border-width-hover);
    }

    &--default .tile__outer {
      position: absolute;
      top: 0;
      font-size: 0.875rem;
      font-weight: var(--font-weight-semi);
      line-height: 1.25rem;
      margin-left: 0.5rem;
      padding: 0 0.5rem;
      transform: translateY(-50%);
      background-color: var(--sf-tile-background);
    }
  }

  a.tile {
    &:hover {
      box-shadow: var(--sf-tile-link-box-shadow-hover);
    }
  }

  @include media.query(lg) {
    .tile {
      background-color: var(--sf-tile-background);

      > .tile__outer {
        background-color: var(--sf-tile-background);
        padding: calc(var(--sf-tile-padding) / 3) 0 0 calc(var(--sf-tile-padding) / 3);
        flex-wrap: wrap;
        //row-gap: 0;
      }

      > .tile__content {
        gap: calc(var(--sf-tile-gap) / 2);
        padding: calc(var(--sf-tile-padding) / 3);
      }

      &--large {
        > .tile__outer {
          gap: var(--sf-tile-large-gap);
          padding-left: calc(var(--sf-tile-large-padding) / 3);
        }

        > .tile__content {
          padding: calc(var(--sf-tile-large-padding) / 2) calc(var(--sf-tile-large-padding) / 3);
          gap: calc(var(--sf-tile-large-gap) / 2);
        }
      }

      &--small {
        > .tile__outer {
          padding-left: var(--sf-tile-small-padding);
        }

        > .tile__content {
          padding: var(--sf-tile-small-padding);
          gap: var(--sf-tile-small-gap);
        }
      }

      &--default {
        > .tile__outer {
          padding: 0 0.5rem;
        }
      }
    }
  }
</style>
