<template>
<svg  xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 42.104 42.104">
  <g  transform="translate(0 0)">
    <path 
    fill="currentColor"
    d="M30,44.1H16.1a4.156,4.156,0,0,1-2.947-1.263L3.263,32.946A4.156,4.156,0,0,1,2,30V16.1a4.156,4.156,0,0,1,1.263-2.947l9.894-9.894A4.156,4.156,0,0,1,16.1,2H30a4.156,4.156,0,0,1,2.947,1.263l9.894,9.894A4.156,4.156,0,0,1,44.1,16.1V30a4.156,4.156,0,0,1-1.263,2.947l-9.894,9.894A4.156,4.156,0,0,1,30,44.1ZM16.1,6.21,6.21,16.1V30L16.1,39.893H30L39.893,30V16.1L30,6.21Z" transform="translate(-2 -2)"/>
  </g>
  <g transform="translate(18.947 10.526)">
    <path fill="currentColor" d="M13.105,19.631A1.989,1.989,0,0,1,11,17.526V9.105A1.989,1.989,0,0,1,13.105,7,1.989,1.989,0,0,1,15.21,9.105v8.421A1.989,1.989,0,0,1,13.105,19.631Z" transform="translate(-11 -7)"/>
  </g>
  <g  transform="translate(18.947 27.367)">
    <path fill="currentColor"  d="M13.105,19.21A1.989,1.989,0,0,1,11,17.105,1.989,1.989,0,0,1,13.105,15a1.989,1.989,0,0,1,2.105,2.105A1.989,1.989,0,0,1,13.105,19.21Z" transform="translate(-11 -15)"/>
  </g>
</svg>

</template>

