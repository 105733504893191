<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 23.318 23.796">
  <g transform="translate(0 12.959)" fill="#fff" stroke="#141515" stroke-width="1">
    <rect width="9.806" height="9.806" rx="3" stroke="none"/>
    <rect x="0.5" y="0.5" width="8.806" height="8.806" rx="2.5" fill="none"/>
  </g>
  <g transform="translate(0.276)" fill="#fff" stroke="#141515" stroke-width="1">
    <rect width="9.806" height="9.806" rx="3" stroke="none"/>
    <rect x="0.5" y="0.5" width="8.806" height="8.806" rx="2.5" fill="none"/>
  </g>
  <g transform="translate(12.757)" fill="#fff" stroke="#141515" stroke-width="1">
    <rect width="9.806" height="9.806" rx="3" stroke="none"/>
    <rect x="0.5" y="0.5" width="8.806" height="8.806" rx="2.5" fill="none"/>
  </g>
  <g transform="translate(12.481 12.959)">
    <path d="M13.5,9A4.5,4.5,0,1,1,9,4.5,4.5,4.5,0,0,1,13.5,9Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path d="M27.423,27.423l-2.448-2.448" transform="translate(-17.293 -17.293)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>
</svg>

</template>