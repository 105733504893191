<script lang="ts" setup>
  import { $post, route, useShared } from '@plenny/visitor';
  import { ref, watch } from 'vue';

  const shared = useShared();
  const include = ref(shared.value.include_vat);

  function toggleVat(event) {
    $post(route('web.vat.store'), { inclusive: event.target.checked });
  }

  watch(() => shared.value.include_vat, (value) => {
    include.value = value;
  });
</script>
<template>
  <div class="tax-switch">
    <SfFormSwitch v-model="include" class="tax-switch__switch" reverse @change="toggleVat">
      {{ include ? $t('z VAT') : $t('bez VAT') }}
    </SfFormSwitch>
  </div>
</template>
<style lang="scss" scoped>
  .tax-switch {
    --sf-switch-label-weight: var(--font-weight-500);

    display: flex;
    align-items: center;
    gap: 0.625rem;
    width: auto;
  }
</style>
