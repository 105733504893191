<script lang="ts" setup>
  import { ref, onMounted, type PropType, type Ref, onBeforeUnmount, onUpdated } from 'vue';
  import type { SwiperOptions } from 'swiper/types';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';

  const props = defineProps({
    products: { type: Array, required: true },
    parameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
    productHeader: { type: String, required: false, default: 'h3' },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      spaceBetween: 20,
      ...props.parameters,
      modules: [Navigation],
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel products-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container products-carousel__container">
      <div class="swiper-wrapper">
        <div v-for="product in products" class="swiper-slide items-carousel__slide products-carousel__product">
          <ProductCard :header="productHeader" :product />
        </div>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style lang="scss">
  :root {
    --sf-products-carousel-padding: 0.2rem;
    --sf-products-carousel-container-padding: 1rem;
  }

  .products-carousel {
    padding: var(--sf-products-carousel-padding);

    &__container {
      padding: var(--sf-products-carousel-container-padding);
    }
  }
</style>