<template>
  <footer class="modal__footer">
    <slot />
  </footer>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .modal__footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    flex-wrap: wrap;
  }

  @include media.query(sm) {
    .modal__footer {
      flex-direction: column;
      align-items: stretch;
    }
  }
</style>
