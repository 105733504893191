<script lang="ts" setup>
  import { ref, onMounted } from 'vue';
  import { $get, route } from '@plenny/visitor';

  type AttributeOption = {
    label: string,
    value: number,
  };

  type Attribute = {
    label: string,
    options: AttributeOption[],
    value: number,
  };

  const variant = defineModel('variant', { type: Object, required: false });

  const props = defineProps({
    product: { type: Object, required: true },
    series: { type: Boolean, required: false, default: false },
  });

  const attributes = ref<Attribute[]>([]);
  const selected = ref([]);
  const variants = ref([]);
  const series = ref([]);

  onMounted(() => {
    $get(route('api.v1.web.product.variant.index', {
      product: props.product.id,
      selected: selected.value,
      variant: variant.value?.id || props.product.variant_id,
    })).then((response) => {
      attributes.value = response.attributes;
      selected.value = response.selected;
      variants.value = response.variants;
      series.value = response.series;

      // On initial load do not override variant if it's already provided
      // as it causes unnecessary updates on components just after
      // the page is loaded.
      if (!variant.value) {
        variant.value = response.variant;
      }
    });
  });
</script>
<template>
  <div class="variant-picker">
    <SfVariantPickerGrid v-if="$props.series && series.length > 0" :product="product" show="series" v-model:attributes="attributes" v-model:variants="variants" v-model:series="series" v-model:selected="selected" v-model:variant="variant">
      <template #title>
        {{ $t('Inne z tej serii') + ':' }}
      </template>
    </SfVariantPickerGrid>
    <SfVariantPickerAttributes v-if="attributes.length > 0" :product="product" v-model:attributes="attributes" v-model:variants="variants" v-model:series="series" v-model:selected="selected" v-model:variant="variant">
      <template #title>
        {{ $t('Wybierz wariant') + ':' }}
      </template>
    </SfVariantPickerAttributes>
    <SfVariantPickerGrid v-else-if="variants.length > 0" :product="product" show="variants" v-model:attributes="attributes" v-model:variants="variants" v-model:series="series" v-model:selected="selected" v-model:variant="variant">
      <template #title>
        {{ $t('Wybierz wariant') + ':' }}
      </template>
    </SfVariantPickerGrid>
  </div>
</template>
<style scoped lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-variants-grid-gap: 3px;
    --sf-variants-grid-link-radius: 0px;
    --sf-variants-grid-link-border-color: var(--secondary-border);
    --sf-variants-grid-link-border-color-active: var(--primary-border-active);
    --sf-variants-grid-link-border-color-hover: var(--secondary-border-hover);
  }

  .variant-picker {
    display: flex;
    flex-direction: column;
    gap: var(--gap-card);
  }
</style>
