<script setup lang="ts">
  import type { PropType } from 'vue';
  import { useBreakpoints } from '$storefront';

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  type Loyalty = {
    remaining_points: number,
    created_at: string,
    expired_at: string
  }

  defineProps({
    activePointsValue: { type: Number, required: true },
    totalActivePoints: { type: Number, required: true },
    totalExpiredPoints: { type: Number, required: true },
    table: { type: Array as PropType<Loyalty[] | []>, required: true },
  });

  const breakpoints = useBreakpoints();
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Punkty lojalnościowe') }}
  </AccountMobileNavigation>
  <SfTile large class="account-loyalty">
    <template #title v-if="breakpoints.lg">
      <h1 class="account-heading">
        {{ $t('Punkty lojalnościowe') }}
      </h1>
    </template>
    <template #default>
      <section v-if="totalActivePoints">
        <h2 class="account-loyalty__total">{{ $t('Ilość punktów') + ':' }} {{ totalActivePoints }}</h2>
        <p class="account-loyalty__convert">
          {{ $t('W przeliczeniu') + ':' }}
          <b class="account-loyalty__money">
            <SfMoney :value="activePointsValue" />
          </b>
        </p>
        <p class="account-loyalty__expired">
          {{ $t('Nieaktywne punkty') + ':' }} <b>{{ totalExpiredPoints }}</b>
        </p>
      </section>

      <section v-else class="account-loyalty__lack-section">
        <h2 class="account-loyalty__lack">{{ $t('Brak punktów') }}</h2>
      </section>

      <section v-if="table.length > 0" class="account-loyalty__table-wrapper">
        <h2 class="account-loyalty__table-heading">{{ $t('Historia') }}</h2>
        <div class="account-loyalty__table">
          <div class="account-loyalty__table-head account-loyalty__row account-loyalty__row--head">
            <div class="account-loyalty__cell account-loyalty__cell-count account-loyalty__cell--head">
              {{ $t('Ilość') + ':' }}
            </div>
            <div class="account-loyalty__cell account-loyalty__cell-created account-loyalty__cell--head">
              {{ $t('Data uzyskania') + ':' }}
            </div>
            <div class="account-loyalty__cell account-loyalty__cell-expired account-loyalty__cell--head">
              {{ $t('Data ważności') + ':' }}
            </div>
          </div>

          <div class="account-loyalty__body">
            <div v-for="item in table" class="account-loyalty__row account-loyalty__row--body">
              <div class="account-loyalty__cell account-loyalty__cell-count account-loyalty__cell--body">
                {{ item.remaining_points }}
              </div>
              <div class="account-loyalty__cell account-loyalty__cell-created account-loyalty__cell--body">
                <SfDateTime :value="item.created_at" />
              </div>
              <div class="account-loyalty__cell account-loyalty__cell-expired account-loyalty__cell--body">
                <SfDateTime :value="item.expired_at" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </SfTile>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-loyalty {
    &__total,
    &__table-heading {
      font-size: 1.25rem;
    }

    &__convert {
      margin-top: 1.25rem;
    }

    &__expired {
      margin-top: 0.5rem;
    }

    &__table-wrapper {
      margin-top: 3.125rem;
    }

    &__table-head {
      background-color: var(--sf-account-color-light-background);
      font-weight: var(--font-weight-semi);
      border-top: 1px solid var(--sf-account-color-medium-grey);
    }

    &__body {
      font-size: 0.875rem;
    }

    &__row--body:nth-child(2n) {
      background-color: var(--sf-account-color-light-background);
    }

    &__row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid var(--sf-account-color-medium-grey);
    }

    &__cell {
      padding: 0.5rem 0.625rem;
    }

    &__table-heading {
      margin-bottom: 1.25rem;
    }
  }

  @include media.query(lg) {

    .account-loyalty {
      --sf-tile-large-padding: 2rem;

      &__lack {
        text-align: center;
      }

      &__total, &__table-heading {
        font-size: 1.25rem;
      }

      &__convert, &__expired {
        font-size: 0.875rem;
      }

      &__table-wrapper {
        margin-top: 1rem;
      }

      &__convert {
        margin-top: 1rem;
      }

      &__expired {
        margin-top: 0.25rem;
      }

      &__cell--head {
        font-size: 0.875rem;
      }

      &__cell {
        padding: 0.5rem 0.375rem;
      }
    }
  }
</style>
