<script setup lang="ts">
  import { computed } from 'vue';
  import { $delete, route, $post, useShared } from '@plenny/visitor';

  const props = defineProps({
    variant: { type: Number, required: true },
  });

  const shared = useShared();

  const compared = computed(() => {
    if (props.variant && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(props.variant);
    } else {
      return false;
    }
  });

  function toggle() {
    if (compared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.variant }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': props.variant }));
    }
  }
</script>
<template>
  <div class="compare" @click.prevent.stop="toggle">
    <slot v-bind="{ compared }">
      <SfButton class="compare__button" :class="{ compared }" rounded small square transparent>
        <template #after>
          <SfIconScale />
        </template>
      </SfButton>
    </slot>
  </div>
</template>
<style scoped lang="scss">
  @use "$assets/mixins/media";

  .compare {
    display: contents;
    font-size: 1.125rem;

    &__button {
      &:hover {
        color: var(--color-primary);
      }

      &.compared {
        color: var(--danger-color);

        &:hover {
          color: var(--danger-color-hover);
        }
      }
    }
  }
</style>
