<script setup lang="ts">
  import { ref, type Ref } from 'vue';

  const prev = ref() as Ref<HTMLElement>;
  const next = ref() as Ref<HTMLElement>;

  defineExpose({
    prev,
    next,
  });
</script>
<template>
  <SfButton rounded square ref="prev" class="items-carousel__button items-carousel__button--prev">
    <SfIconChevronLeft />
  </SfButton>
  <SfButton rounded square ref="next" class="items-carousel__button items-carousel__button--next">
    <SfIconChevronRight />
  </SfButton>
</template>
