<template>
  <div class="login">
    <SfTile large>
      <template #title>
        <p class="account-heading login__heading">
          {{ $t('Logowanie') }}
        </p>
      </template>
      <template #default>
        <AccountSignInForm />
      </template>
    </SfTile>
    <SfTile class="sign-up" large>
      <template #title>
        <p class="account-heading">
          {{ $t('Nowe konto') }}
        </p>
      </template>
      <template #default>
        <AccountSignUpAdvantages />
        <SfButton :href="route('web.account.register.create')">
          {{ $t('Załóż konto') }}
        </SfButton>
      </template>
    </SfTile>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: flex-start;
    gap: 4rem;
    max-width: 1200px;
    margin: 100px auto;
  }

  @include media.query(lg) {
    .login {
      grid-template-columns: 1fr;
      gap: 1rem;
      margin: 1rem 0 2rem;


      .tile__content {
        padding: 1.25rem 0.625rem;

        .tile__header {
          justify-content: center;
        }
      }
    }
  }
</style>
