<script setup lang="ts">
import {computed} from 'vue';
import {route} from '@plenny/visitor';

const props = defineProps({
  offer: {type: Boolean, required: true},
  step: {type: Number, required: false},
  processing: {type: Boolean, required: false, default: false},
});

const isLastStep = computed(() => props.step === 5);

const target = computed(() => {
  if (isLastStep.value) {
    return route('web.homepage.index');
  } else {
    return route('web.cart.show');
  }
});

const firstStep = computed(() => route('web.cart.show'));
const secondStep = computed(() => props.offer ? route('web.offer.account.show') : route('web.cart.account.show'));
const thirdStep = computed(() => props.offer ? route('web.offer.details.edit') : route('web.cart.details.edit'));
const fourthStep = computed(() => props.offer ? route('web.offer.summary.show') : route('web.cart.summary.show'));

const classes = computed(() => {
  return {'order-header__nav-item--disabled': isLastStep.value};
});
</script>
<template>
  <header class="order-header">
    <div class="order-header__wrapper">
      <div class="container order-header__content">
        <div class="order-header__top-wrapper">
          <VisitorLink :href="target" class="order-header__logo-wrapper">
            <SfLogo class="order-header__image"/>
          </VisitorLink>
          <OrderHeaderPhone/>
        </div>
      </div>
    </div>
    <nav class="order-header__nav">
      <VisitorLink :aria-disabled="isLastStep" :class="classes" :href="firstStep" class="order-header__nav-item"
                   explicit>
        {{ $t('Koszyk') }}
      </VisitorLink>
      <VisitorLink :aria-disabled="isLastStep" :class="classes" :href="secondStep" class="order-header__nav-item">
        {{ $t('Twoje konto') }}
      </VisitorLink>
      <VisitorLink :aria-disabled="isLastStep" :class="classes" :href="thirdStep" class="order-header__nav-item">
        {{ $t('Finalizacja') }}
      </VisitorLink>
      <VisitorLink :aria-disabled="isLastStep" :class="classes" :href="fourthStep" class="order-header__nav-item">
        {{ $t('Podsumowanie') }}
      </VisitorLink>
      <VisitorLink :class="{ 'active': isLastStep, 'processing': true }" class="order-header__nav-item"
                   href="javascript:void(0)">
        {{ $t('Potwierdzenie') }}
      </VisitorLink>
    </nav>
  </header>
</template>

<style lang="scss">
@use "$assets/mixins/media";

:root {
  --sf-order-header-completed-bg: var(--sf-status-color-success);
  --sf-order-header-completed-color: var(--color-white);

  --sf-order-header-active-bg: var(--color-black);
  --sf-order-header-active-color: var(--color-white);

  --sf-order-header-disabled-color: var(--disabled-color);

  --sf-order-header-background: rgb(255, 255, 255);
  --sf-order-header-nav-background: rgb(255, 255, 255);

  --sf-order-header-box-shadow: none;
  --sf-order-header-line-color: var(--disabled-color);
  --sf-order-header-border: 1px solid var(--disabled-background);
}

.order-header {
  background-color: var(--sf-order-header-background);
  box-shadow: var(--sf-order-header-box-shadow);

  &__wrapper {
    border-bottom: var(--sf-order-header-border);
  }

  &__top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0;
    gap: 0.5rem;
  }

  &__image {
    max-width: 200px;
    height: 50px;
    font-size: 2.5rem;
    object-fit: contain;
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0;
    gap: 1.25rem;
    font-weight: var(--font-weight-semi);
    counter-reset: section;
    background-color: var(--sf-order-header-nav-background);
  }

  &__nav-item {
    display: flex;
    align-items: center;
    color: var(--color-default);
    gap: 0.625rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    white-space: nowrap;
    counter-increment: section;

    &:not(:last-child)::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 1.875rem;
      background-color: var(--sf-order-header-line-color);
    }

    &::before {
      content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='white' viewBox='0 0 16 16'%3e%3cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z'/%3e%3c/svg%3e ");
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0.75rem;
      line-height: 0.5rem;
      color: var(--sf-order-header-nav-background);
      vertical-align: center;
      border-radius: 50%;
      border: 1px solid var(--sf-order-header-completed-bg);
      background-color: var(--sf-order-header-completed-bg);
    }

    &.active {
      &.processing {
        cursor: not-allowed;
        pointer-events: none;
      }

      &::before {
        content: counter(section);
        background-color: var(--sf-order-header-active-bg);
        color: var(--sf-order-header-nav-background);
        border: none
      }

      & ~ a {
        color: var(--sf-order-header-disabled-color);
        cursor: not-allowed;

        &::before {
          content: counter(section);
          color: var(--disabled-color);
          background-color: var(--sf-order-header-nav-background);
          border-color: var(--sf-order-header-disabled-color);
        }
      }
    }

    &.order-header__nav-item--disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}

@include media.query(lg) {
  .order-header {

    &__wrapper {
      padding: 0 0.625rem;
    }

    &__logo-wrapper {
      max-width: 40%;
    }

    &__image {
      width: 100%;
    }

    &__nav {
      padding: 1rem 0;
      gap: 0;
    }

    &__nav-item {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 20%;
      padding: 0 0.25rem;
      font-weight: var(--font-weight-regular);

      &::after {
        position: absolute;
        width: 50%;
        top: 20%;
        left: unset;
        right: -25%;
      }

      &:not(:last-child)::after {
        width: 50%;
      }
    }
  }
}

@include media.query(sm) {
  .order-header__nav-item {
    font-size: 0.625rem;
  }
}
</style>
