<script setup>
  import { ref, onMounted } from 'vue';
  import { useShared } from '@plenny/visitor';

  const show = ref(false);
  const shared = useShared();
  const categories = ref([]);

  onMounted(() => {
    categories.value = shared.value.categories;
  });

  function showCategories() {
    show.value = true;
  }

  function hideCategories() {
    show.value = false;
  }
</script>
<template>
  <div class="categories">
    <ul class="categories__list">
      <li v-for="item in categories" class="categories__item level-0 " @mouseleave="hideCategories" @mouseover="showCategories">
        <VisitorLink :href="item.url" :rel="item.rel" :target="item.target" class="categories__link">
          <IconLightning />
          {{ item.name }}
        </VisitorLink>

        <div v-if="item.children.length > 0 && show" class="categories__children">
          <div class="categories__wrapper">
            <ul class="categories__sublist">
              <HeaderNavItem v-for="item in item.children" :depth="1" :item="item" />
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
  .level-0 {
    font-size: 0.875rem;

    .categories__children {
      position: absolute;
      display: none;
      top: 0;
      left: 300px;
      right: 0;
      height: 100%;
      min-width: 1050px;
      padding: 0.25rem 0.75rem 0.75rem 0;
      border-bottom-right-radius: 5px;
      text-align: left;

      @media (max-width: 1400px) {
        min-width: 850px;
      }

      @media (max-width: 1200px) {
        left: 250px;
        min-width: 725px;
      }

      .categories__wrapper {
        height: 100%;
        background: var(--color-white);
        border-radius: 20px;
        padding: 2rem 0;

        .categories__sublist {
          margin: 0;
          list-style: none;
          padding: 0 0 0 3.5rem;
          width: 300px;
          height: 100%;

          :deep(.nav-item) {
            white-space: wrap;
            width: auto;
          }

          @media (max-width: 1200px) {
            padding-left: 1.5rem;
            width: 260px;
          }

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: var(--color-light-background);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
            border-radius: 4px;
          }
        }
      }
    }
  }

  .categories {
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    z-index: 9;
    width: 300px;
    pointer-events: none;

    @media (max-width: 1200px) {
      width: 260px;
    }

    &__list {
      height: 100%;
      list-style: none;
      background: var(--color-medium-gray);
      border-bottom-left-radius: 5px;
      padding: 2.5rem 0;
      overflow-y: auto;
      scrollbar-width: none;

      @media (max-width: 1200px) {
        width: 260px;
      }
    }

    &__item {
      display: flex;
      gap: 1rem;
      align-items: center;
      pointer-events: auto;
      padding-left: 2.5rem;
      border-left: 3px solid transparent;

      @media (max-width: 1200px) {
        padding-left: 1rem;
      }

      a {
        justify-content: flex-start;
      }

      &:hover {
        background: var(--color-white);
        border-left: 3px solid var(--primary-color);
        width: 100%;

        &.level-0 .categories__children {
          display: block;
          background: var(--color-medium-gray);
        }
      }
    }

    &__link {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 100%;
      padding: 0.625rem 0;

      &:hover {
        color: var(--primary-color);
      }
    }
  }
</style>
