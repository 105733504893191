<script setup lang="ts">
  import { ref, onMounted, type PropType, type Ref, onBeforeUnmount, onUpdated } from 'vue';
  import type { SwiperOptions } from 'swiper/types';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';

  const props = defineProps({
    node: { type: Object, required: true },
    parameters: { type: Object as PropType<SwiperOptions>, required: false, default: {} },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      ...props.parameters,
      modules: [Navigation],
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>

<template>
  <div class="subcategories-carousel">
    <div class="items-carousel subcategories-carousel__items">
      <div ref="htmlElement" class="swiper items-carousel__container subcategories-carousel__container">
        <div class="swiper-wrapper subcategories-carousel__swiper-wrapper">
          <div class="swiper-slide items-carousel__slide subcategories-carousel__slide" v-for="category in node.children">
            <VisitorLink :href="route('web.product.category', { category })" class="subcategories-carousel__link">
              <MediaPicture :photo="category.photo" sizes="430x380 2x, 215x160" loading="lazy" class="subcategories-carousel__image" />
              <span class="subcategories-carousel__txt">
                {{ category.title }}
              </span>
              <div class="swiper-lazy-preloader" />
            </VisitorLink>
          </div>
        </div>
      </div>
      <SfCarouselButtons ref="htmlNavigation" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .subcategories-carousel {
    flex-grow: 1;
    max-height: calc(100% - 27px);

    @media (max-width: 576px) {
      margin-top: 0.5rem;
    }

    &__items {
      position: relative;
      width: 95%;
      height: 100%;

      :deep(.swiper-button-disabled) {
        display: none
      }
    }

    &__container {
      height: 100%;
    }

    &__swiper-wrapper {
      flex-flow: column wrap;
    }

    &__slide {
      --sf-carousel-slide-padding-x: 0;
      --sf-carousel-slide-padding-y: 0;
      justify-content: center;
      transition: border-color 0.2s ease-out;
      padding: 0.75rem 0.25rem;
      border-radius: 0.25rem;
      border: 1px solid transparent;

      &:hover {
        border-color: var(--primary-color);
      }

      :deep(img) {
        height: 80px;
        width: unset;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 0.25rem;
    }

    &__txt {
      text-align: center;
      font-size: 0.875rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
</style>