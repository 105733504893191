<script setup lang="ts">
  import { useShared } from '@plenny/visitor';
  import { computed } from 'vue';

  const shared = useShared();
  const phone = computed(() => `tel:${shared.value.department.phone}`);
</script>
<template>
  <SfButton :href="phone" class="order-header-phone" transparent>
    <template #before>
      <SfIconPhoneOutbound />
    </template>
    <template #default>
      {{ shared.department.phone }}
    </template>
  </SfButton>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  @include media.query(lg) {
    .order-header-phone {
      --sf-btn-font-size: 0.875rem;
      padding: 0;
    }
  }
</style>
