<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { $get, url } from '@plenny/visitor';

  const props = defineProps({
    price: { type: Object, required: true },
    attributes: { type: Object, required: true },
    brands: { type: Array, required: true },
    search: { type: String, required: false },
    options: { type: Object, required: true },
    categories: { type: Object, required: true },
  });

  const form = ref();

  function onSubmit(data: any) {
    return $get(url(window.location.pathname, data));
  }

  function actionClear() {
    return $get(url(window.location.pathname));
  }

  watch(props, (value) => {
    form.value.context.set('brands', value.brands);
    form.value.context.set('price', value.price);
    form.value.context.set('attributes', value.attributes);
    form.value.context.set('search', value.search);
  });
</script>
<template>
  <div class="filters">
    <VisitorForm ref="form" :initial="{ brands, price, attributes, search }" @submit="onSubmit">

      <ShopProductsFilterGroup>
        <template #header>
          <SfHeading>
            {{ $t('Szukaj') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <SfFormInput :label="$t('Wpisz szukaną frazę')" name="search" type="text" @change="$refs.form.submit()" />
          </div>
        </template>
      </ShopProductsFilterGroup>

      <ShopProductsFilterGroup v-if="categories.children.length > 0 || categories.category ||  categories.parent">
        <template #header>
          <SfHeading>
            {{ $t('Kategoria') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <ShopProductsFilterCategories v-bind="{ categories }" />
          </div>
        </template>
      </ShopProductsFilterGroup>

      <ShopProductsFilterGroup v-if="options.brands.length > 0">
        <template #header>
          <SfHeading>
            {{ $t('Marka') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <SfFormCheckbox :options="options.brands" name="brands" @change="$refs.form.submit()" />
          </div>
        </template>
      </ShopProductsFilterGroup>

      <ShopProductsFilterGroup>
        <template #header>
          <SfHeading>
            {{ $t('Cena') }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls filters__controls--price">
            <SfFormNumber label="Od" min="0" name="price.min" @change="$refs.form.submit()" />
            <SfFormNumber :min="price.min + 1" label="Do" name="price.max" @change="$refs.form.submit()" />
          </div>
        </template>
      </ShopProductsFilterGroup>

      <ShopProductsFilterGroup v-for="option in options.attributes">
        <template #header>
          <SfHeading>
            {{ option.label }}
          </SfHeading>
        </template>
        <template #content>
          <div class="filters__controls">
            <SfFormCheckbox :name="`attributes.${option.value}`" :options="option.options" @change="$refs.form.submit()" />
          </div>
        </template>
      </ShopProductsFilterGroup>

      <SfButton class="filters__clear" @click="actionClear">
        {{ $t('Wyczyść') }}
      </SfButton>
    </VisitorForm>
  </div>
</template>
<style lang="scss">
  @use '$assets/mixins/media';

  :root {
    --sf-products-filter-gap: var(--gap-card);
    --sf-products-filter-controls-padding: 0;
    --sf-products-filter-controls-padding-lg: 0;
    --sf-products-filter-controls-price-gap: 0.5rem;
    --sf-products-filter-controls-price-gap-lg: 1rem;
    --sf-products-filter-clear-btn-width: 100%;
    --sf-products-filter-mobile-clear-btn-margin-top: 1rem;

    --sf-products-filter-heading-font-size: 1.5rem;
    --sf-products-filter-heading-font-size-sm: 1.25rem;
    --sf-products-filter-heading-line-height: 1.5;
    --sf-products-filter-heading-font-weight: var(--font-weight-bold);
  }

  .filters {
    --sf-heading-font-size: var(--sf-products-filter-heading-font-size);
    --sf-heading-line-height: var(--sf-products-filter-heading-line-height);
    --sf-heading-font-weight: var(--sf-products-filter-heading-font-weight);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sf-products-filter-gap);

    @include media.query(lg) {
      --sf-heading-font-size: var(--sf-products-filter-heading-font-size-sm);
    }

    &__controls {
      padding: var(--sf-products-filter-controls-padding);

      @include media.query(lg) {
        padding: var(--sf-products-filter-controls-padding-lg);
      }

      &--price {
        display: flex;
        gap: var(--sf-products-filter-controls-price-gap);

        @include media.query(lg) {
          gap: var(--sf-products-filter-controls-price-gap-lg);
        }
      }
    }

    &__clear {
      width: var(--sf-products-filter-clear-btn-width);

      @include media.query(lg) {
        margin-top: var(--sf-products-filter-mobile-clear-btn-margin-top);
      }
    }
  }
</style>
