<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { route, useShared, useSession, $post } from '@plenny/visitor';

  const emit = defineEmits([
    'saved',
  ]);

  const props = defineProps({
    variant: { type: Number, required: true },
  });

  const shared = useShared();
  const session = useSession();
  const create = ref(false);

  const options = computed(() => {
    return Object.entries(shared.value.wishlists).map(([id, name]) => ({
      label: name as string,
      value: id as string,
    }));
  });

  function onSubmit(data: { wishlist: string }) {
    return $post(route('api.v1.web.wishlist.variant.store', { wishlist: data.wishlist }), {
      variant_id: props.variant,
    }).then(() => {
      emit('saved');
    });
  }
</script>

<template>
  <VisitorForm v-slot="{ data, submitting }" :submit="onSubmit" class="wishlist-picker">
    <section class="wishlist-picker__wrapper">
      <div v-if="session.is_authenticated">
        <SfFormSelect :label="$t('Wybierz listę')" :options="options" name="wishlist" required />
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Schowek jest dostępny tylko dla zalogowanych użytkowników.') }}
      </SfDataPlaceholder>
    </section>
    <footer class="wishlist-picker__footer">
      <template v-if="session.is_authenticated">
        <SfButton :disabled="!data.wishlist" :loading="submitting" primary type="submit">
          {{ $t('Zapisz') }}
        </SfButton>
        <SfButton @click.prevent="create = true">
          {{ $t('Nowa lista') }}
        </SfButton>
      </template>
      <template v-else>
        <SfButton :href="route('web.account.session.create')" primary>
          {{ $t('Zaloguj się') }}
        </SfButton>
      </template>
    </footer>
  </VisitorForm>
  <WishlistEditModal v-model:open="create" />
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .wishlist-picker {
    &__wrapper {
      margin: 1rem 0;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  @include media.query(md) {
    .wishlist-picker {
      &__wrapper,
      &__footer {
        display: flex;
        flex-direction: column;
      }

      &__wrapper {
        justify-content: flex-start;
        flex-grow: 1;
      }
    }
  }
</style>
