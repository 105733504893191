<script setup lang="ts">
  import NProgress from 'nprogress';
  import { onMounted, computed, onBeforeUnmount } from 'vue';
  import { useScrollWidth } from '$storefront';

  const scroll = useScrollWidth();
  const spacer = computed(() => scroll.value + 'px');

  function configureProgress() {
    NProgress.configure({
      parent: '#sf-progress-loader',
    });
  }

  function onVisitorStart() {
    NProgress.start();
  }

  function onVisitorDone() {
    NProgress.done();
  }

  function addEventListeners() {
    document.addEventListener('visitor:start', onVisitorStart);
    document.addEventListener('visitor:done', onVisitorDone);
  }

  function removeEventListeners() {
    document.removeEventListener('visitor:start', onVisitorStart);
    document.removeEventListener('visitor:done', onVisitorDone);

    NProgress.remove();
  }

  onMounted(() => {
    configureProgress();
    addEventListeners();
  });

  onBeforeUnmount(() => {
    removeEventListeners();
  });
</script>
<template>
  <div id="sf-progress-loader" />
</template>
<style lang="scss">
  :root {
    --nprogress-color: var(--primary-color);
  }

  #sf-progress-loader {
    --nprogress-spacer: v-bind(spacer);
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: var(--nprogress-color);
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: calc(100% - var(--nprogress-spacer));
    height: 3px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--nprogress-color), 0 0 5px var(--nprogress-color);
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }

  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 18px;
    right: calc(15px + var(--nprogress-spacer));
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: var(--nprogress-color);
    border-left-color: var(--nprogress-color);
    border-radius: 50%;
    animation: nprogress-spinner 400ms linear infinite;
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
