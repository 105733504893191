<script setup lang="ts">
  import type { PropType } from 'vue';
  import type { Country } from '$types';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  defineProps({
    countries: { type: Array as PropType<Country[]>, required: true },
  });
</script>
<template>
  <VisitorForm v-slot="{ data, submitting }">
    <header>
      <slot name="header">
        <h2 v-if="data.id">
          {{ $t('Edytuj adres dostawy') }}
        </h2>
        <h2 v-else>
          {{ $t('Dodaj adres dostawy') }}
        </h2>
      </slot>
    </header>
    <SfAddressForm
      :countries="countries"
      named contactable
      name-required
    />
    <section>
      <SfFormSwitch :label="$t('Ustaw jako domyślny')" name="default" />
    </section>
    <SfModalFooter>
      <SfButton @click="open = false">
        {{ $t('Anuluj') }}
      </SfButton>
      <SfButton type="submit" primary :loading="submitting">
        {{ $t('Zapisz') }}
      </SfButton>
    </SfModalFooter>
  </VisitorForm>
</template>
