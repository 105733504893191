<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {useVisitorForm} from '@plenny/visitor';

const props = defineProps({
  service: {type: String, required: false, default: undefined},
  type: {type: String, required: false, default: undefined},
});

const {data, set} = useVisitorForm();
const loading = ref(true);
const map = ref();

function mapService(service) {
  switch (service) {
    case 'inpost':
    case 'paczkomaty':
      return 'inpost';
    case 'dpd':
      return 'dpd';
    case 'ups':
      return 'ups';
    case 'gls':
      return 'gls';
    case 'fedex':
      return 'fedex';
    case 'poczta':
    case 'pocztapolska':
      return 'poczta';
    case 'dhl':
      return 'dhl';
    case 'meest':
      return 'meest';
    case 'orlen':
    case 'ruch':
      return 'orlen';
    default:
      throw new Error('Cannot resolve map widget service.');
  }
}

function openMap() {
  map.value = new window.Furgonetka.Map({
    courierServices: [mapService(props.service)],
    type: props.type,
    callback: (params) => {
      set('shipping_point_id', params.point.code);
      set('shipping_point_name', params.point.name);
    },
  });

  map.value.show();
}

onMounted(() => {
  if (!window.Furgonetka) {
    const script = document.createElement('script');

    script.src = 'https://furgonetka.pl/js/dist/map/map.js';
    script.async = true;
    script.onload = () => {
      loading.value = false;
    };

    document.head.appendChild(script);
  } else {
    loading.value = false;
  }
});
</script>
<template>
  <div class="furgonetka-map-widget">
    <SfDataLoader :loading="loading">
      <div class="furgonetka-map-widget__point">
        <div class="furgonetka-map-widget__icon">
          <SfIconPointer/>
        </div>
        <div class="furgonetka-map-widget__details">
          <div v-if="data.shipping_point_name" class="furgonetka-map-widget__name">
            {{ data.shipping_point_name }}
          </div>
          <SfButton small @click="openMap" transparent type="button">
            {{ data.shipping_point_name ? 'Zmień punkt' : 'Wybierz punkt' }}
          </SfButton>
        </div>
      </div>
    </SfDataLoader>
  </div>
</template>
<style lang="scss">
.furgonetka-map-widget {
  &__point {
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px dashed var(--secondary-border);
    padding: 1rem;
    border-radius: var(--border-radius);
  }

  &__icon {
    font-size: 1.5rem;
    line-height: 1;
  }

  &__details {
    display: flex;
    align-items: center;
    line-height: 1.5rem;
    gap: 0.5rem;
  }

  &__name {
    font-weight: var(--font-weight-bold);
  }
}
</style>
