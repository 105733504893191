<script setup>
  import { useShared, useSession } from '@plenny/visitor';
  import { ref } from 'vue';

  const shared = useShared();
  const session = useSession();

  const open = defineModel('open', { type: Boolean, required: false, default: false });
  const show = ref(false);

  function showCategories() {
    show.value = true;
  }

</script>

<template>
  <SfDrawer v-model:open="open" class="menu" left>
    <SfDrawerHeader v-model:open="open">
      <div class="menu__header">
        <IconSygnet class="menu__logo" />
        {{ $t('MENU') }}
      </div>
    </SfDrawerHeader>

    <div class="menu__wrapper">
      <ul class="menu__content">
        <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" @close-menu="open = false" />
        <li class="menu__categories" tabindex="0" @click="showCategories" @keydown.enter.space="showCategories">
          <SfButton primary>{{ $t('Kategorie') }}</SfButton>
        </li>
      </ul>
    </div>

    <div class="menu__actions">
      <a v-if="shared.department.phone" :href="`callto:${shared.department.phone}`" class="menu__row menu__row--bold">
        <IconPhone />
        {{ shared.department.phone }}</a>

      <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="menu__row">
        <IconEnvelope class="contact__icon" />
        {{ shared.department.email }}</a>

      <HeaderAuth menuDrawer @click="open = false" />

      <VisitorLink @click="open = false" :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" class="menu__row" explicit>
        <IconHeart />
        <span>
          {{ $t('Schowek') }}
        </span>
      </VisitorLink>
    </div>

    <div class="menu__inputs">
      <VatSwitcher />
      <LocalizationDropdown class="menu__localization" />
    </div>

    <SfDrawerCategories v-model:open="show" />
  </SfDrawer>
</template>

<style lang="scss" scoped>
  .menu {
    :deep(.categories-drawer__content) {
      background: transparent;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      font-size: 1.125rem;
    }

    &__logo {
      height: 35px;
      width: 35px;
    }

    &__wrapper {
      flex-grow: 1;
      font-size: 1.125rem;
      padding-left: 1rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      list-style: none;
      font-weight: bold;

      li {
        :deep(.active) {
          color: var(--primary-color);
        }
      }
    }

    &__categories {
      padding: 0.75rem 1rem 0.5rem 0;
      transition: color 0.2s ease;

      &:hover {
        color: var(--primary-color);
        cursor: pointer;
      }
    }

    &__actions {
      flex-grow: 1;
      font-size: 1.125rem;
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__inputs {
      font-size: 1.125rem;
      padding: 0 0 10rem 1rem;
      display: flex;
      flex: 1;
      gap: 1rem;
    }

    &__row {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      &--bold {
        font-weight: bold;
      }
    }

    &__localization {
      :deep(.switcher__dropdown) {
        background: var(--color-light-background);
        box-shadow: none;
        left: -100%;
        top: 75%;
        gap: 0.5rem;
      }
    }
  }
</style>