<script setup>
  import { ref, watch } from 'vue';
  import { useShared, useSession } from '@plenny/visitor';
  import { useBreakpoints, useScrollDetector } from '$storefront';

  defineProps({
    homepage: Boolean,
  });

  const session = useSession();

  const headerBottom = ref();

  const scroll = ref(false);
  const show = ref(false);
  const showMenu = ref(false);
  const openSearch = ref(false);
  const searchMobilebar = ref(false);

  const breakpoints = useBreakpoints();
  const shared = useShared();

  useScrollDetector({
    threshold: 2,
    callback: ({ up }) => {
      if (up) {
        headerBottom.value.classList.add('header__bottom--open');
        scroll.value = false;
      } else {
        headerBottom.value.classList.remove('header__bottom--open');
        scroll.value = true;
      }
    },
  });

  watch(scroll, () => {
    if (!scroll.value) {
      show.value = false;
      searchMobilebar.value = false;
    }
  });
</script>

<template>
  <header :class="{ 'header--scroll': scroll, 'search-mobile-active': searchMobilebar }" class="header">
    <HeaderTop />
    <div class="header__content">
      <div class="container header__top">
        <VisitorLink :href="route('web.homepage.index')">
          <SfLogo class="header__logo" />
        </VisitorLink>
        <HeaderSearch v-if="breakpoints.lg" :placeholder="$t('Wyszukaj produkt')" :searchButtonText="$t('Szukaj')" class="header__search" />
        <div class="header__actions">
          <HeaderAuth v-if="!breakpoints.lg" />
          <VisitorLink :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" class="header__action-tile" explicit>
            <IconHeart class="icon-heart" />
            <span class="header__wishlist">
              {{ $t('Schowek') }}
            </span>
          </VisitorLink>
          <MiniCart />
        </div>
      </div>
    </div>
    <div ref="headerBottom" class="header__bottom header__bottom--open">
      <div class="container header__bottom__inner">
        <div class="header__bottom__inner__wrapper">
          <Categories v-if="breakpoints.lg" compact />
          <nav v-if="breakpoints.lg" class="navigation">
            <ul>
              <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" />
            </ul>
          </nav>
          <IconHamburger v-if="!breakpoints.lg" class="header__hamburger" @click="showMenu = true" />
          <HeaderSearch v-if="!breakpoints.lg" :placeholder="$t('Wyszukaj produkt')" class="header__search" />
        </div>
      </div>
    </div>
  </header>
  <MenuDrawer v-if="!breakpoints.lg" v-model:open="showMenu" />
</template>

<style lang="scss" scoped>
  .header {
    position: sticky;
    top: 0;
    z-index: 9999;

    &__content {
      background: var(--color-white);
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3rem;

      @media (max-width: 992px) {
        justify-content: space-between;
        padding-right: 2rem;
      }

      @media (max-width: 576px) {
        padding: 0.5rem;
        gap: 0;
      }
    }

    &__search {
      width: 100%;
    }

    &__logo {
      @media (max-width: 992px) {
        padding: 0.25rem 0 0 0.25rem;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      @media (max-width: 576px) {
        gap: 0.65rem;
      }
    }

    &__wishlist {
      @media (max-width: 992px) {
        display: none;
      }
    }

    &__action-tile {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__logo {
      max-width: 260px;
      aspect-ratio: 957 / 218;
      height: auto;

      @media (max-width: 576px) {
        max-width: 140px;
      }
    }

    &__hamburger {
      font-size: 2rem;
      cursor: pointer;
    }

    &__bottom {
      background: var(--color-white);
      display: grid;
      width: 100%;
      min-width: 0;
      grid-template-rows: 0fr;
      transition: grid-template-rows ease-in-out 100ms;
      border-top: 1px solid var(--color-light-background);
      box-shadow: var(--box-shadow-menu);

      &--open {
        grid-template-rows: 1fr;

        .header__bottom__inner {
          animation: show-overflow ease-in-out 100ms;
          overflow: visible;
        }
      }

      &__inner {
        position: relative;
        display: flex;
        min-width: 0;
        overflow: hidden;

        &__wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          min-width: 0;

          .navigation {
            ul {
              display: flex;
              gap: 2rem;
              list-style: none;
              font-weight: bold;

              @media (max-width: 1200px) {
                gap: 1rem;
              }

              li {
                :deep(.active) {
                  color: var(--primary-color);
                }
              }
            }
          }

          @media(max-width: 992px) {
            align-items: center;
            gap: 20px;
            padding: 10px 15px;
          }
        }
      }
    }
  }

  @keyframes show-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  .fade-down-enter-active,
  .fade-down-leave-active {
    transition: var(--transition-primary-fade);
  }

  .fade-down-enter-from,
  .fade-down-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }
</style>
