<script setup lang="ts">
  import { useVisitorControl } from '@plenny/visitor';
  import { ref, onMounted, type PropType, watch, onBeforeUnmount, nextTick } from 'vue';
  import type { WatchStopHandle } from '@vue/runtime-core';

  defineOptions({
    inheritAttrs: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const props = defineProps({
    label: { type: String, required: false },
    id: { type: String, required: false },
    name: { type: String, required: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Array as PropType<string[]>, required: false, default: [] },
    value: { type: [String, Number], required: false },
    modelValue: { type: [String, Number], required: false },
    defaultValue: { type: [String, Number], required: false, default: null },
    placeholder: { type: String, required: false, default: '' },
  });

  const htmlControl = ref<HTMLTextAreaElement>();
  const { model, error } = useVisitorControl(props, emit);

  function resize() {
    htmlControl.value!.style.height = '0px';

    let style = window.getComputedStyle(htmlControl.value!);
    let top = Number(style.getPropertyValue('border-top-width').replace('px', ''));
    let bottom = Number(style.getPropertyValue('border-bottom-width').replace('px', ''));
    let height = htmlControl.value!.scrollHeight + top + bottom;

    htmlControl.value!.style.height = height + 'px';
  }

  const watchStopHandle = ref<WatchStopHandle>();

  onMounted(() => {
    resize();

    watchStopHandle.value = watch(model, () => {
      nextTick(() => {
        resize();
      });
    });
  });

  onBeforeUnmount(() => {
    if (watchStopHandle.value) {
      watchStopHandle.value();
    }
  });
</script>
<template>
  <SfControlWrapper floating v-bind="{ id, name, label, required, error }">
    <textarea ref="htmlControl" class="control" v-bind="{ name, required, ...$attrs }" :placeholder="placeholder || label" v-model="model" />
  </SfControlWrapper>
</template>
<style lang="scss">
  textarea.control {
    resize: none;
    min-height: var(--sf-textarea-min-height);
    //max-height: var(--sf-textarea-max-height);
  }
</style>
