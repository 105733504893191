<script setup lang="ts">
  import { ref, nextTick } from 'vue';
  import { useBreakpoints, useCart } from '$storefront';
  import { CartLayout } from '$components';
  import { route, $post } from '@plenny/visitor';

  defineOptions({
    layout: CartLayout,
    inheritAttrs: false,
  });

  const props = defineProps({
    offer: { type: Boolean, required: true },
    billing: { type: Object, required: true },
  });

  const { cart } = useCart();

  const breakpoints = useBreakpoints();
  const submitting = ref(false);

  function handleSubmit() {
    submitting.value = true;

    return nextTick(() => {
      if (props.offer) {
        return $post(route('web.offer.purchase.store'));
      } else {
        return $post(route('web.cart.purchase.store'));
      }
    });
  }
</script>
<template>
  <div class="order">
    <div class="order-summary">
      <div class="order-summary__column order-summary__column--left">
        <h1 class="order-summary__heading">
          {{ offer ? $t('Podsumowanie zapytania') : $t('Podsumowanie') }}
        </h1>
        <div class="order-summary__tiles-group">
          <SfTile class="order-summary__tile order-summary__delivery-method">
            <template #outer>
              <div class="order-summary__header-wrapper">
                <h2 class="order-heading">
                  {{ $t('Sposób dostawy') }}
                </h2>
                <SfButton :href="route('web.cart.details.edit') + '#shipping-method'" class="order-summary__edit-header" small transparent v-if="!breakpoints.md" :class="{'hide': breakpoints.md }" :aria-hidden="breakpoints.md">
                  {{ $t('Edytuj') }}
                </SfButton>
              </div>
            </template>
            <div class="order-summary__tile-content">
              <div class="order-summary__icon-wrapper">
                <SfIconTruck />
              </div>
              <div class="order-summary__content-info">
                <p class="order-summary__shipping-name">
                  {{ cart.shipping_option_name }}
                </p>
                <SfMoney :value="cart.shipping_cost" />
              </div>
            </div>
            <template #footer>
              <SfButton :href="route('web.cart.details.edit') + '#shipping-method'" class="order-summary__edit-footer" transparent v-if="breakpoints.md" :class="{'hide': !breakpoints.md }" :aria-hidden="!breakpoints.md">
                {{ $t('Edytuj') }}
              </SfButton>
            </template>
          </SfTile>

          <SfTile class="order-summary__tile order-summary__billing-method">
            <template #outer>
              <div class="order-summary__header-wrapper">
                <h2 class="order-heading">
                  {{ $t('Płatność') }}
                </h2>
                <SfButton :href="route('web.cart.details.edit') + '#billing-data'" class="order-summary__edit-header" transparent small v-if="cart.total_gross != 0 && !breakpoints.md" :class="{'hide': breakpoints.md }" :aria-hidden="breakpoints.md">
                  {{ $t('Edytuj') }}
                </SfButton>
              </div>
            </template>
            <template #default>
              <div class="order-summary__tile-content">
                <img :alt="billing.name" :src="billing.icon" class="order-summary__billing-image">
                <p class="order-summary__content-info">
                  {{ cart.billing_method_option_name }}
                </p>
              </div>
            </template>
            <template #footer>
              <SfButton :href="route('web.cart.details.edit') + '#billing-method'" class="order-summary__edit-footer" transparent v-if="cart.total_gross != 0 && breakpoints.md" :class="{'hide': !breakpoints.md }" :aria-hidden="!breakpoints.md">
                {{ $t('Edytuj') }}
              </SfButton>
            </template>
          </SfTile>
        </div>
        <SfTile class="order-summary__tile">
          <template #outer>
            <div class="order-summary__header-wrapper">
              <h2 class="order-heading">
                {{ $t('Dane') }}
              </h2>
              <SfButton :href="route('web.cart.details.edit') + '#shipping-data'" class="order-summary__edit-header" small transparent v-if="!breakpoints.md" :class="{'hide': breakpoints.md }" :aria-hidden="breakpoints.md">
                {{ $t('Edytuj') }}
              </SfButton>
            </div>
          </template>
          <div class="order-summary__data-tiles">
            <SfTile small>
              <template #title>
                <h3 class="order-summary__data-heading">{{ $t('Dane odbiorcy') }}</h3>
              </template>
              <template #default>
                <address class="order-summary__address">
                  <p v-if="cart.shipping_point_name">{{ cart.shipping_point_name }}</p>
                  <p v-if="cart.shipping_point_description">{{ cart.shipping_point_description }}</p>
                  <p v-if="cart.shipping_name">{{ cart.shipping_name }}</p>
                  <p v-if="cart.shipping_company">{{ cart.shipping_company }}</p>
                  <p v-if="cart.shipping_first_line">{{ cart.shipping_first_line }}</p>
                  <p v-if="cart.shipping_second_line">{{ cart.shipping_second_line }}</p>
                  <p v-if="cart.shipping_third_line">{{ cart.shipping_third_line }}</p>
                  <p v-if="cart.shipping_email">{{ cart.shipping_email }}</p>
                  <p v-if="cart.shipping_phone">{{ cart.shipping_phone }}</p>
                </address>
              </template>
            </SfTile>
            <SfTile small>
              <template #title>
                <h3 class="order-summary__data-heading">{{ $t('Dane kupującego') }}</h3>
              </template>
              <template #default>
                <address class="order-summary__address">
                  <p v-if="cart.billing_name">{{ cart.billing_name }}</p>
                  <p v-if="cart.billing_first_line">{{ cart.billing_first_line }}</p>
                  <p v-if="cart.billing_second_line">{{ cart.billing_second_line }}</p>
                  <p v-if="cart.billing_third_line">{{ cart.billing_third_line }}</p>
                  <p v-if="cart.billing_vat_id">NIP: {{ cart.billing_vat_id }}</p>
                </address>
              </template>
            </SfTile>
          </div>
          <template #footer>
            <SfButton :href="route('web.cart.details.edit') + '#shipping-data'" class="order-summary__edit-footer" v-if="breakpoints.md" transparent :class="{'hide': !breakpoints.md }" :aria-hidden="!breakpoints.md">
              {{ $t('Edytuj') }}
            </SfButton>
          </template>
        </SfTile>
        <SfTile class="order-summary__tile order-summary__order-note">
          <template #outer>
            <div class="order-summary__header-wrapper">
              <h2 class="order-heading">{{ $t('Komentarz do zamówienia') }}</h2>
              <SfButton :href="route('web.cart.details.edit') + '#note'" class="order-summary__edit-header" small transparent v-if="!breakpoints.md" :class="{'hide': breakpoints.md }" :aria-hidden="breakpoints.md">
                {{ $t('Edytuj') }}
              </SfButton>
            </div>
          </template>
          <p v-if="cart.customer_note" class="order-summary__note-content">
            {{ cart.customer_note }}
          </p>
          <SfDataPlaceholder v-else>
            {{ $t('Brak dodatkowych informacji.') }}
          </SfDataPlaceholder>
          <template #footer>
            <SfButton :href="route('web.cart.details.edit') + '#note'" class="order-summary__edit-footer" v-if="breakpoints.md" transparent :class="{'hide': !breakpoints.md }" :aria-hidden="!breakpoints.md">
              {{ $t('Edytuj') }}
            </SfButton>
          </template>
        </SfTile>
      </div>
      <div class="order-summary__column order-summary__column--right">
        <SfTile class="order-summary__tile order-summary__cart-summary">
          <template #outer>
            <h2 class="order-heading">
              {{ offer ? $t('Twoje zapytanie') : $t('Twoje zamówienie') }}
            </h2>
          </template>
          <div class="order-summary__products-list">
            <OrderSummaryItems v-for="item in cart.items" :key="item.id" :item="item" />
          </div>
          <OrderSummary :order="cart">
            <SfButton primary :loading="submitting" @click="handleSubmit">
              {{ offer ? $t('Składam zapytanie') : $t('Kupuję i płacę') }}
            </SfButton>
          </OrderSummary>
        </SfTile>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .order-summary {
    display: grid;
    grid-template-columns: 2fr minmax(450px, 1fr);
    gap: 2rem;

    &__data-heading {
      font-size: 1.25rem;
    }

    &__column {
      gap: 2rem;
    }

    &__data-tiles {
      display: flex;
      flex-wrap: wrap;
      gap: 4rem;
    }

    &__address {
      font-style: normal;
      flex-grow: 1;
    }

    &__tiles-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.75rem;
    }

    &__delivery-method,
    &__billing-method,
    &__column,
    &__content-info,
    &__products-list {
      display: flex;
      flex-direction: column;
    }

    &__tile-content {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    &__icon-wrapper {
      font-size: 2rem;
    }

    &__billing-image {
      height: 2rem;
      max-width: 2rem;
      object-fit: contain;
    }

    &__content-info {
      gap: 0.5rem;
      font-weight: var(--font-weight-semi);
    }

    &__products-list {
      row-gap: 0.625rem;
      margin-bottom: 1.5rem;
    }

    &__cart-summary {
      position: sticky;
      top: var(--sf-order-cart-summary-sticky-top);
    }

    &__edit-footer,
    &__edit-header {
      &.hide {
        display: none;
      }
    }
  }

  @include media.query(xl) {
    .order-summary {
      grid-template-columns: 1fr;

      &__heading {
        font-size: 1.5rem;
        padding: 0 0.625rem;
      }
    }
  }

  @include media.query(md) {
    .order-summary {
      margin: 0;
      gap: 1rem;

      &__tile {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      &__heading {
        font-size: 1.5rem;
        padding: 0 0.625rem;
      }

      &__tiles-group {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      &__column--left {
        gap: 1rem;
      }

      &__header-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &__edit-header {
        display: inline-flex;
      }

      &__data-heading {
        font-size: 1rem;
      }

      &__data-tiles {
        gap: 1rem;

        .tile--small {
          padding: 0;
        }
      }

      &__address {
        font-size: 0.875rem;
      }

      .order-heading {
        font-size: 1.25rem;
      }
    }
  }
</style>
