<script setup lang="ts">
  import { useShared } from '@plenny/visitor';
  import { computed } from 'vue';

  const shared = useShared();

  const phone = computed(() => {
    return shared.value.department?.phone;
  });

  const email = computed(() => {
    return shared.value.department?.email;
  });
</script>
<template>
  <div class="header-bgc">
    <div class="container header-top">
      <div class="header-top__contact">
        <a :href="`callto:${phone}`" class="header-top__link header-top__link--bold">
          <IconPhone class="header-top__icon" />
          {{ phone }}
        </a>
        <a :href="`callto:${email}`" class="header-top__link">
          <IconEnvelope class="header-top__icon" />
          {{ email }}
        </a>
      </div>
<!--   TODO - feature for future   -->
<!--      <div class="header-top__info">-->
<!--        {{ $t('Darmowa dostawa już od 500 zł!') }}-->
<!--      </div>-->
      <div class="header-top__right">
        <div class="header-top__controls">
          <VatSwitcher />
          <LocalizationDropdown />
        </div>
        <HeaderAuth />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .header-bgc {
    background: var(--color-light-background);
  }

  .header-top {
    background: var(--color-light-background);
    padding: 12px 0 11px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;

    @media (max-width: 992px) {
      display: none;
    }

    &__contact {
      display: flex;
      gap: 2.4rem;
    }

    &__link {
      display: inline-flex;
      align-items: center;
      gap: 0.375rem;
      text-decoration: none;
      font-size: 0.75rem;
      border: none;
      white-space: nowrap;

      &--bold {
        font-weight: bold;
      }
    }

    &__info {
      color: var(--color-gray);
      font-size: 0.75rem;
    }

    &__right {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__controls {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      white-space: nowrap;
    }
  }
</style>
