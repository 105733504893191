<script setup lang="ts">
  import { ref, onMounted, computed, type PropType, type Ref, onBeforeUnmount } from 'vue';
  import { route } from '@plenny/visitor';
  import { useBreakpoints } from '$storefront';

  type Attribute = {
    attribute_id: number,
    name: string,
  }

  type Variant = {
    id: number,
    variant_id: number,
    type: 'VARIANT' | 'SIMPLE',
    photo: string | null,
    slug: string,
    title: string | null,
    description_short: string | null,
    price_net: string,
    price_tax: string,
    price_gross: string,
    regular_net: string,
    regular_tax: string,
    regular_gross: string,
    omnibus_net: string | null,
    omnibus_tax: string | null,
    omnibus_gross: string | null,
    reviews_average: string,
    reviews_count: number,
    ean: string | null,
    sku: string | null,
    mpn: string | null,
    is_new: boolean,
    brand_name: string | null,
    brand_slug: string | null,
  }

  type Matrix = {
    [key: number]: {
      [key: number]: string
    };
  }

  const props = defineProps({
    attributes: { type: Array as PropType<Attribute[]>, required: true },
    variants: { type: Array as PropType<Variant[]>, required: true },
    matrix: { type: Object as PropType<Matrix>, required: true },
    stickyDistance: { type: Number, required: true, default: 0 },
  });

  const stickyDistance = computed(() => props.stickyDistance + 'px');
  const breakpoints = useBreakpoints();
  const showDifferences = ref(false);
  const scroll = ref(false);
  const head = ref() as Ref<HTMLElement>;
  const body = ref() as Ref<HTMLElement>;
  const pin = ref() as Ref<HTMLElement>;
  const observer = ref<IntersectionObserver>();

  onMounted(() => {
    if (body.value) {
      body.value.addEventListener('scroll', (e: Event) => {
        head.value.scrollLeft = e.target.scrollLeft;
      }, {
        passive: true,
      });
    }

    if (head.value) {
      head.value.addEventListener('scroll', (e: Event) => {
        body.value.scrollLeft = e.target.scrollLeft;
      }, {
        passive: true,
      });
    }

    observer.value = new IntersectionObserver(([entry]) => {
      scroll.value = !entry.isIntersecting;
    }, {
      rootMargin: `-${props.stickyDistance}px 0px 0px 0px`,
    });

    if (pin.value) {
      observer.value!.observe(pin.value);
    }
  });

  onBeforeUnmount(() => {
    observer.value!.disconnect();
  });

  const hasDifferentValue = (attributeId: number) => {
    const variants = Object.values(props.matrix);

    if (variants.length > 1) {
      const firstValue = variants[0][attributeId];
      return variants.some(product => product[attributeId] !== firstValue);
    }

    return false;
  };
</script>
<template>
  <span ref="pin"></span>
  <section v-if="variants.length > 0" class="comparator">
    <div class="comparator__header">
      <div v-if="!breakpoints.lg" class="comparator__count-mobile">
        <span class="comparator__header-count">
          {{ $t('Liczba produktów') + ':' }}
          {{ variants.length }}
        </span>
        <SfFormSwitch v-if="variants.length >= 2" v-model="showDifferences" :label="$t('Pokaż różnice')" name="differences" />
      </div>
      <div ref="head" :class="{ scroll }" class="comparator__head comparator__custom-scrollbar">
        <div v-if="breakpoints.lg" class="comparator__head-left">
          <span class="comparator__header-count">
            {{ $t('Liczba produktów') + ':' }}
            {{ variants.length }}
          </span>
          <SfFormSwitch v-if="variants.length >= 2" v-model="showDifferences" :label="$t('Pokaż różnice')" name="differences" />
        </div>
        <article v-for="variant in variants" :key="variant.id" class="comparator__head-product">
          <SfComparedProduct :variant="variant" :class="{ scroll }" />
        </article>
        <p v-if="props.variants.length === 1" class="comparator__head-add">
          {{ $t('Dodaj kolejny produkt aby porównać') }}
        </p>
      </div>
    </div>
    <div ref="body" class="comparator__body comparator__custom-scrollbar">
      <div v-for="attribute in attributes" :key="attribute.attribute_id" :class="{ 'different-value': variants.length >= 2 && showDifferences && hasDifferentValue(attribute.attribute_id) }" class="comparator__info">
        <div class="comparator__attribute-title">
          <span class="comparator__attribute-name">
            {{ attribute.name }}
          </span>
        </div>
        <div class="comparator__attributes">
          <div v-for="variant in variants" class="comparator__attribute">
            <span class="comparator__attribute-value">
              {{ matrix[variant.variant_id] && matrix[variant.variant_id][attribute.attribute_id] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else class="comparator__empty">
    <SfDataPlaceholder>
      {{ $t('Nie masz żadnych produktów w porównywarce.') }}
    </SfDataPlaceholder>
    <SfButton :href="route('web.product.index')" primary>
      {{ $t('Przejdź do sklepu') }}
    </SfButton>
  </section>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-comparator-border-color: rgb(220, 220, 220);
    --sf-comparator-header-background: rgb(255, 255, 255);
    --sf-comparator-diffrent-color: rgb(255, 0, 0);
    --sf-comparator-scrollbar-thumb-background: rgb(170, 170, 170);
    --sf-comparator-scrollbar-track-background: rgb(230, 230, 230);
    --sf-comparator-text-color: rgb(0, 0, 0);
  }

  .comparator {

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2rem;
      font-size: 1.375rem;
      padding: 1.875rem 0;
    }

    &__head,
    &__info {
      display: flex;
    }

    &__attribute {
      width: 300px;
    }

    &__attribute-title,
    &__head-left {
      min-width: 220px;
      width: 220px;
      background-color: var(--sf-comparator-header-background);
    }

    &__header {
      --stickyDistance: v-bind(stickyDistance);
      position: sticky;
      top: var(--stickyDistance);
      background-color: var(--sf-comparator-header-background);
      z-index: 9;
    }

    &__count-mobile, &__head-left {
      color: var(--sf-comparator-text-color);
    }

    &__head-add {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 1.25rem;
      line-height: 1.6;
    }

    &__head-left {
      position: sticky;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      z-index: 3;
      font-size: 0.875rem;
      padding: 1.25rem 10px;
      border: 1px solid var(--sf-comparator-border-color);
      border-bottom: none;
      background-color: var(--sf-comparator-header-background);
    }

    &__head-product {
      position: relative;
      width: 300px;
      min-width: 300px;
      padding: 0.625rem 1.25rem 1rem;
      border-right: 1px solid var(--sf-comparator-border-color);
      border-top: 1px solid var(--sf-comparator-border-color);
    }

    &__head {
      overflow-x: auto;
      overscroll-behavior-x: none;
      overscroll-behavior-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &.scroll {
        .comparator__head-product,
        .comparator__head-left {
          border-bottom: 1px solid var(--sf-comparator-border-color);
        }
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow-x: auto;
      overscroll-behavior-x: none;
      overscroll-behavior-y: auto;
      -webkit-overflow-scrolling: touch;
      max-width: 100%;
      padding-bottom: 0.625rem;
      color: var(--sf-comparator-text-color);
    }

    &__info {
      width: fit-content;
      font-size: 0.875rem;

      &:last-child {
        border-bottom: 1px solid var(--sf-comparator-border-color);
      }


      &.different-value {
        font-weight: var(--font-weight-semi);
        color: var(--sf-comparator-diffrent-color);
      }
    }

    &__attributes {
      display: flex;
      align-items: center;
    }

    &__attribute-title {
      position: sticky;
      left: 0;
      z-index: 1;
      height: 100%;
      font-weight: var(--font-weight-semi);
      background-color: var(--sf-comparator-header-background);
      border-left: 1px solid var(--sf-comparator-border-color);
    }

    &__attribute,
    &__attribute-title {
      height: auto;
      padding: 1rem 1.5rem;
      border-right: 1px solid var(--sf-comparator-border-color);
      border-top: 1px solid var(--sf-comparator-border-color);
    }

    &__attribute {
      height: 100%;
    }

    &__custom-scrollbar {
      &::-webkit-scrollbar {
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--sf-comparator-scrollbar-track-background);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--sf-comparator-scrollbar-thumb-background);
        border-radius: 5px;
      }
    }
  }

  @include media.query(lg) {
    .comparator {

      &__info {
        flex-direction: column;
        min-width: 100%;
      }

      &__attribute-title {
        width: auto;
        padding: 0.625rem 0;
        background-color: var(--sf-comparator-border-color);

        span {
          position: sticky;
          display: inline-block;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &__attribute {
        width: 50vw;
        min-width: unset;
        text-align: center;
        padding: 0.875rem;
        border-top: none;
      }

      &__header .btn {
        --sf-btn-padding-x: 0.375rem;
        --sf-btn-font-size: 0.875rem;
      }

      &__count-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        font-size: 0.875rem;

        .control__wrapper {
          width: auto;
        }

      }

      &__head-add {
        border-top: 1px solid var(--sf-comparator-border-color);
      }

      &__head-product {
        min-width: 50vw;
        width: 50vw;
        padding: 0.375rem;
      }

      &__head-left {
        width: 100%;
      }
    }
  }

  @include media.query(md) {
    .comparator {

      &__attribute {
        width: 70vw;
        min-width: unset;
      }

      &__head-product {
        min-width: 70vw;
        width: 70vw;
      }
    }
  }
</style>
