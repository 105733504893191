<script setup>
  import { useShared, VisitorLink } from '@plenny/visitor';

  const shared = useShared();
</script>
<template>
  <footer class="footer">
    <div class="footer__top container">
      <div class="footer__column">
        <SfLogo class="footer__logo" />
        <address class="footer__address">
          <div class="footer__contact">
            <IconPointer class="footer__icon" />
            <div>
              <p v-if="shared.department.first_line" v-text="shared.department.first_line" />
              <p v-if="shared.department.second_line" v-text="shared.department.second_line" />
              <p v-if="shared.department.third_line" v-text="shared.department.third_line" />
            </div>
          </div>
          <div class="footer__contact">
            <a :href="`callto:${shared.department.phone}`" class="footer__link">
              <IconPhone class="footer__icon" />
              {{ shared.department.phone }}</a>
          </div>
          <div class="footer__contact">
            <a :href="`mailto:${shared.department.email}`" class="footer__link">
              <IconEnvelope class="footer__icon" />
              {{ shared.department.email }}</a>
          </div>
        </address>
      </div>
      <section v-for="group in shared.menu.menu_bottom" class="footer__column">
        <SfHeading type="h2" class="footer__title">{{ group.name }}</SfHeading>
        <ul class="footer__list">
          <li v-for="item in group.children" class="footer__entry">
            <VisitorLink :explicit="item.explicit" :href="item.url" :rel="item.rel" :target="item.target" class="footer__link">
              {{ item.name }}
            </VisitorLink>
          </li>
        </ul>
      </section>
    </div>
    <div class="footer__bottom">
      <CopyrightMessage />
      <p class="footer__text">
        {{ $t('Realizacja:') }}
        <a
          class="footer__external-link"
          href="https://promoznawcy.pl/"
          target="_blank"
        >PROMOZnawcy.pl</a
        >
      </p>
    </div>
  </footer>

  <FixedActions />
</template>

<style lang="scss" scoped>
  .footer {
    background-color: var(--color-white);
    position: relative;
    overflow: hidden;
    margin-top: 5rem;
    z-index: 1;

    @media (max-width: 576px) {
      margin-top: 1.5rem;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      right: -5%;
      z-index: -1;
      background-image: url("../../../assets/images/sygnet.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: right;
      opacity: 0.1;

      @media (max-width: 992px) {
        background-position: bottom;
        right: 0;
        bottom: -50%;
        background-size: auto;
      }
      @media (max-width: 576px) {
        bottom: 0;
        background-size: auto 50%;
      }
    }

    &__top {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 5rem;
      padding: 1rem 1rem 2rem 1rem;

      @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
        text-align: center;
      }

      @media (max-width: 576px) {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
      }
    }

    &__logo {
      width: 100%;
      aspect-ratio: 957 / 218;
      height: auto;
      margin-bottom: 1rem;
    }

    &__bottom {
      background-color: var(--color-primary-black);
      color: var(--color-white);
      padding: 0.75rem 0.2rem;
      display: flex;
      justify-content: space-around;
      text-align: center;
      font-size: 0.875rem;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &__address {
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.32px;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      font-weight: bold;

      @media (max-width: 576px) {
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    &__contact {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      text-align: left;

      @media (max-width: 576px) {
        gap: 1rem;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      @media (max-width: 576px) {
        gap: 1rem;
      }
    }

    &__icon {
      font-size: 1.5rem;
    }

    &__title {
      font-size: 1rem;
      margin-top: 3.5rem;
      text-transform: uppercase;

      @media (max-width: 576px) {
        margin: 1rem 0;
        text-align: left;
      }
    }

    &__list {
      list-style: none;
      margin-top: 2rem;

      @media (max-width: 576px) {
        margin-top: 0.2rem;
        text-align: left;
      }
    }

    &__entry {
      margin-bottom: 1rem;
      @media (max-width: 576px) {
        margin-bottom: 0.375rem;
      }
    }

    &__company-name, &__external-link {
      color: var(--primary-color);
      text-decoration: none;
    }
  }
</style>
