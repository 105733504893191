<template>
	<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 46.104 46.104">
		<g>
			<path fill="currentColor"
				d="M23.052,0A23.052,23.052,0,1,0,46.1,23.052,23.077,23.077,0,0,0,23.052,0Zm0,42.533A19.481,19.481,0,1,1,42.533,23.052,19.5,19.5,0,0,1,23.052,42.533Z" />
		</g>
		<g transform="translate(11.893 14.572)">
			<path fill="currentColor"
				d="M153.814,162.287a1.786,1.786,0,0,0-2.521.121L140.4,174.4l-5.262-5.35a1.785,1.785,0,0,0-2.546,2.5l6.587,6.7a1.783,1.783,0,0,0,1.273.534.217.217,0,0,1,.034,0,1.787,1.787,0,0,0,1.287-.586l12.162-13.392A1.787,1.787,0,0,0,153.814,162.287Z"
				transform="translate(-132.079 -161.824)" />
		</g>
	</svg>
</template>