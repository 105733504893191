<script setup lang="ts">
  import { ref, type PropType } from 'vue';
  import type { Section } from '$types';

  defineProps({
    product: { type: Object, required: true },
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    recommended: { type: Array, required: false, default: [] },
    reviews: { type: Object, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });

</script>
<template>
  <div class="container" v-if="sections.length > 0 || product.list_attributes.length > 0 || product.reviews_average > 0">
    <div class="wrapper description-wrapper">

      <ShopProductViewDescriptionSection v-if="sections.length > 0" :title="$t('Opis')">
        <SectionRenderer :sections />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection v-if="product.list_attributes.length > 0" :title="$t('Specyfikacja')">
        <ShopProductViewDescriptionSpecification :product />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection  :title="$t('Opinie')">
        <ShopProductViewDescriptionReviews :reviews :product :reviewAvailable :reviewsSummary  />
      </ShopProductViewDescriptionSection>

      <ShopProductViewDescriptionSection :title="$t('Podobne produkty')" v-if="recommended.length > 0">
        <div class="products-wrapper">
          <SfProductsCarousel :products="recommended" :parameters />
        </div>
      </ShopProductViewDescriptionSection>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .description-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding: 60px;
    background-color: var(--color-white);

    .products-wrapper {
      .products-carousel {
        :deep(.swiper-container) {
          .wrap {
            .slide .product {
              flex-grow: 0;
              width: auto;
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .description-wrapper {
      margin: 15px auto;
      padding: 26px 10px;

      .product-section {
        padding: 26px 20px 20px 20px;
      }
    }
  }

  @media (max-width: 768px) {
    .description-wrapper {
      gap: 2.5rem;

      .reviews-wrapper {
        padding-top: 0.75rem;

        .head {
          margin: 0 auto;
          width: 250px;
          justify-content: center;

          :deep(.stars-wrapper) {
            svg {
              font-size: 18px;
            }

            span {
              font-size: 1rem;
            }
          }
        }
      }

      .products-wrapper {
        margin-top: 12px;
      }

      .product-section {
        padding: 15px;
      }
    }
  }
</style>
