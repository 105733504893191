<script lang="ts" setup>
  import type { PropType } from 'vue';
  import { computed } from 'vue';
  import { useVariantStock } from '$storefront';
  import { trans } from '@plenny/translator';
  import { UnitFormatter } from '$support';
  import { Unit } from '$types';

  type Inventory = {
    id: number;
    stock_on_order: boolean;
    stock_available: number;
    stock_unit: Unit,
  }

  const props = defineProps({
    variant: { type: Object as PropType<Inventory>, required: false },
  });

  const variant = computed(() => props.variant);
  const { stockOnOrder, inStock, amountInStock, amountInCart } = useVariantStock(variant);

  const availabilityMessage = computed(() => {
    let amount = amountInStock.value;
    let unit = props.variant?.stock_unit;

    if (props.variant && unit) {
      if (!stockOnOrder.value) {
        if (inStock.value) {
          return trans('Dostępne <b>:amount</b> w magazynie.', { amount: UnitFormatter.accusative(unit, amount) });
        } else {
          return trans('<b>Product niedostępny.</b>');
        }
      } else {
        return trans('<b>Produkt dostępny.</b>');
      }
    } else {
      return trans('<b>Wybierz opcje aby sprawdzić dostępność.</b>');
    }
  });

  const inCartMessage = computed(() => {
    let unit = props.variant?.stock_unit;
    let amount = amountInCart.value;

    if (amount && unit) {
      return trans('Masz <b>:amount</b> w koszyku.', { amount: UnitFormatter.accusative(unit, amount) });
    }
  });
</script>
<template>
  <div class="stock" aria-live="polite">
    <SfIconBoxSeam class="stock__icon" />
    <div class="stock__availability">
      <span v-html="availabilityMessage" />
      <span v-if="inCartMessage" v-html="inCartMessage" />
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-stock-gap: 0.625rem;
    --sf-stock-icon-size: 1rem;
    --sf-stock-font-size: 0.75rem;

    @include media.query(md) {
      --sf-stock-font-size: 0.625rem;
    }
  }

  .stock {
    display: flex;
    align-items: center;
    gap: var(--sf-stock-gap);

    &__icon {
      flex: 0 0 var(--sf-stock-icon-size);
      font-size: var(--sf-stock-icon-size);
    }

    &__availability {
      display: flex;
      flex-direction: column;
      font-size: var(--sf-stock-font-size);
      line-height: var(--line-height);
    }
  }
</style>
