<script setup lang="ts">
  import { computed, resolveComponent } from 'vue';
  import { useVisitorForm } from '@plenny/visitor';

  defineOptions({
    inheritAttrs: false,
  });

  type OrderShippingForm = {
    shipping_option_delivery_types: string[];
  }

  const { data } = useVisitorForm<OrderShippingForm>();

  const services = {
    point: resolveComponent('ShippingPoint'),
    courier: resolveComponent('ShippingCourier'),
  };

  const service = computed(() => {
    let isService = data.value.shipping_option_delivery_types.includes('service');
    let isMachine = data.value.shipping_option_delivery_types.includes('machine');
    let isCourier = data.value.shipping_option_delivery_types.includes('courier');

    if (isService || isMachine) {
      return services.point;
    }

    if (isCourier) {
      return services.courier;
    }

    throw new Error('Unsupported delivery type for driver.');
  });
</script>
<template>
  <component :is="service" />
</template>
