<script lang="ts" setup>
  import { ref, type PropType } from 'vue';
  import { $refresh, route, $delete } from '@plenny/visitor';
  import { useBreakpoints } from '$storefront';
  import type { Pagination, Wishlist } from '$types';
  import { trans } from '@plenny/translator';

  type AccountWishlist = Pagination & {
    data: Wishlist[];
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    wishlists: { type: Object as PropType<AccountWishlist>, required: true },
  });

  const breakpoints = useBreakpoints();
  const open = ref(false);

  function onSaved() {
    $refresh();
  }

  function onDestroy(wishlist: Wishlist) {
    if (confirm(trans('Czy na pewno chcesz usunąć tę listę z ulubionych?'))) {
      $delete(route('web.account.wishlist.destroy', { wishlist }));
    }
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Ulubione') }}
  </AccountMobileNavigation>
  <SfTile large class="account-wishlist">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading" :class="{'hide': !breakpoints.lg }" :aria-hidden="!breakpoints.lg">
        {{ $t('Ulubione') }}
      </h1>
    </template>
    <template #default>
      <div v-if="wishlists.data.length > 0" class="account-wishlist__products">
        <SfListCard
          interactive
          v-for="wishlist in wishlists.data"
          :key="wishlist.id"
          role="button"
          @click="$redirect(route('web.account.wishlist.show', {wishlist}))"
          @keydown.enter="$redirect(route('web.account.wishlist.show', {wishlist}))"
          class="account-wishlist__product"
          tabindex="0"
        >
          <template #default>
            <header class="account-wishlist__product-content">
              <h2 class="account-wishlist__product-title">{{ wishlist.name }}</h2>
              <p class="account-wishlist__product-count">{{ $tc(':count produkt|:count produkty|:count produktów', wishlist.variants_count, { count: wishlist.variants_count }) }}</p>
            </header>
          </template>
          <template #icons>
            <SfButton rounded square danger :title="$t('Usuń listę')" transparent @click.stop="onDestroy(wishlist)">
              <template #after>
                <SfIconTrash />
              </template>
            </SfButton>
          </template>
        </SfListCard>
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Obecnie, nie masz zapisanych żadnych list zakupowych.') }}
      </SfDataPlaceholder>
    </template>
    <template #footer>
      <SfButton primary @click="open = true">
        {{ $t('Utwórz nową listę') }}
      </SfButton>
    </template>
  </SfTile>
  <SfPagination :lastPage="wishlists.last_page" :links="wishlists.links" :currentPage="wishlists.current_page" />
  <WishlistEditModal v-model:open="open" @saved="onSaved" />
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-wishlist {
    &__products {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__product {
      cursor: pointer;
    }
  }

  @include media.query(lg) {
    .account-wishlist {
      &__product-title {
        font-size: 1.125rem;
      }

      &__product-count {
        font-size: 0.875rem;
      }
    }
  }
</style>
