<script lang="ts" setup>
  import { useSession, route } from '@plenny/visitor';
  import { useBreakpoints } from '$storefront';
  import { computed } from 'vue';

  const props = defineProps({
    menuDrawer: { type: Boolean, default: false },
  });

  const linkClasses = computed(() => {
    return {
      'auth__link--menu': props.menuDrawer,
    };
  });

  const session = useSession();
  const breakpoints = useBreakpoints();
</script>

<template>
  <div class="auth">
    <template v-if="session.is_authenticated">
      <VisitorLink :href="route('web.account.show')" class="auth__link" :class="linkClasses">
        <IconPersonCircle class="auth__icon auth__icon--lg" />
        <span class="auth__account">
          {{ $t('Konto') }}
        </span>
      </VisitorLink>
    </template>
    <template v-else>
      <template v-if="breakpoints.lg">
        <VisitorLink :href="route('web.account.session.create')" class="auth__link auth__link--bold">
          <IconPerson class="auth__icon" />
          {{ $t('Zaloguj się') }}
        </VisitorLink>
        <div class="auth__separator">|</div>
        <VisitorLink :href="route('web.account.register.create')" class="auth__link">
          {{ $t('Rejestracja') }}
        </VisitorLink>
      </template>

      <template v-else>
        <VisitorLink :class="linkClasses" :href="route('web.account.session.create')" class="auth__link">
          <IconPersonCircle class="auth__icon auth__icon--lg" />
          <span class="auth__account">
            {{ $t('Konto') }}
          </span>
        </VisitorLink>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .auth {
    display: flex;
    gap: 0.625rem;

    &__link {
      display: inline-flex;
      align-items: center;
      gap: 0.375rem;
      text-decoration: none;
      border: none;
      white-space: nowrap;
      font-size: 0.875rem;

      &--bold {
        font-weight: bold;
      }

      &--menu {
        gap: 1.25rem;
        font-size: 1.125rem;

        .auth__account {
          display: block;
        }
      }
    }

    &__account {
      display: none;
    }

    &__icon {
      &--lg {
        font-size: 1.125rem;
      }
    }
  }
</style>
