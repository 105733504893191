<script lang="ts" setup>
  import { ref } from 'vue';

  defineProps({
    product_featured_header: { type: String, required: true },
    product_sale_header: { type: String, required: true },
    product_new_header: { type: String, required: true },
    products_featured: { type: Array, required: true },
    products_new: { type: Array, required: true },
    products_sale: { type: Array, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  });

  const activeTab = ref('featured');

  function setActiveTab(tab: string) {
    activeTab.value = tab;
  }
</script>

<template>
  <Section class="container">
    <div class="tabs-block">
      <div class="tabs-block__headers">
        <SfButton
          :class="{ 'tabs-block__tab--active': activeTab === 'featured' }"
          class="tabs-block__tab"
          transparent
          @click="setActiveTab('featured')"
        >
          {{ product_featured_header }}
        </SfButton>
        <SfButton
          :class="{ 'tabs-block__tab--active': activeTab === 'sale' }"
          class="tabs-block__tab"
          transparent
          @click="setActiveTab('sale')"
        >
          {{ product_sale_header }}
        </SfButton>
        <SfButton
          :class="{ 'tabs-block__tab--active': activeTab === 'new' }"
          class="tabs-block__tab"
          transparent
          @click="setActiveTab('new')"
        >
          {{ product_new_header }}
        </SfButton>
      </div>

      <div class="tabs-block__content">
        <SfProductsCarousel v-if="activeTab === 'featured'" :parameters="parameters" :products="products_featured" />
        <SfProductsCarousel v-if="activeTab === 'sale'" :parameters="parameters" :products="products_sale" />
        <SfProductsCarousel v-if="activeTab === 'new'" :parameters="parameters" :products="products_new" />
      </div>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
  .tabs-block {
    &__headers {
      display: flex;
      gap: 2rem;

      @media (max-width: 576px) {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
        padding: 0;
      }
    }

    &__tab {
      text-transform: uppercase;
      padding: 0 0 0.5rem 0;
      font-weight: bold;

      &--active {
        color: var(--primary-color);
      }
    }
  }
</style>
