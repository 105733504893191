<script lang="ts" setup>
  import { computed } from 'vue';
  import { url, useShared } from '@plenny/visitor';

  /*
   | This component comes from a package:
   | https://github.com/Yummygum/vue-flagpack
   |
   | Static assets can be found in a package:
   | https://github.com/Yummygum/flagpack-core
   */

  const props = defineProps({
    size: { type: String, default: 's', validator: (value: string) => (['s', 'm', 'l'].indexOf(value) !== -1) },
    code: { type: String, default: null },
    hasDropShadow: { type: Boolean, default: false },
    hasBorder: { type: Boolean, default: true },
    hasBorderRadius: { type: Boolean, default: true },
    gradient: { type: String, validator: (value: string) => (['top-down', 'real-linear'].indexOf(value) !== -1) },
  });

  const shared = useShared();

  const classes = computed(() => {
    return [
      'flag',
      `size-${props.size}`,
      { 'border-radius': props.hasBorderRadius },
      { 'border': props.hasBorder },
      { 'drop-shadow': props.hasDropShadow },
      props.gradient,
    ];
  });

  const image = computed(() => {
    let size = props.size.toLowerCase();
    let code = (props.code || shared.value.active_country_code).toUpperCase();

    return url(`vendor/othercommerce/storefront/flags/${size}/${code}.svg`);
  });
</script>
<template>
  <div :class="classes">
    <img :alt="code" :src="image" />
  </div>
</template>
<style lang="scss" scoped>
  @mixin before-styling {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    mix-blend-mode: overlay;
    box-sizing: border-box;
  }

  .flag {
    display: inline-block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    &.size {
      &-s {
        width: 16px;
        height: 12px;

        &.drop-shadow {
          box-shadow: var(--secondary-box-shadow-100);
        }

        &.border-radius {
          border-radius: 1px;
        }
      }

      &-m {
        width: 26px;
        height: 20px;

        &.drop-shadow {
          box-shadow: var(--secondary-box-shadow-100);
        }

        &.border-radius {
          border-radius: 2px;
        }
      }

      &-l {
        width: 32px;
        height: 24px;

        &.drop-shadow {
          box-shadow: var(--secondary-box-shadow-100);
        }

        &.border-radius {
          border-radius: 2px;
        }
      }
    }

    &.border {
      &::before {
        @include before-styling();
        border: 1px solid rgba(0, 0, 0, .5);
        mix-blend-mode: overlay;
      }
    }

    &.border-radius {
      &::before {
        @include before-styling();
        border-radius: 1px;
      }
    }

    &.top-down {
      &::before {
        @include before-styling();
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 2%, rgba(255, 255, 255, 0.70) 100%);
      }
    }

    &.real-linear {
      &::before {
        @include before-styling();
        background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.20) 0%, rgba(39, 39, 39, 0.22) 11%, rgba(255, 255, 255, 0.30) 27%, rgba(0, 0, 0, 0.24) 41%, rgba(0, 0, 0, 0.55) 52%, rgba(255, 255, 255, 0.26) 63%, rgba(0, 0, 0, 0.27) 74%, rgba(255, 255, 255, 0.30) 100%);
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
</style>
