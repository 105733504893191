<script setup lang="ts">
  import { $post, route, $delete, useSession } from '@plenny/visitor';
  import { computed } from 'vue';
  import { trans, transChoice } from '@plenny/translator';
  import { useBreakpoints } from '$storefront';

  const props = defineProps({
    review: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();
  const session = useSession();
  const emit = defineEmits(['refreshComments']);

  const formattedDate = computed(() => {
    const reviewDate = new Date(props.review.created_at);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - reviewDate.getTime();

    // ms to days
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (daysDifference < 1) {
      return trans('dzisiaj');
    } else if (daysDifference < 30) {
      return trans(':days dni temu', { days: daysDifference });
    } else if (daysDifference < 365) {
      const monthsDifference = Math.floor(daysDifference / 30);
      return transChoice('miesiąc temu|:month miesiące temu|:month miesięcy temu', monthsDifference, { month: monthsDifference });
    } else {
      const yearsDifference = Math.floor(daysDifference / 365);
      return transChoice('rok temu|:year lata temu|:year lat temu', yearsDifference, { year: yearsDifference });
    }
  });

  function onVoteClick() {
    if (props.review.is_liked) {
      $delete(route('api.v1.web.review.vote.destroy', { review: props.review })).then(() => {
        emit('refreshComments');
      });
    } else {
      $post(route('api.v1.web.review.vote.store', { review: props.review })).then(() => {
        emit('refreshComments');
      });
    }
  }
</script>
<template>
  <div class="review-comment__opinion">
    <div class="review-comment__opinion-header">
      <div class="review-comment__opinion-header-wrap">
        <p class="review-comment__opinion-name">{{ review.name }}</p>
        <div class="review-comment__opinion-header-info">
          <span class="review-comment__opinion-rating">{{ review.rating.toFixed(1) }}</span>
          <SfStars :rating="review.rating" />
          <span class="review-comment__opinion-date">
            {{ formattedDate }}
          </span>
        </div>
      </div>

      <SfButton
        :disabled="!session.is_authenticated"
        :title="session.is_authenticated ? $t('Polub opinię') : $t('Tylko zalogowany użytkownik może polubić opinię')"
        class="review-comment__opinion-vote"
        transparent
        :small="!breakpoints.sm"
        @click="onVoteClick()"
      >
        <template #default>
          {{ review.upvotes }}
        </template>
        <template #after>
          <SfIconLike :class="{ 'review-comment__opinion-thumb--active': review.is_liked }" class="review-comment__opinion-thumb" />
        </template>
      </SfButton>
    </div>
    <p class="review-comment__review">
      {{ review.comment }}
    </p>
  </div>
</template>
<style lang="scss">
  :root {
    --shop-product-review-comments-gap: 1rem;
    --shop-product-review-comments-gap-lg: 0.5rem;
  }
</style>
<style scoped lang="scss">
  @use '$assets/mixins/media';

  .review-comment {
    &__opinion {
      padding-bottom: var(--shop-product-review-comments-gap);
      border-bottom: 1px solid var(--secondary-border);
    }

    &__opinion-header {
      --sf-stars-padding-y: 0;
      --sf-stars-star-size: 0.875rem;
      --sf-stars-gap: 0.25rem;

      justify-content: space-between;
      gap: 1.5rem;
    }

    &__opinion-header-info {
      gap: 0.5rem;
    }

    &__opinion-header-wrap,
    &__opinion,
    &__opinion-header-info,
    &__opinion-header,
    &__opinion-date {
      display: flex;
    }

    &__opinion-header-wrap,
    &__opinion {
      flex-direction: column;
    }

    &__opinion-header-wrap {
      gap: 0.125rem;
    }

    &__opinion {
      gap: 0.5rem
    }

    &__opinion-name {
      font-weight: var(--font-weight-bold);
      font-size: 1.125rem;
    }

    &__opinion-vote {
      font-weight: var(--font-weight-semi);
    }

    &__opinion-date {
      align-items: center;
      font-size: 0.875rem;
    }

    &__review {
      font-size: 0.875rem;
    }

    &__opinion-thumb {
      font-size: 1rem;

      &--active {
        color: var(--primary-color);
      }
    }
  }

  @include media.query(md) {
    .review-comment {
      &__opinion {
        padding-bottom: var(--shop-product-review-comments-gap-lg);
      }

      &__opinion-header {
        column-gap: 1rem;
        margin-bottom: 0;
        font-size: 1.125rem;
      }

      &__opinion-name {
        font-size: 0.875rem;
      }

      &__opinion-rating {
        font-size: 0.875rem;
      }
    }
  }
</style>
