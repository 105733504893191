<script lang="ts" setup>
  import { $post, $patch, route } from '@plenny/visitor';
  import { type PropType } from 'vue';
  import type { Wishlist } from '$types';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const emit = defineEmits([
    'saved',
  ]);

  const props = defineProps({
    wishlist: { type: Object as PropType<Wishlist>, required: false },
  });

  function onSubmit(data: { name: string }) {
    let wishlist = props.wishlist;

    let callback = (res) => {
      emit('saved', res);
      open.value = false;
    };

    if (wishlist) {
      return $patch(route('api.v1.web.wishlist.update', { wishlist }), data).then(callback);
    }

    return $post(route('api.v1.web.wishlist.store'), data).then(callback);
  }
</script>

<template>
  <SfModal v-model:open="open" class="wishlist-edit-modal">
    <VisitorForm v-slot="{ submitting } " :initial="wishlist" :submit="onSubmit">
      <header>
        <h2 v-if="wishlist">{{ $t('Edytuj listę') }}</h2>
        <h2 v-else>{{ $t('Utwórz nową listę') }}</h2>
      </header>
      <section class="wishlist-edit-modal__input">
        <SfFormInput :label="$t('Nazwa')" name="name" required />
      </section>
      <SfModalFooter>
        <SfButton :loading="submitting" primary type="submit">
          {{ $t('Zapisz') }}
        </SfButton>
        <SfButton @click.prevent="open = false">
          {{ $t('Anuluj') }}
        </SfButton>
      </SfModalFooter>
    </VisitorForm>
  </SfModal>
</template>
<style lang="scss">
  .wishlist-edit-modal__input {
    flex-grow: 1;
  }
</style>
