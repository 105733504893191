<script lang="ts" setup>
  type department = {
    id: number,
    name: string,
  }

  const props = defineProps({
    departments: { type: Object, required: true },
  });

  import { computed } from 'vue';
  import { trans } from '@plenny/translator';
  import { route, $post } from '@plenny/visitor';

  const departments = computed(() => {
    return props.departments.map((department: department) => ({
      label: department.name,
      value: department.id,
    }));
  });

  function onSubmit(data) {
    return $post(route('web.contact.store'), data);
  }

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" class="conditions-form__item-link" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <VisitorForm :title="$t('Formularz kontaktowy')" @submit="onSubmit">
    <div class="contact-form">
      <p v-if="$slots.title" class="contact-form__title">
        <slot name="title" />
      </p>
      <div class="contact-form__inputs">
        <SfFormSelect v-if="departments.length > 1" :label="$t('Dział')" name="contact_department_id" :options="departments" required />
        <SfFormInput :label="$t('Imię i Nazwisko')" name="name" type="text" required />
        <SfFormInput :label="$t('Telefon')" name="phone" type="tel" required />
        <SfFormInput :label="$t('E-mail')" name="email" type="email" required />
        <SfFormTextarea :label="$t('Wiadomość')" name="content" required />
        <div class="contact-form__switch">
          <SfFormSwitch class="contact-form__switch" name="acceptance_rodo" required>
            <span v-html="privacyPolicy" />
          </SfFormSwitch>
        </div>
      </div>
      <SfButton class="contact-form__submit" primary type="submit">
        {{ $t('Wyślij wiadomość') }}
      </SfButton>
    </div>
  </VisitorForm>
</template>
<style lang="scss">
  :root {
    --sf-contact-form-border: none;
    --sf-contact-form-border-radius: 1.25rem;
    --sf-contact-form-border-padding-x: 3rem;
    --sf-contact-form-border-padding-y: 4rem;

    --sf-contact-form-title-size: 1rem;
    --sf-contact-form-title-color: var(--color-default);
    --sf-contact-form-title-weight: normal;

    --sf-contact-form-submit-text-transform: none;
    --sf-contact-form-submit-weight: normal;

    --sf-contact-form-gap: 1.5rem;
    --sf-contact-form-inputs-gap: 1.5rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: var(--sf-contact-form-gap);
    border: var(--sf-contact-form-border);
    border-radius: var(--sf-contact-form-border-radius);
    padding: var(--sf-contact-form-border-padding-x) var(--sf-contact-form-border-padding-y);

    &__title {
      font-size: var(--sf-contact-form-title-size);
      color: var(--sf-contact-form-title-color);
      font-weight: var(--sf-contact-form-title-weight);
    }

    &__inputs {
      display: flex;
      flex-direction: column;
      gap: var(--sf-contact-form-inputs-gap);
    }

    &__submit {
      text-transform: var(--sf-contact-form-submit-text-transform);
      font-weight: var(--sf-contact-form-submit-weight);
      align-self: flex-end;
    }

    &__switch {
      a {
        transition: color 0.2s ease-out;
      }
    }
  }
</style>
