<script setup>
  import { ref } from 'vue';

  defineProps({
    product: { type: Object, required: true },
    reviews: { type: Object, required: true },
    recommended: { type: Array, required: true },
    sections: { type: Array, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });

</script>
<template>
  <div class="product-description">
    <nav class="product-description__nav">
      <a class="product-description__link product-description__link--active" href="#specyfikacja">{{
          $t('Specyfikacja')
        }}</a>
      <a v-if="sections.length" class="product-description__link" href="#opis">{{ $t('Opis') }}</a>
      <a v-if="reviews.data.length" class="product-description__link" href="#opinie">{{ $t('Opinie') }}</a>
      <a v-if="recommended.length" class="product-description__link" href="#podobne-produkty">{{
          $t('Podobne produkty')
        }}</a>
    </nav>

    <div class="product-description__sections">
      <ProductSection :id="$t('specyfikacja')">
        <ProductAttributes :product="product" table />
      </ProductSection>
      <ProductSection v-if="sections.length" :id="$t('opis')">
        <template #title>
          <SfHeading type="h2">{{ $t('Opis') }}</SfHeading>
        </template>
        <SectionRenderer :sections="sections" class="product-description__sections-renderer" />
      </ProductSection>
      <ProductSection v-if="recommended.length" :id="$t('podobne-produkty')">
        <template #title>
          <SfHeading type="h2">{{ $t('Podobne produkty') }}</SfHeading>
        </template>
        <div>
          <SfProductsCarousel :parameters :products="recommended" class="product-description__recommended-products"/>
        </div>
      </ProductSection>
      <ProductSection v-if="reviews.data.length" :id="$t('opinie')" class="product-description__reviews">
        <template #title>
          <SfHeading type="h2">{{ $t('Opinie o produkcie:') }}</SfHeading>
        </template>
        <ShopProductViewDescriptionReviews :reviews :product :reviewAvailable :reviewsSummary />
      </ProductSection>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-description {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    padding: 1.5rem;
    background-color: var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-primary);

    &__reviews :deep(.product-reviews__reviews-header) {
      gap: 3rem;
      flex-basis: 80%;
    }

    &__sections-renderer {

      :deep(img) {
        max-width: 100%
      }

      :deep(h1), :deep(h2), :deep(h3), :deep(h4), :deep(h5), :deep(h6) {
        line-height: 1.4;
      }
    }

    &__recommended-products {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      max-width: 100%;

      :deep(.products-carousel__container) {
        width: 100%;
        max-width: 100%;
      }
    }

    @media (max-width: 992px) {
      border-radius: 0;
      row-gap: 1.5rem;
    }

    @media (max-width: 576px) {
      padding: 0.5rem;
    }

    &__nav {
      display: flex;
      font-weight: bold;
      gap: 3rem;

      @media (max-width: 768px) {
        gap: 1.25rem;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &__link {
      &--active, &:hover {
        color: var(--primary-color);
      }
    }

    &__sections {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      @media (max-width: 576px) {
        gap: 1.5rem;
      }
    }
  }
</style>
