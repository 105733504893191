<script setup lang="ts">
  import { $post, route } from '@plenny/visitor';

  const props = defineProps({
    token: { type: String, required: true },
    email: { type: String, required: false, default: null },
  });

  type ResetData = {
    email: string;
    password: string;
    password_confirmation: string;
  }

  function onSubmit(data: ResetData) {
    return $post(route('web.account.password.reset.store'), { token: props.token, ...data });
  }
</script>
<template>
  <div class="reset">
    <SfTile large>
      <template #title>
        <p class="account-heading reset__reset">
          {{ $t('Ustaw nowe hasło') }}
        </p>
      </template>
      <template #default>
        <VisitorForm @submit="onSubmit" :initial="{ email }" v-slot="{ submitting }">
          <div class="reset__inputs">
            <SfFormInput :label="$t('Adres e-mail')" type="email" name="email" required />
            <SfFormInput :label="$t('Hasło')" type="password" name="password" required />
            <SfFormInput :label="$t('Potwierdź hasło')" type="password" name="password_confirmation" required />
            <SfButton type="submit" :loading="submitting">{{ $t('Zmień hasło') }}</SfButton>
          </div>
        </VisitorForm>
      </template>
    </SfTile>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .reset {
    width: 100%;
    max-width: 600px;
    margin: 100px auto;

    .reset__inputs {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  }

  @include media.query(lg) {
    .reset .tile__header {
      justify-content: center;
    }
  }
</style>
